import React, {useCallback, useEffect, useMemo, useState} from "react";
import Select, {OptionProps, StylesConfig} from "react-select";
import {useTranslation} from "react-i18next";
import {Button, Card, CardBody, CardHeader, Col, Input, Row} from "reactstrap";
import {SaleHistoryChart, SaleHistorySeries} from "./Charts/SaleHistoryChart";
import {StrokedCircularRadial} from "Components/Common/Charts/StrokedCircularRadial";
import {useSelector} from "react-redux";
import {multiValueContainer, Option} from "Components/Common/Select/SelectStyle";
import {SelectOptionsType} from "Components/Hooks/useSelectOptions";
import {ShipmentOrder} from "models/user_shipment";
import {getRelativeDateByDate, getToday} from "helpers/utilities";
import {components} from "react-select";
import {AmazonMarketplace} from "helpers/marketplace_helper";
import {ShipmentSlice} from "slices/shipment/selector";
import CountUp from "react-countup";
import DisplayPrice from "Components/Common/DisplayPrice";
import NoData from "Components/Common/NoData";
import errorImage from "assets/images/svg/product.svg";
import DisplayNumber from "Components/Common/DisplayNumber";

type SalesHistoryTimeFilter = "allTime" | "last7Days" | "last1Month";
type GraphicData = {soldItemsCount: number; revenue: number; profit: number};
type ProcessedData = {graphicData: GraphicData[]; days: Date[]};

interface SalesHistoryProps {
  destinationMarketplace?: AmazonMarketplace;
}
const SalesHistory = ({destinationMarketplace}: SalesHistoryProps) => {
  const {t} = useTranslation();
  const [asinFilterOptions, setAsinFilterOptions] = useState<SelectOptionsType[]>();
  const [selectedAsinFilterOptions, setSelectedAsinFilterOptions] = useState<SelectOptionsType[]>([]);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState<SalesHistoryTimeFilter>("allTime");
  const [tableOriginalData, setTableOriginalData] = useState<ShipmentOrder[]>([]); // CurrentUserShipmentOrders
  const [filteredSeries, setFilteredSeries] = useState<SaleHistorySeries[]>([]); // FilteredCurrentUserShipmentOrders
  const [filteredDayLabel, setFilteredDayLabel] = useState<Date[]>([]); // FilteredCurrentUserShipmentOrders days
  const [lastProductDate, setLastProductDate] = useState<Date>(new Date());
  // Overview
  const [revenue, setRevenue] = useState<number>(0);
  const [soldItemsCount, setSoldItemsCount] = useState<number>(0);
  const [cost, setCost] = useState<number>(0);
  const [fees, setFees] = useState<number>(0);
  const [profit, setProfit] = useState<number>(0);
  const [profitPerItem, setProfitPerItem] = useState<number>(0);
  const [roi, setRoi] = useState<number>(0);
  const [margin, setMargin] = useState<number>(0);
  const {currentUserShipment, currentUserShipmentOrders} = useSelector(ShipmentSlice);

  useEffect(() => {
    if (currentUserShipmentOrders) {
      setTableOriginalData(currentUserShipmentOrders);
    }

    const asins = currentUserShipmentOrders.map((order) => order.asin);
    const uniqueAsins = asins.filter((asin, index) => asins.indexOf(asin) === index);
    setAsinFilterOptions(uniqueAsins.map((asin) => ({value: asin, label: asin})));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, currentUserShipment, currentUserShipmentOrders]);

  const handleFilterChange = useCallback(() => {
    const filterByAsin = tableOriginalData.filter((item) => {
      if (selectedAsinFilterOptions.length > 0) {
        return selectedAsinFilterOptions.find((f) => f.value === item.asin);
      } else return true;
    });

    const last7Days: Date = getRelativeDateByDate(lastProductDate, 7);
    const last1Month: Date = getRelativeDateByDate(lastProductDate, 30);
    let minDate: Date = new Date(currentUserShipment.shipDate!);

    switch (selectedTimeFilter) {
      case "last7Days":
        if (new Date(currentUserShipment.shipDate!) < last7Days) {
          minDate = last7Days;
        }
        break;
      case "last1Month":
        if (minDate < last1Month) {
          minDate = last1Month;
        }
        break;
      case "allTime":
      default:
        let x = new Date(currentUserShipment.shipDate!);
        x.setHours(0, 0, 0, 0);
        minDate = new Date(x);
        break;
    }

    const filterByDate = filterByAsin.filter((item) => {
      const assignmentDate: Date = new Date(item.assignmentDate);
      switch (selectedTimeFilter) {
        case "last7Days":
          return assignmentDate > last7Days;
        case "last1Month":
          return assignmentDate > last1Month;
        case "allTime":
        default:
          return true;
      }
    });

    const sumProfit = filterByDate.reduce((sum, order) => sum + (order.actualProfit ?? 0), 0);
    const sumQuantity = filterByDate.reduce((sum, order) => sum + order.quantity, 0);
    const sumCost = filterByDate.reduce((sum, order) => sum + (order.cost ?? 0), 0);
    const sumSellPrice = filterByDate.reduce((sum, order) => sum + (order.actualSellPrice ?? 0), 0);
    setRevenue(filterByDate.reduce((sum, order) => sum + (order.actualSellPrice ?? 0), 0));
    setSoldItemsCount(filterByDate.reduce((sum, order) => sum + order.quantity, 0));
    setCost(filterByDate.reduce((sum, order) => sum + (order.cost ?? 0), 0));
    setFees(filterByDate.reduce((sum, order) => sum + (order.fees ?? 0), 0));
    setProfit(sumProfit);
    setProfitPerItem(sumProfit / sumQuantity);
    setRoi((sumProfit / sumCost) * 100);
    setMargin((sumProfit / sumSellPrice) * 100);

    const processedData = processGraphicsData(minDate, filterByDate);
    const array: SaleHistorySeries[] = [
      {
        id: "SoldItemsCount",
        name: t("Shipments.ShippedOrCompleted.SaleHistory.Label.SoldItemsCount"),
        type: "area",
        data: processedData.graphicData.map((item) => item.soldItemsCount),
      },
      {
        id: "Revenue",
        name: t("Shipments.ShippedOrCompleted.SaleHistory.Label.Revenue"),
        type: "bar",
        data: processedData.graphicData.map((item) => item.revenue),
      },
      {
        id: "Profit",
        name: t("Shipments.ShippedOrCompleted.SaleHistory.Label.Profit"),
        type: "bar",
        data: processedData.graphicData.map((item) => item.profit),
      },
    ];

    setFilteredSeries(array);
    setFilteredDayLabel(processedData.days);
  }, [tableOriginalData, selectedTimeFilter, selectedAsinFilterOptions, t]); // eslint-disable-line

  useEffect(() => {
    handleFilterChange();
  }, [handleFilterChange]);

  const processGraphicsData = (minDate: Date, data: ShipmentOrder[]): ProcessedData => {
    let groupedData: GraphicData[] = [];
    let days: Date[] = [];
    const sortedShipmentOrders = [...data];
    sortedShipmentOrders.sort((a, b) => {
      if (a.assignmentDate < b.assignmentDate) return -1;
      if (a.assignmentDate > b.assignmentDate) return 1;
      return 0;
    });
    let currentDate = new Date(minDate);
    let maxDate = getToday().toDate();
    if (sortedShipmentOrders.length === 0) {
      setLastProductDate(getToday().toDate());
      maxDate = getToday().toDate();
    } else {
      const lastItemDate = new Date(sortedShipmentOrders[sortedShipmentOrders.length - 1].assignmentDate);
      const maxValue = lastItemDate.setDate(lastItemDate.getDate() + 1);
      setLastProductDate(new Date(maxValue));
      maxDate = new Date(maxValue);
    }

    while (currentDate <= maxDate) {
      const itemsOfDay = data.filter((order: ShipmentOrder) => {
        var assignmentDate = new Date(order.assignmentDate);
        return assignmentDate.toDateString() === currentDate.toDateString();
      });

      groupedData.push({
        soldItemsCount: itemsOfDay.reduce((a, b) => a + b.quantity, 0),
        revenue: itemsOfDay.reduce((a, b) => a + (b.actualSellPrice ?? 0), 0),
        profit: itemsOfDay.reduce((a, b) => a + (b.actualProfit ?? 0), 0),
      });
      days.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return {graphicData: groupedData, days: days};
  };

  const [selectStyle, setSelectStyle] = useState<any>();
  useMemo(() => {
    const customStyles: StylesConfig<Option, boolean> = {
      valueContainer: (provided: any) => ({
        ...provided,
        textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: selectedAsinFilterOptions && selectedAsinFilterOptions.length > 0 ? "unset" : "inline-grid",
        minHeight: "30px",
        height: "30px",
      }),
      control: (provided, state) => ({
        ...provided,
        minHeight: "30px",
        height: "30px",
      }),

      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: "30px",
      }),
    };
    setSelectStyle(customStyles);
  }, [selectedAsinFilterOptions]);

  const renderOptionContainer = useCallback(
    (props: OptionProps<SelectOptionsType, boolean>) => {
      const itemAsin = props.data.value;
      const item = currentUserShipment.userShipmentItems.find((item) => item.userInventory.asin === itemAsin);
      const imagePath = `https://m.media-amazon.com/images/I/${item?.userInventory.imageSmall}`;

      return (
        <div>
          <components.Option {...props}>
            <Row className="d-flex align-items-center">
              <Col xs="auto">
                <Input type="checkbox" checked={props.isSelected} onChange={() => null} />
              </Col>
              <Col xs="auto" className="px-0">
                <div className="flex-shrink-0 overflow-hidden">
                  <img
                    className="rounded avatar-xs"
                    src={imagePath}
                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                      e.currentTarget.onerror = null;
                      e.currentTarget.src = errorImage;
                    }}
                    alt=""
                  />
                </div>
              </Col>
              <Col>
                <Row className="d-flex flex-column">
                  <Col>
                    <p className="ellipsis-single-line mb-0" title={item?.userInventory.productName}>
                      {item?.userInventory.productName}
                    </p>
                  </Col>
                  <Col>
                    <p className="ellipsis-single-line mb-0" title={item?.userInventory.productName}>
                      <span className="fw-medium">{props.label}</span>
                      <span className="text-decoration-underline ms-2">{item?.userInventory.sku}</span>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </components.Option>
        </div>
      );
    },
    [currentUserShipment],
  );
  return (
    <React.Fragment>
      <Row>
        <Col xs={12} xl={9} className="mb-3 mb-xl-0 same-height">
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col xs={12} md="auto" className="d-flex justify-content-lg-center align-items-lg-center mb-2 mb-md-0">
                  <h5 className="mb-0 border-0 align-items-center d-flex">{t("Shipments.ShippedOrCompleted.SaleHistory.Title")}</h5>
                </Col>
                {filteredSeries.length > 0 && (
                  <>
                    <Col md></Col>
                    {asinFilterOptions && asinFilterOptions.length > 1 && (
                      <Col xs={12} md={4} className="d-flex justify-content-end mb-3 mb-md-0">
                        <Select
                          className="w-100"
                          options={asinFilterOptions}
                          styles={selectStyle}
                          components={{MultiValueContainer: multiValueContainer, Option: renderOptionContainer}}
                          onChange={(e: any) => {
                            setSelectedAsinFilterOptions(e);
                          }}
                          isClearable
                          hideSelectedOptions={false}
                          isMulti={true}
                          closeMenuOnSelect={false}
                          placeholder={t("SelectAProduct")}
                        />
                      </Col>
                    )}

                    <Col xs={12} md="auto">
                      <div className="d-flex justify-content-end gap-1">
                        <Button
                          type="button"
                          size="sm"
                          color="soft-secondary"
                          onClick={() => {
                            setSelectedTimeFilter("allTime");
                          }}
                          active={selectedTimeFilter === "allTime"}
                        >
                          {t("Shipments.ShippedOrCompleted.SaleHistory.Button.AllTime")}
                        </Button>
                        <Button
                          type="button"
                          size="sm"
                          color="soft-secondary"
                          onClick={() => {
                            setSelectedTimeFilter("last7Days");
                          }}
                          active={selectedTimeFilter === "last7Days"}
                        >
                          {t("Shipments.ShippedOrCompleted.SaleHistory.Button.Last7Days")}
                        </Button>
                        <Button
                          type="button"
                          size="sm"
                          color="soft-secondary"
                          onClick={() => {
                            setSelectedTimeFilter("last1Month");
                          }}
                          active={selectedTimeFilter === "last1Month"}
                        >
                          {t("Shipments.ShippedOrCompleted.SaleHistory.Button.Last1Month")}
                        </Button>
                      </div>
                    </Col>
                  </>
                )}
              </Row>
            </CardHeader>
            <CardBody className="p-0">
              {filteredSeries.length > 0 ? (
                <div className="w-100" dir="ltr">
                  <SaleHistoryChart series={filteredSeries} days={filteredDayLabel} dataColors='["#13c56b", "#50c3e6", "--vz-warning"]' currency={destinationMarketplace?.currency} />
                </div>
              ) : (
                <NoData icon="mdi mdi-note-search-outline" />
              )}
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} xl={3} className="same-height">
          <Card className="card-animate">
            <CardHeader>
              <Row>
                <Col>
                  <h5 className="mb-0 border-0 align-items-center d-flex">{t("Shipments.ShippedOrCompleted.SaleHistory.Overview.Title")}</h5>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="mb-4">
                <div className="mb-n5">
                  <StrokedCircularRadial progress={roi} label="ROI" height={220} />
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-grow-1">
                  <p className="text-truncate fs-15 mb-0">
                    <i className="bx bx-dollar-circle align-middle text-primary me-2 fs-20"></i>
                    {t("Shipments.ShippedOrCompleted.SaleHistory.Overview.Label.Revenue")}
                  </p>
                </div>
                <div className="flex-shrink-0 fw-medium fs-15">
                  <DisplayPrice source={destinationMarketplace?.currency!} value={revenue} decimals={2} notation="decimal" countUp />
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-grow-1">
                  <p className="text-truncate fs-15 mb-0">
                    <i className="bx bx-shopping-bag align-middle text-primary me-2 fs-20"></i>
                    {t("Shipments.ShippedOrCompleted.SaleHistory.Overview.Label.SoldItemsCount")}
                  </p>
                </div>
                <div className="flex-shrink-0 fw-medium fs-15">
                  <DisplayNumber
                    value={soldItemsCount}
                    decimals={0}
                    suffix={` ${soldItemsCount > 1 ? t("Shipments.ShippedOrCompleted.SaleHistory.Overview.Label.ItemPlural") : t("Shipments.ShippedOrCompleted.SaleHistory.Overview.Label.Item")}`}
                  />
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-grow-1">
                  <p className="text-truncate fs-15 mb-0">
                    <i className="bx bxs-credit-card align-middle text-primary me-2 fs-20"></i>
                    {t("Shipments.ShippedOrCompleted.SaleHistory.Overview.Label.Cost")}
                  </p>
                </div>
                <div className="flex-shrink-0 fw-medium fs-15">
                  <DisplayPrice source={destinationMarketplace?.currency!} value={cost} decimals={2} notation="decimal" countUp />
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-grow-1">
                  <p className="text-truncate fs-15 mb-0">
                    <i className="bx bxs-bank align-middle text-primary me-2 fs-20"></i>
                    {t("Shipments.ShippedOrCompleted.SaleHistory.Overview.Label.Fees")}
                  </p>
                </div>
                <div className="flex-shrink-0 fw-medium fs-15">
                  <DisplayPrice source={destinationMarketplace?.currency!} value={fees} decimals={2} notation="decimal" countUp />
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-grow-1">
                  <p className="text-truncate fs-15 mb-0">
                    <i className="bx bxs-wallet align-middle text-primary me-2 fs-20"></i>
                    {t("Shipments.ShippedOrCompleted.SaleHistory.Overview.Label.Profit")}
                  </p>
                </div>
                <div className="flex-shrink-0 fw-medium fs-15">
                  <DisplayPrice source={destinationMarketplace?.currency!} value={profit} decimals={2} notation="decimal" countUp />
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="flex-grow-1">
                  <p className="text-truncate fs-15 mb-0">
                    <i className="bx bxs-purchase-tag align-middle text-primary me-2 fs-20"></i>
                    {t("Shipments.ShippedOrCompleted.SaleHistory.Overview.Label.ProfitPerItem")}
                  </p>
                </div>
                <div className="flex-shrink-0 fw-medium fs-15">
                  <DisplayPrice source={destinationMarketplace?.currency!} value={profitPerItem} decimals={2} notation="decimal" countUp />
                </div>
              </div>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-truncate fs-15 mb-0">
                    <i className="bx bx-dollar align-middle text-primary me-2 fs-20"></i>
                    {t("Shipments.ShippedOrCompleted.SaleHistory.Overview.Label.RoiMargin")}
                  </p>
                </div>
                <div className="flex-shrink-0 fw-medium text-nowrap fs-15">
                  <CountUp start={0} end={roi} decimals={0} duration={2} suffix="%" />
                  <i className="mdi mdi-slash-forward"></i>
                  <CountUp start={0} end={margin} decimals={0} duration={2} suffix="%" />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SalesHistory;

import {Col, Progress, Row} from "reactstrap";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getShipmentSpeed} from "helpers/utilities";
import {ShipmentSlice} from "slices/shipment/selector";
import CountUp from "react-countup";
import SuccessRateCalculator from "Components/Common/Calculator/SuccessRateCalculator";

const Stats = () => {
  const {t} = useTranslation();
  const [shipmentSpeed, setShipmentSpeed] = useState<number>();
  const {currentUserShipment} = useSelector(ShipmentSlice);

  useEffect(() => {
    if (currentUserShipment) {
      const {shipmentSpeed} = getShipmentSpeed(currentUserShipment);
      setShipmentSpeed(shipmentSpeed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserShipment]);
  return (
    <>
      <Row>
        <Col xxl={2} sm={6} className="same-height">
          <div className="p-2 border card card-animate rounded d-flex justify-content-center">
            <div className="d-flex align-items-center">
              <div className="avatar-sm me-2">
                <div className="avatar-title rounded bg-transparent text-success fs-24">
                  <i className="ri-numbers-fill"></i>
                </div>
              </div>
              <div className="flex-grow-1 me-3 align-items-center py-2">
                <p className="text-muted mb-0 fs-16">{t("Shipments.ShippedOrCompleted.Stats.Label.Items")}</p>

                <h4 className="fs-22 mb-0 mt-1 fw-semibold ff-secondary d-flex align-items-center">
                  <span className="counter-value">
                    <CountUp start={0} decimals={0} duration={2} end={currentUserShipment.totalItems} />
                  </span>
                  <span className="badge rounded-pill bg-success-subtle text-success ms-2 fs-14">
                    <CountUp start={0} decimals={0} duration={2} end={currentUserShipment.totalSKU} /> {currentUserShipment.totalSKU > 1 ? t("SKUs") : t("SKU")}
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </Col>
        <Col xxl={2} sm={6} className="same-height">
          <div className="p-2 border card card-animate rounded d-flex justify-content-center">
            <div className="d-flex align-items-center">
              <div className="avatar-sm me-2">
                <div className="avatar-title rounded bg-transparent text-success fs-24">
                  <i className="ri-shopping-cart-2-fill"></i>
                </div>
              </div>
              <div className="flex-grow-1 me-3 align-items-center py-2">
                <p className="text-muted mb-0 fs-16">{t("Shipments.ShippedOrCompleted.Stats.Label.SoldItems")}</p>
                <h4 className="fs-22 fw-semibold ff-secondary mb-0 mt-1 d-flex align-items-center">
                  {currentUserShipment.soldItems}
                  <span className="text-muted fs-14 ps-2">{t("PercentSign", {value: ((currentUserShipment.soldItems / currentUserShipment.totalItems) * 100).toFixed(2)})}</span>
                </h4>

                <Progress className="progress-sm w-100 mt-1" color="success" value={(currentUserShipment.soldItems / currentUserShipment.totalItems) * 100} />
              </div>
            </div>
          </div>
        </Col>
        <Col xxl={2} sm={6} className="same-height">
          <div className="p-2 border card card-animate rounded d-flex justify-content-center">
            <div className="d-flex align-items-center">
              <div className="avatar-sm me-2">
                <div className="avatar-title rounded bg-transparent text-warning fs-24">
                  <i className="ri-pie-chart-fill"></i>
                </div>
              </div>
              <div className="flex-grow-1 me-3 align-items-center py-2">
                <p className="text-muted mb-0 fs-16">{t("Shipments.ShippedOrCompleted.Stats.Label.LeftItems")}</p>
                <h4 className="fs-22 mt-1 fw-semibold ff-secondary mb-0 d-flex align-items-center">{currentUserShipment.totalItems - currentUserShipment.soldItems}</h4>
              </div>
            </div>
          </div>
        </Col>

        <Col xxl={2} sm={6} className="same-height">
          <div className="p-2 border card card-animate rounded d-flex justify-content-center">
            <div className="d-flex align-items-center">
              <div className="avatar-sm me-2">
                <div className="avatar-title rounded bg-transparent text-secondary fs-24">
                  <i className="mdi mdi-trophy"></i>
                </div>
              </div>
              <div className="flex-grow-1 me-3 align-items-center py-2">
                <p className="text-muted mb-0 fs-16">{t("Shipments.ShippedOrCompleted.Stats.Label.SuccessRate")}</p>
                <h4 className="fs-22 fw-semibold ff-secondary mb-0 mt-1 d-flex align-items-center">
                  {currentUserShipment.soldItems > 0 ? (
                    t("PercentSign", {value: SuccessRateCalculator(currentUserShipment.userShipmentFinance.actualSoldProfit, currentUserShipment.userShipmentFinance.expectedSoldProfit).toFixed(0)})
                  ) : (
                    <span className="text-muted">—</span>
                  )}
                </h4>
              </div>
            </div>
          </div>
        </Col>
        <Col xxl={2} sm={6} className="same-height">
          <div className="p-2 border card card-animate rounded d-flex justify-content-center">
            <div className="d-flex align-items-center">
              <div className="avatar-sm me-2">
                <div className="avatar-title rounded bg-transparent text-success fs-24">
                  <i className="mdi mdi-cash-refund"></i>
                </div>
              </div>
              <div className="flex-grow-1 me-3 align-items-center py-2">
                <p className="text-muted mb-0 fs-16">{t("Shipments.ShippedOrCompleted.Stats.Label.CostRecoveryStatus")}</p>
                <h4 className="fs-22 fw-semibold ff-secondary mb-0 mt-1 d-flex align-items-center">{t("PercentSign", {value: currentUserShipment.userShipmentFinance.costRecoveryStatusRate})}</h4>
                <Progress className="progress-sm w-100 mt-1" color="success" value={currentUserShipment.userShipmentFinance.costRecoveryStatusRate} />
              </div>
            </div>
          </div>
        </Col>
        <Col xxl={2} sm={6} className="same-height">
          <div className="p-2 border card card-animate rounded d-flex justify-content-center">
            <div className="d-flex align-items-center">
              <div className="avatar-sm me-2 mb-0">
                <div className="avatar-title rounded bg-transparent text-secondary fs-24">
                  <i className="mdi mdi-speedometer"></i>
                </div>
              </div>
              <div className="flex-grow-1 me-3 align-items-center py-2">
                <p className="text-muted mb-0 fs-16">{t("Shipments.ShippedOrCompleted.Stats.Label.Speed")}</p>
                <h4 className="fs-22 fw-semibold ff-secondary mb-0 mt-1 d-flex align-items-center">
                  {shipmentSpeed?.toFixed(2)}
                  <span className="text-muted fw-normal text-nowrap fs-14 ms-2">
                    {t("items")}
                    <i className="mdi mdi-slash-forward"></i>
                    {t("day")}
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Stats;

import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Select, {OptionProps, StylesConfig, components} from "react-select";
import {useProfile} from "Components/Hooks/useProfile";
import {ColumnDef} from "@tanstack/react-table";
import {PagerQuery} from "helpers/types";
import {ShipmentOrder} from "models/user_shipment";
import {useTranslation} from "react-i18next";
import {Button, Card, CardBody, CardHeader, Col, Input, Row} from "reactstrap";
import {useImmer} from "use-immer";
import {getRelativeDate} from "helpers/utilities";
import {SelectOptionsType} from "Components/Hooks/useSelectOptions";
import {Link} from "react-router-dom";
import {multiValueContainer, Option} from "Components/Common/Select/SelectStyle";
import {AmazonMarketplace} from "helpers/marketplace_helper";
import {ShipmentSlice} from "slices/shipment/selector";
import {useSelector} from "react-redux";
import errorImage from "assets/images/svg/product.svg";
import DomainToFlag from "Components/Common/DomainToFlag";
import CopyWidget from "Components/Common/CopyWidget";
import DisplayPrice from "Components/Common/DisplayPrice";
import DisplayNumber from "Components/Common/DisplayNumber";
import NoData from "Components/Common/NoData";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayDate from "Components/Common/DisplayDate";
import _ from "lodash";
import {FinanceStatus} from "models/user_order";

type OrderTimeFilter = "allTime" | "last7Days" | "last1Month";
interface OrdersProps {
  destinationMarketplace?: AmazonMarketplace;
}
const Orders = ({destinationMarketplace}: OrdersProps) => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const tableRef = useRef<DataTableRef>(null);
  const [filteredData, setFilteredData] = useState<ShipmentOrder[]>([]);
  const [displayData, setDisplayData] = useState<ShipmentOrder[]>([]);
  const [asinFilterOptions, setAsinFilterOptions] = useState<SelectOptionsType[]>();
  const [selectedAsinFilterOptions, setSelectedAsinFilterOptions] = useState<SelectOptionsType[]>([]);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState<OrderTimeFilter>("allTime");
  const {currentUserShipment, currentUserShipmentOrders} = useSelector(ShipmentSlice);

  const [query, updateQuery] = useImmer<PagerQuery>({
    page: 1,
    pageSize: 10,
  });

  const onLoad = useCallback(() => {
    if (currentUserShipmentOrders) {
      const asins = currentUserShipmentOrders.map((order) => order.asin);
      const uniqueAsins = asins.filter((asin, index) => asins.indexOf(asin) === index);

      setAsinFilterOptions(uniqueAsins.map((asin) => ({value: asin, label: asin})));
      // handle data
      setDisplayData(currentUserShipmentOrders.slice(0, query.pageSize));
    }
  }, [currentUserShipmentOrders]); // eslint-disable-line

  const filterData = useCallback(
    (data: ShipmentOrder[]) => {
      if (!data) return;

      // Apply ASIN filter
      let filtered = [...data];
      if (selectedAsinFilterOptions.length > 0) {
        const selectedAsins = selectedAsinFilterOptions.map((option) => option.value);
        filtered = filtered.filter((item) => selectedAsins.includes(item.asin));
      }

      // Apply time filter
      if (selectedTimeFilter !== "allTime") {
        const cutoffDate = getRelativeDate(selectedTimeFilter === "last7Days" ? 7 : 30);
        filtered = filtered.filter((item) => new Date(item.assignmentDate) >= cutoffDate);
      }

      setFilteredData(filtered);
      const start = 0;
      const end = query.pageSize;
      setDisplayData(filtered.slice(start, end));
    },
    [selectedAsinFilterOptions, selectedTimeFilter], // eslint-disable-line
  );

  const updateDisplayData = useCallback(
    (data: ShipmentOrder[]) => {
      const start = (query.page - 1) * query.pageSize;
      const end = start + query.pageSize;
      setDisplayData(data.slice(start, end));
    },
    [query.page, query.pageSize],
  );

  useEffect(() => {
    if (currentUserShipmentOrders) {
      filterData(currentUserShipmentOrders);
    }
  }, [selectedAsinFilterOptions, selectedTimeFilter, filterData]); // eslint-disable-line

  // Handle pagination changes
  useEffect(() => {
    updateDisplayData(filteredData);
  }, [query, updateDisplayData]); // eslint-disable-line

  // Pagination change handler
  const handlePaginationChange = (pagination: {pageIndex: number; pageSize: number}) => {
    updateQuery((draft) => {
      draft.page = pagination.pageIndex + 1;
      draft.pageSize = pagination.pageSize;
    });
  };

  // Time filter change handler
  const handleTimeFilterChange = (newTimeFilter: OrderTimeFilter) => {
    setSelectedTimeFilter(newTimeFilter);
    updateQuery((draft) => {
      draft.page = 1; // Reset to first page when filter changes
    });
  };

  // ASIN filter change handler
  const handleAsinFilterChange = (newOptions: SelectOptionsType[]) => {
    setSelectedAsinFilterOptions(newOptions || []);
    updateQuery((draft) => {
      draft.page = 1; // Reset to first page when filter changes
    });
  };
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const columns = useMemo<ColumnDef<ShipmentOrder, any>[]>(
    () => [
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.OrderId"),
        size: 80,
        accessorKey: "orderId",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return (
            <>
              <div className="mb-1 d-flex">
                <Link className="link-secondary link-offset-2 text-decoration-underline me-1 text-nowrap" target="_blank" to={`/orders/${row.amazonOrderId}`}>
                  {row.amazonOrderId}
                  <i className="ri-arrow-right-up-line"></i>
                </Link>
              </div>
              <div className="d-flex align-items-center text-nowrap text-muted">
                <DomainToFlag marketplace={row.marketplace} />
                <span>{`${row.marketplace} - ${row.storeName}`}</span>
              </div>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.Product"),
        size: 80,
        accessorKey: "image",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center avatar-sm rounded flex-shrink-0 overflow-hidden">
                <img
                  className="rounded w-100"
                  src={`https://m.media-amazon.com/images/I/${row.image}`}
                  onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = errorImage;
                  }}
                  alt=""
                />
              </div>
              <div className="flex-grow-1 ms-2">
                <div className="d-flex align-items-center">
                  <CopyWidget text={row.asin} index={row.asin} asLink={true} marketplace={row.marketplace} />
                </div>
                <div className="d-flex align-items-center">
                  <CopyWidget text={row.sku} index={row.sku} />
                </div>
              </div>
            </div>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.Quantity"),
        size: 50,
        accessorKey: "quantity",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return (
            <>
              <h5 className="fs-14 my-1 align-items-center d-flex">
                {row.quantity} <i className="ri-bar-chart-fill text-success fs-16 ms-1"></i>
              </h5>
              <span className="text-muted text-nowrap"></span>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.Date"),
        size: 100,
        accessorFn: (item) => item.assignmentDate,
        sortingFn: "datetime",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return <DisplayDate id={`DisplayDateShipmentOrderAssignmentDate-${row.orderId}`} value={row.assignmentDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} className="text-nowrap" />;
        },
      },

      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.ExpectedSellPriceAndActualSellPrice"),
        size: 170,
        accessorKey: "expectedSellPrice",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return (
            <>
              <span className="text-muted">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.expectedSellPrice} decimals={2} notation="decimal" />
              </span>
              <h5 className="fs-14 my-1">
                {row.financeStatus !== FinanceStatus.Completed ? (
                  <span className="text-muted">—</span>
                ) : (
                  <DisplayPrice source={destinationMarketplace?.currency} value={row.actualSellPrice} decimals={2} notation="decimal" />
                )}
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.ExpectedProfitAndActualProfit"),
        size: 150,
        accessorKey: "expectedProfit",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return (
            <>
              <span className={`text-muted`}>
                <DisplayPrice source={destinationMarketplace?.currency} value={row.expectedProfit} decimals={2} notation="decimal" />
              </span>
              <h5 className="fs-14 my-1">
                <div className="d-flex align-items-center">
                  {row.financeStatus !== FinanceStatus.Completed ? (
                    <span className="text-muted">—</span>
                  ) : (
                    <>
                      <DisplayPrice source={destinationMarketplace?.currency} value={row.actualProfit} decimals={2} notation="decimal" />
                      {row.costIsPredicted && (
                        <>
                          <i className="ri-question-fill text-warning fs-14 ms-1" id={`CostIsPredictedTooltip-${row.amazonOrderId}`} />
                          <DefaultUncontrolledTooltip target={`CostIsPredictedTooltip-${row.amazonOrderId}`}>{t("CostIsPredicted")}</DefaultUncontrolledTooltip>
                        </>
                      )}
                    </>
                  )}
                </div>
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.ExpectedRoiMarginAndActualRoiMargin"),
        size: 170,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return (
            <div className="text-nowrap">
              <span className="text-muted d-flex flex-nowrap">
                <DisplayNumber value={row.expectedROI} decimals={0} suffix="%" />

                <i className="mdi mdi-slash-forward"></i>
                <DisplayNumber value={row.expectedMargin} decimals={0} suffix="%" />
              </span>
              <h5 className="fs-14 my-1 d-flex flex-nowrap">
                {row.financeStatus !== FinanceStatus.Completed ? (
                  <span className="text-muted">—</span>
                ) : (
                  <>
                    <DisplayNumber value={row.actualROI} decimals={0} suffix="%" />

                    <i className="mdi mdi-slash-forward"></i>
                    <DisplayNumber value={row.actualMargin} decimals={0} suffix="%" />
                  </>
                )}
              </h5>
            </div>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.Cost"),
        size: 100,
        accessorKey: "cost",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return (
            <span className="fw-medium">
              <DisplayPrice source={destinationMarketplace?.currency} value={row.cost} decimals={2} notation="decimal" />
            </span>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.FeesAndTax"),
        size: 100,
        accessorKey: "fees",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return row.financeStatus !== FinanceStatus.Completed ? (
            <>
              <div className="bg-body-tertiary p-2 me-3" id={`MissingFinancialDataTooltip-FeesAndTaxes-${row.amazonOrderId}`}></div>
              <DefaultUncontrolledTooltip target={`MissingFinancialDataTooltip-FeesAndTaxes-${row.amazonOrderId}`}>{t("Orders.Warnings.MissingFinancialData.Description")}</DefaultUncontrolledTooltip>
            </>
          ) : (
            <>
              <span className={`text-muted`}>
                <DisplayPrice source={destinationMarketplace?.currency} value={row.fees} decimals={2} notation="decimal" />
              </span>
              <h5 className="fs-14 my-1">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.tax} decimals={2} notation="decimal" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.SuccessRate"),
        size: 150,

        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          let style = "";
          if (!_.isNil(row.successRate)) {
            if (row.successRate >= 50 && row.successRate <= 70) {
              style = "warning";
            } else if (row.successRate > 70) {
              style = "success";
            } else {
              style = "danger";
            }
          } else {
            style = "danger";
          }
          return row.financeStatus !== FinanceStatus.Completed ? (
            <>
              <div className="bg-body-tertiary p-2 me-3" id={`MissingFinancialDataTooltip-FeesAndTaxes-${row.amazonOrderId}`}></div>
              <DefaultUncontrolledTooltip target={`MissingFinancialDataTooltip-FeesAndTaxes-${row.amazonOrderId}`}>{t("Orders.Warnings.MissingFinancialData.Description")}</DefaultUncontrolledTooltip>
            </>
          ) : (
            <div className={style}>
              <h5 className="my-1 text-nowrap align-items-center d-flex fs-14">
                <i className={`text-${style} mdi mdi-trophy me-1`}></i>

                <span className={`text-${style}`}>
                  <DisplayNumber value={row.successRate || 0} decimals={2} suffix="%" />
                </span>
              </h5>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, filteredData, destinationMarketplace],
  );

  const [selectStyle, setSelectStyle] = useState<any>();
  useMemo(() => {
    const customStyles: StylesConfig<Option, boolean> = {
      valueContainer: (provided: any) => ({
        ...provided,
        textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: selectedAsinFilterOptions && selectedAsinFilterOptions.length > 0 ? "unset" : "inline-grid",
        minHeight: "30px",
        height: "30px",
      }),
      control: (provided, state) => ({
        ...provided,
        minHeight: "30px",
        height: "30px",
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: "30px",
      }),
    };
    setSelectStyle(customStyles);
  }, [selectedAsinFilterOptions]);

  const renderOptionContainer = useCallback(
    (props: OptionProps<SelectOptionsType, boolean>) => {
      const itemAsin = props.data.value;
      const item = currentUserShipment.userShipmentItems.find((item) => item.userInventory.asin === itemAsin);
      const imagePath = `https://m.media-amazon.com/images/I/${item?.userInventory.imageSmall}`;

      return (
        <div>
          <components.Option {...props}>
            <Row className="d-flex align-items-center">
              <Col xs="auto">
                <Input type="checkbox" checked={props.isSelected} onChange={() => null} />
              </Col>
              <Col xs="auto" className="px-0">
                <div className="flex-shrink-0 overflow-hidden">
                  <img
                    className="rounded avatar-xs"
                    src={imagePath}
                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                      e.currentTarget.onerror = null;
                      e.currentTarget.src = errorImage;
                    }}
                    alt=""
                  />
                </div>
              </Col>
              <Col>
                <Row className="d-flex flex-column">
                  <Col>
                    <p className="ellipsis-single-line mb-0" title={item?.userInventory.productName}>
                      {item?.userInventory.productName}
                    </p>
                  </Col>
                  <Col>
                    <p className="ellipsis-single-line mb-0" title={item?.userInventory.productName}>
                      <span className="fw-medium">{props.label}</span>
                      <span className="text-decoration-underline ms-2">{item?.userInventory.sku}</span>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </components.Option>
        </div>
      );
    },
    [currentUserShipment],
  );

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs={12} md="auto" className="d-flex justify-content-lg-center align-items-lg-center mb-2 mb-md-0">
                <h5 className="mb-0 border-0 align-items-center d-flex">{t("Shipments.ShippedOrCompleted.Orders.Title")}</h5>
              </Col>
              <Col md></Col>
              {asinFilterOptions && asinFilterOptions.length > 1 && (
                <Col xs={12} md={4} className="d-flex justify-content-end mb-3 mb-md-0">
                  <Select
                    className="w-100"
                    styles={selectStyle}
                    components={{MultiValueContainer: multiValueContainer, Option: renderOptionContainer}}
                    options={asinFilterOptions}
                    value={selectedAsinFilterOptions}
                    onChange={(e: any) => handleAsinFilterChange(e)}
                    isClearable
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    placeholder={t("SelectAProduct")}
                  />
                </Col>
              )}

              <Col xs={12} md="auto">
                <div className="d-flex justify-content-end gap-1">
                  <Button type="button" color="soft-secondary" size="sm" onClick={() => handleTimeFilterChange("allTime")} active={selectedTimeFilter === "allTime"}>
                    {t("Shipments.ShippedOrCompleted.Orders.Button.AllTime")}
                  </Button>
                  <Button type="button" color="soft-secondary" size="sm" onClick={() => handleTimeFilterChange("last7Days")} active={selectedTimeFilter === "last7Days"}>
                    {t("Shipments.ShippedOrCompleted.Orders.Button.Last7Days")}
                  </Button>
                  <Button type="button" color="soft-secondary" size="sm" onClick={() => handleTimeFilterChange("last1Month")} active={selectedTimeFilter === "last1Month"}>
                    {t("Shipments.ShippedOrCompleted.Orders.Button.Last1Month")}
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {currentUserShipmentOrders && currentUserShipmentOrders.length > 0 ? (
              <DataTable
                trClass="text-start"
                tdClass="text-start"
                ref={tableRef}
                busy={false}
                columns={columns}
                data={displayData}
                totalDataLength={filteredData.length}
                pagination={{
                  pageIndex: query.page - 1,
                  pageSize: query.pageSize,
                }}
                onPaginationChanged={handlePaginationChange}
                hovered
              />
            ) : (
              <NoData icon="mdi mdi-note-search-outline" />
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Orders;

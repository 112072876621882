import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useProfile} from "Components/Hooks/useProfile";
import {refreshRemainingLimits} from "slices/thunks";
import {Link} from "react-router-dom";
import {UserLimitType} from "models/enums/user_limit_type";
import {LoginSlice} from "slices/auth/login/selector";
import Restricted from "./Restricted";
import DisplayNumber from "./DisplayNumber";

const UsageLimit: React.FC = () => {
  const {t} = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const {userProfile, hasPermission} = useProfile();
  const dispatch: any = useDispatch();
  const [dailySearchRemaining, setDailySearchRemaining] = useState<number>();
  const [dailyScanRemaining, setDailyScanRemaining] = useState<number>();
  const {loading} = useSelector(LoginSlice);

  useEffect(() => {
    if (hasPermission("account.limitUsageHistory")) {
      refreshRemainingLimits()(dispatch);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (userProfile) {
      const searchDailyValue = userProfile.remainingLimits?.remaining?.find((x) => x.type === UserLimitType.SEARCH)?.dailyValue || 0;
      const scanDailyValue = userProfile.remainingLimits?.remaining?.find((x) => x.type === UserLimitType.CRAWLER_SCAN)?.dailyValue || 0;

      setDailySearchRemaining(searchDailyValue);
      setDailyScanRemaining(scanDailyValue);
    }
  }, [userProfile, dispatch]);
  const containerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "transparent",
    position: "relative",
  };

  const overlayStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: dailySearchRemaining === 0 || dailyScanRemaining === 0 ? "#fce8e4" : "#ffe8c4",
    transition: "opacity 0.3s ease",
    opacity: isHovered ? 1 : 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Restricted require="account.limitUsageHistory">
      <div className="d-none d-md-block me-0 me-md-2" style={containerStyle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        {!loading.limit && (
          <div
            className={`d-flex align-items-center limits-badge px-3 rounded-pill ${
              dailySearchRemaining === 0 || dailyScanRemaining === 0 ? "bg-danger-subtle" : "bg-warning-limits-badge"
            } text-muted p-2`}
          >
            <div className="d-flex align-items-center fw-semibold text-muted fs-12">
              <i className="mdi mdi-speedometer me-1 fs-15"></i>
              {t("UsageLimits.DailyLimits")}:
            </div>
            <div className="ps-2">
              <div>
                <span className="fs-12 nowrap font-weight-normal">
                  {!loading.limit && t("UsageLimits.Analysis")}: {<DisplayNumber value={dailySearchRemaining} />}
                </span>
              </div>
              <div className="mt-1">
                <span className="fs-12 nowrap font-weight-normal">
                  {!loading.limit && t("UsageLimits.ScanAndSave")}: {<DisplayNumber value={dailyScanRemaining} />}
                </span>
              </div>
            </div>

            <div style={overlayStyle} className="rounded-pill">
              {dailySearchRemaining === 0 || dailyScanRemaining === 0 ? (
                <>
                  <Link to={"/account/subscription/change"} className="rounded-pill fw-semibold w-100 py-3 d-inline-block text-primary">
                    {t("UsageLimits.UpgradeNow")}
                  </Link>
                </>
              ) : (
                <>
                  <Link to={"/account/limit-usage-history"} className="rounded-pill fw-semibold w-100 py-3 d-inline-block text-primary">
                    {t("UsageLimits.UsageHistory")}
                  </Link>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </Restricted>
  );
};

export default UsageLimit;

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getDemoModeInfo, getLoggedUser, LOCAL_STORAGE, removeLoggedUserLocalData} from "helpers/local_storage";
import {ApiError, LoadingStates} from "helpers/types";
import {ApplicationUser, IUpdateUIPreferencesDto} from "models/application_user";
import {DateStyle} from "models/enums/date_style";
import {UserRemainingLimitViewModel} from "models/user_limit";

type LoginLoadingStates = LoadingStates<"login" | "logout" | "load" | "limit" | "loginWithToken" | "verify2fa">;
type LoginStatus = "empty" | "success" | "failed";

export type DemoMode = {
  active: boolean;
  date: Date;
};
export type LoginState = {
  loading: LoginLoadingStates;
  status: LoginStatus;
  error?: ApiError;
  user?: ApplicationUser;
  demoMode?: DemoMode;
};

const initialState: LoginState = {
  loading: {
    login: false,
    logout: false,
    load: false,
    limit: false,
    loginWithToken: false,
    verify2fa: false,
  },
  status: "empty",
  error: {} as ApiError,
  user: getLoggedUser(),
  demoMode: getDemoModeInfo(),
};

const loginSlice = createSlice({
  name: "Login",
  initialState,
  reducers: {
    setStatus(state, action: PayloadAction<LoginStatus>) {
      state.status = action.payload;
    },
    setLoginUser(state, action: PayloadAction<ApplicationUser | undefined>) {
      state.user = action.payload;
      state.error = undefined;
    },
    setLoggedUserRemainingLimits(state, action: PayloadAction<UserRemainingLimitViewModel>) {
      state.user!.remainingLimits = action.payload;
    },
    loading(state, action: PayloadAction<[keyof LoginLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    setUserPreferences(state, action: PayloadAction<IUpdateUIPreferencesDto>) {
      if (state.user) {
        var localUser: ApplicationUser = localStorage.getItem(LOCAL_STORAGE.LOGGED_USER) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.LOGGED_USER)!) : {};
        if (action.payload.language) {
          state.user.language = action.payload.language;
          localUser.language = action.payload.language;
        }

        if (action.payload.theme) {
          state.user.theme = action.payload.theme;
          localUser.theme = action.payload.theme;
        }

        if (action.payload.dateStyle === DateStyle.SHORT || action.payload.dateStyle === DateStyle.LONG) {
          state.user.dateStyle = action.payload.dateStyle;
          localUser.dateStyle = action.payload.dateStyle;
        }
        localStorage.setItem(LOCAL_STORAGE.LOGGED_USER, JSON.stringify(localUser));
      }
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.status = initialState.status;
      state.error = initialState.error;
      state.user = undefined;
      state.demoMode = initialState.demoMode;
      removeLoggedUserLocalData();
    },
  },
});

export const {setStatus, setLoginUser, setLoggedUserRemainingLimits, setUserPreferences, loading, apiError, reset} = loginSlice.actions;

export default loginSlice.reducer;

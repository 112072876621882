import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {UserInventory} from "models/user_inventory";
import {RootState} from "slices";
import CopyWidget from "Components/Common/CopyWidget";
import DomainToFlag from "Components/Common/DomainToFlag";
import SimpleBar from "simplebar-react";
import errorImage from "assets/images/svg/product.svg";
import NoData from "Components/Common/NoData";
import CountUp from "react-countup";
import {SellThisModal} from "Components/Common/SellThis";

const RestockInventory = () => {
  const {t} = useTranslation();
  const [restockInventories, setRestockInventories] = useState<UserInventory[]>([]);

  const dashboardData = createSelector(
    (state: RootState) => state,
    (state) => ({
      statistics: state.Dashboard.statistics,
    }),
  );
  const {statistics} = useSelector(dashboardData);

  useEffect(() => {
    if (statistics && statistics.restockInventories) {
      setRestockInventories(statistics.restockInventories.filter((i) => i.afnFulfillableQuantity !== undefined && i.afnFulfillableQuantity <= 0));
    }
  }, [statistics]);

  return (
    <React.Fragment>
      <Col xl={4}>
        <Card className="card-height-100 card-animate">
          <div className="align-items-center d-flex card-header">
            <h4 className="card-title mb-0 flex-grow-1">{t("Dashboard.RestockInventory.Title")}</h4>
            <div className="flex-shrink-0">
              <span className="fw-semibold fs-13">
                <CountUp start={0} decimals={0} duration={2} end={restockInventories.length} /> {restockInventories.length > 1 ? t("SKUs") : t("SKU")}
              </span>
            </div>
          </div>
          <CardBody>
            {restockInventories.length > 0 ? (
              <div className="table-responsive table-card">
                <SimpleBar style={{height: "558px"}}>
                  <table className="table-hover table table-centered align-middle mb-0">
                    <tbody>
                      {restockInventories.map((inventory) => {
                        const imagePath = inventory && `https://m.media-amazon.com/images/I/${inventory.imageSmall}`;
                        return (
                          <tr key={inventory.userInventoryId}>
                            <td>
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <h5 className="fs-14 mb-2 fw-normal text-center">
                                    <span className="badge rounded-pill bg-danger-subtle text-danger">{t("Dashboard.RestockInventory.Label.OutOfStock")}</span>
                                  </h5>
                                  <div className="avatar-md rounded flex-shrink-0 overflow-hidden">
                                    <img
                                      src={imagePath}
                                      alt=""
                                      className="member-img img-fluid d-block rounded"
                                      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                        e.currentTarget.onerror = null;
                                        e.currentTarget.src = errorImage;
                                      }}
                                    ></img>
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <div className="text-muted d-flex align-items-center text-nowrap mb-1">
                                    <DomainToFlag marketplace={inventory.userStore.marketplace} />
                                    <span>{`${inventory.userStore.marketplace} - ${inventory.userStore.name}`}</span>
                                  </div>
                                  <p className="ellipsis-two-lines mb-0" title={inventory.productName}>
                                    {inventory.productName}
                                  </p>
                                  <div className="d-flex flex-wrap gap-2 row-gap-0 align-items-center">
                                    <CopyWidget text={inventory.asin} index={inventory.asin} asLink={true} marketplace={inventory.userStore.marketplace} />
                                    <CopyWidget text={inventory.sku} index={inventory.sku} asLink={false} />
                                    <Button
                                      color="link"
                                      onClick={() => {
                                        SellThisModal.open({asin: inventory.asin, marketplace: inventory.userStore.marketplace});
                                      }}
                                      className="link-body-emphasis link-offset-1 text-decoration-underline link-underline-opacity-75-hover align-items-center d-flex p-0"
                                    >
                                      <i className="mdi mdi-cube-send fs-20 me-1"></i>
                                      {t("Dashboard.RestockInventory.Button.Resend")}
                                    </Button>
                                  </div>
                                  <Row className="text-muted">
                                    <div className="d-flex align-items-center col-auto">
                                      <i className="ri-bar-chart-fill text-danger fs-16 me-1"></i> {t("Dashboard.RestockInventory.Label.CurrentStock")}: 0
                                    </div>
                                    <div className="d-flex align-items-center col-auto">
                                      <i className="ri-history-fill text-success fs-16 me-1"></i> {t("Dashboard.RestockInventory.Label.Last30Days")}: {inventory.afnWarehouseQuantity}
                                    </div>
                                  </Row>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </SimpleBar>
              </div>
            ) : (
              <NoData icon="mdi mdi-note-search-outline" />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RestockInventory;

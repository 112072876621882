import useSelectOptions, {MarketplaceSelectOption} from "Components/Hooks/useSelectOptions";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row, Spinner, Form, Label} from "reactstrap";
import {useFormik} from "formik";
import {RefObject, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {sendAnalysis} from "slices/scan-and-save/thunk";
import {useDispatch, useSelector} from "react-redux";
import {SubmitForAnalysisCommand} from "api/command";
import {ScanAndSaveListRef} from "../../../pages/ScanAndSave";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {refreshRemainingLimits} from "slices/thunks";
import {createTypedModal} from "helpers/modal_helpers";
import {NumberRange} from "helpers/types";
import {getActiveUserStoresWithActiveMarketplaceOptions} from "helpers/utilities";
import {ScanAndSaveSlice} from "slices/scan-and-save/selector";
import ValidatedInput from "Components/Common/ValidatedInput";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import ValidatedNumberRange from "Components/Common/ValidatedNumberRange";
import ValidationWrapper from "Components/Common/ValidationWrapper";
import Checkbox from "Components/Common/Checkbox";
import i18n from "i18n";
import * as Yup from "yup";

interface ModalData {
  searchName: string;
  id: string;
  total: number;
  listRef: RefObject<ScanAndSaveListRef>;
  showAdminFields?: boolean;
}

export const SendAnalysisModal = createTypedModal<ModalData>("send_analysis");

const SendAnalysis = () => {
  const {t} = useTranslation();
  const {open, data} = SendAnalysisModal.useModal();
  const {businessModelSelectOptions} = useSelectOptions();
  const [marketplaceSelectOptions, setMarketplaceSelectOptions] = useState<MarketplaceSelectOption[]>([]);
  const [maxSendingAsinCount, setMaxSendingAsinCount] = useState<number>(0);

  useEffect(() => {
    if (validation.validateForm) {
      validation.validateForm();
    }
  }, [maxSendingAsinCount]); // eslint-disable-line

  const dispatch = useDispatch();
  const NumberRangeSchema = Yup.object<NumberRange>()
    .shape({
      min: Yup.number()
        .required("This field is required")
        .transform((value) => (value === 0 ? 1 : value))
        .min(1, i18n.t("ScanAndSave.Dialog.SendingAnalysisForm.Validation.AsinRangeInvalid")),
      max: Yup.number()
        .required("This field is required")
        .min(1, i18n.t("ScanAndSave.Dialog.SendingAnalysisForm.Validation.AsinRangeInvalid")),
    })
    .test("range-validations", "", function(value) {
      if (open && data) {
        if (value.max > data!.total) {
          return this.createError({
            path: this.path,
            message: i18n.t("ScanAndSave.Dialog.SendingAnalysisForm.Validation.AsinRangeInvalid"),
          });
        }

        // Check if min or max exceeds maxSendingAsinCount
        if (value.max - value.min > 5000) {
          return this.createError({
            path: this.path,
            message: i18n.t("ScanAndSave.Dialog.SendingAnalysisForm.Validation.SendingAsinCountMax5000"),
          });
        }

        // Check if min equals max
        if (value.min === value.max) {
          return this.createError({
            path: this.path,
            message: i18n.t("ScanAndSave.Dialog.SendingAnalysisForm.Validation.AsinRangeInvalid"),
          });
        }

        // Check min <= max
        if (value.min > value.max) {
          return this.createError({
            path: this.path,
            message: i18n.t("ScanAndSave.Dialog.SendingAnalysisForm.Validation.AsinRangeInvalid"),
          });
        }

        // Check range limit
        const range = value.max - value.min + 1;
        if (range > maxSendingAsinCount) {
          return this.createError({
            path: this.path,
            message: i18n.t("ScanAndSave.Dialog.SendingAnalysisForm.Validation.SendingAsinCountMax", {value: maxSendingAsinCount}),
          });
        }
      }

      return true;
    });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchName: data?.searchName,
      asinFetcherTaskId: data?.id,
      sendingAsinRange: {min: undefined, max: undefined},
      amazonBusinessModels: [] as AmazonBusinessModel[],
      marketplace: "",
      preventReduceLimits: data?.showAdminFields ? true : undefined,
    } as SubmitForAnalysisCommand,
    validationSchema: Yup.object<SubmitForAnalysisCommand>({
      searchName: Yup.string().required(t("ScanAndSave.Dialog.SendingAnalysisForm.Validation.AnalysisName")),
      sendingAsinRange: NumberRangeSchema,
      amazonBusinessModels: Yup.array<String>()
        .min(1, t("FieldRequired"))
        .required(t("FieldRequired")),
      marketplace: Yup.string().required(t("ScanAndSave.Dialog.SendingAnalysisForm.Validation.Marketplace")),
    }),
    onSubmit: (values: SubmitForAnalysisCommand) => {
      values.asinFetcherTaskId = data!.id;
      values.sendingAsinRange.min = values.sendingAsinRange.min === 0 ? 1 : values.sendingAsinRange.min;
      let payload = JSON.parse(JSON.stringify(values));
      const sendPromise = sendAnalysis(payload)(dispatch);

      sendPromise.then((isSuccess) => {
        if (isSuccess) {
          refreshRemainingLimits()(dispatch);
          data?.listRef.current?.reload();
          validation.resetForm();
          toggle();
        }
      });
    },
  });

  const {loading} = useSelector(ScanAndSaveSlice);

  const onDataChange = useCallback(() => {
    if (data) {
      const sendingAsinCount = data.total;
      if (sendingAsinCount < 5000) {
        validation.setFieldValue("sendingAsinRange.min", 1);
        validation.setFieldValue("sendingAsinRange.max", sendingAsinCount);
        setMaxSendingAsinCount(sendingAsinCount);
      } else {
        validation.setFieldValue("sendingAsinRange.min", 1);
        validation.setFieldValue("sendingAsinRange.max", 5000);
        setMaxSendingAsinCount(5000);
      }
      const nonUSMarketplaces = getActiveUserStoresWithActiveMarketplaceOptions().filter((x) => x.marketplace !== "US");
      if (data.showAdminFields) {
        setMarketplaceSelectOptions([...nonUSMarketplaces]);
      } else {
        setMarketplaceSelectOptions([...getActiveUserStoresWithActiveMarketplaceOptions()]);
      }
    }
  }, [data, open]); //eslint-disable-line

  useEffect(() => {
    onDataChange();
  }, [onDataChange]);

  const toggle = () => {
    SendAnalysisModal.close();
    validation.resetForm();
  };

  return (
    <Modal id="showSendModal" isOpen={open} toggle={toggle} centered size="md">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <ModalHeader className="p-3" toggle={toggle}>
          {t("ScanAndSave.Dialog.SendingAnalysisForm.Title")}
        </ModalHeader>
        <ModalBody className="p-4 d-flex flex-column gap-3">
          <Row className="">
            <Col>
              <Label htmlFor="searchName">{t("ScanAndSave.Dialog.SendingAnalysisForm.AnalysisName")}</Label>
              <ValidatedInput validation={validation} field="searchName" maxLength={100} placeholder={t("ScanAndSave.Dialog.SendingAnalysisForm.AnalysisName")} disableValidationUI />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label htmlFor="sendingAsinCount">{t("ScanAndSave.Dialog.SendingAnalysisForm.AsinNumberRange")}</Label>
              <ValidatedNumberRange
                field="sendingAsinRange"
                validation={validation}
                minPlaceholder={t("ScanAndSave.Dialog.SendingAnalysisForm.AsinNumberRangeStart")}
                maxPlaceholder={t("ScanAndSave.Dialog.SendingAnalysisForm.AsinNumberRangeEnd")}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label htmlFor="marketplace">{t("Business Model")}</Label>
              <ValidatedMultiSelect
                options={businessModelSelectOptions.filter((x) => x.value !== AmazonBusinessModel.WHOLESALE.toString())}
                validation={validation}
                field="amazonBusinessModels"
                errorStyle="container"
                value={validation.values.amazonBusinessModels}
                hasSelectAll
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label htmlFor="marketplace">{t("ScanAndSave.Dialog.SendingAnalysisForm.Marketplace")}</Label>
              <ValidatedSelect
                options={marketplaceSelectOptions}
                validation={validation}
                field="marketplace"
                value={validation.values.marketplace}
                errorStyle="container"
                optionStyle="marketplace"
                valueStyle="marketplace"
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {data?.showAdminFields && (
            <ValidationWrapper validation={validation} field="preventReduceLimits">
              <Checkbox label={t("Admin.ScanAndSave.Dialog.SendingAnalysisForm.Button.PreventReduceLimits")} />
            </ValidationWrapper>
          )}
          <div className="hstack gap-2 justify-content-end">
            <Button type="button" className="btn btn-light" disabled={loading.send} onClick={toggle}>
              {t("ScanAndSave.Dialog.SendingAnalysisForm.Button.Close")}
            </Button>

            <Button type="submit" color="success" className="d-block" disabled={loading.send}>
              {loading.send && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
              {t("ScanAndSave.Dialog.SendingAnalysisForm.Button.Analyze")}
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default SendAnalysis;

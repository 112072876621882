import useSelectOptions, {SelectOptionsType} from "Components/Hooks/useSelectOptions";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {RefObject, useCallback, useEffect, useState} from "react";
import {SearchesListRef} from "../../../pages/Searches/Searches";
import {Button, Col, Form, Label, Modal, ModalBody, Row, Spinner} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {ReAnalyzeCommand} from "api/command";
import {saveNewSearch} from "slices/thunks";
import {createTypedModal} from "helpers/modal_helpers";
import {getActiveUserStoresWithActiveMarketplaceOptions} from "helpers/utilities";
import {CommonSliceSelector} from "slices/common/selector";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";
import { reAnalyze } from "slices/common/thunk";

export const ReAnalyzeModal = createTypedModal<ModalData>("reanalyze");

export enum AnalyzeType {
  SEARCH = 0,
  SEARCH_PRODUCT = 1,
  FAVORITE = 2,
  SHIPMENT = 3,
}

interface ModalData {
  type: AnalyzeType;
  searchId?: string;
  favoriteId?: string;
  shipmentId?: string;
  searchName?: string;
  store: string;
  businessModel?: AmazonBusinessModel;
  asin?: string;
  currency?: string;
  price?: string;
}

interface ReAnalyzeProps {
  listRef?: RefObject<SearchesListRef>;
}
const ReAnalyze = (props: ReAnalyzeProps) => {
  const {t} = useTranslation();
  const {open, data} = ReAnalyzeModal.useModal();
  const {businessModelSelectOptions} = useSelectOptions();
  const [marketplaceOptions, setMarketplaceOptions] = useState<SelectOptionsType[]>([]);
  const [businessModelOptions, setBusinessModelOptions] = useState<SelectOptionsType[]>([]);
  const dispatch = useDispatch();
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();

  const {loading, activeUserStoreOptions, activeMarketplaceOptions} = useSelector(CommonSliceSelector);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchName: "",
      marketplace: "",
      businessModel: AmazonBusinessModel.CROSS_BORDER_ARBITRAGE,
      currency: "",
    } as ReAnalyzeCommand,
    validationSchema: Yup.object().shape({
      searchName: Yup.string().required(t("Common.Dialog.ReAnalyze.Validation.AnalysisName")),
      businessModel: Yup.mixed<AmazonBusinessModel>().required(t("Common.Dialog.ReAnalyze.Validation.BusinessModel")),
      marketplace: Yup.string().required(t("Common.Dialog.ReAnalyze.Validation.Marketplace")),
    }),
    onSubmit: (values: ReAnalyzeCommand) => {
      if (!data) return;

      const payload: ReAnalyzeCommand = {
        searchId: data.searchId,
        favoriteId: data.favoriteId,
        shipmentId: data.shipmentId,
        marketplace: values.marketplace,
        searchName: values.searchName,
        currency: values.currency,
        businessModel: values.businessModel,
      };

      if (data.type === AnalyzeType.SEARCH_PRODUCT && data.asin) {
        if (values.businessModel.toString() === AmazonBusinessModel.WHOLESALE.toString()) {
          const savePromise = saveNewSearch(
            {
              searchName: data.asin,
              marketplace: values.marketplace,
              amazonBusinessModel: AmazonBusinessModel.WHOLESALE,
              currency: data.currency!,
              identifierList: [`${data.asin};${data.price}`],
            },
            true,
          )(dispatch);

          savePromise.then((isSuccess) => {
            if (isSuccess) {
              props.listRef?.current?.reload();
              toggle();
            }
          });
        } else {
          window.open(`/dp/${data.asin}?marketplace=${values.marketplace}&amazonBusinessModel=${values.businessModel}`, "_blank");
          toggle();
        }
      } else {
        const reAnalyzePromise = reAnalyze(payload)(dispatch);
        reAnalyzePromise.then((isSuccess) => {
          if (isSuccess) {
            props.listRef?.current?.reload();
            toggle();
          }
        });
      }
    },
  });

  const initModal = useCallback(() => {
    if (!open) return;

    switch (data?.type) {
      case AnalyzeType.SEARCH:
        setTitle(t("Common.Dialog.ReAnalyze.Search.Title"));
        setDescription(t("Common.Dialog.ReAnalyze.Search.Description"));
        break;
      case AnalyzeType.SEARCH_PRODUCT:
        setTitle(t("Common.Dialog.ReAnalyze.Product.Title"));
        setDescription(t("Common.Dialog.ReAnalyze.Product.Description"));
        break;
      case AnalyzeType.FAVORITE:
        setTitle(t("Common.Dialog.ReAnalyze.Favorite.Title"));
        setDescription(t("Common.Dialog.ReAnalyze.Favorite.Description"));
        break;
      case AnalyzeType.SHIPMENT:
        setTitle(t("Common.Dialog.ReAnalyze.Shipment.Title"));
        setDescription(t("Common.Dialog.ReAnalyze.Shipment.Description"));
        break;
    }

    var options = businessModelSelectOptions;
    if (data?.businessModel === AmazonBusinessModel.CROSS_BORDER_ARBITRAGE || data?.businessModel === AmazonBusinessModel.DROPSHIPPING) {
      options = businessModelSelectOptions.filter((x) => x.value.toString() !== AmazonBusinessModel.WHOLESALE.toString());
    } else if (data?.businessModel === AmazonBusinessModel.WHOLESALE) {
      options = businessModelSelectOptions.filter((x) => x.value.toString() === AmazonBusinessModel.WHOLESALE.toString());
    }

    validation.setFieldValue("businessModel", data?.businessModel?.toString());
    validation.setFieldValue("searchName", data?.searchName);
    validation.setFieldValue("marketplace", data?.store);
    setBusinessModelOptions(options);
  }, [open]); // eslint-disable-line

  const onBusinessModelChange = useCallback(() => {
    if (!validation.values.businessModel) return;

    const selectedBusinessModel = validation.values.businessModel.toString();
    const nonUSOptions = getActiveUserStoresWithActiveMarketplaceOptions().filter((x) => x.marketplace !== "US");
    const marketplaces = selectedBusinessModel === AmazonBusinessModel.CROSS_BORDER_ARBITRAGE.toString() ? [...nonUSOptions] : [...getActiveUserStoresWithActiveMarketplaceOptions()];

    setMarketplaceOptions(marketplaces);
  }, [validation.values.businessModel, activeUserStoreOptions, activeMarketplaceOptions]); // eslint-disable-line

  useEffect(() => {
    initModal();
  }, [initModal]);

  useEffect(() => {
    onBusinessModelChange();
  }, [onBusinessModelChange]);

  const toggle = () => {
    ReAnalyzeModal.close();
    validation.resetForm();
  };

  return (
    <Modal backdrop="static" isOpen={open} toggle={toggle} centered={true} size="md">
      <ModalBody className="p-4">
        <Form onSubmit={validation.handleSubmit}>
          <div className="mt-2 text-center">
            <i className="mdi mdi-chart-timeline-variant-shimmer display-5 text-info"></i>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-4">
              <h4>{title}</h4>
              <p className="text-muted mx-4 mb-0 mt-3">{description}</p>
            </div>
          </div>
          <div className="mt-4 d-flex justify-content-center flex-column gap-3">
            <>
              {data?.type !== AnalyzeType.SEARCH_PRODUCT && (
                <Row>
                  <Col>
                    <Label htmlFor="searchName">{t("Analysis Name")}</Label>
                    <ValidatedInput validation={validation} type="text" field="searchName" maxLength={100} placeholder={t("Analysis Name")} disableValidationUI />
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Label htmlFor="sendingAsinCount">{t("Business Model")}</Label>
                  <ValidatedSelect className="w-100" errorStyle="container" options={businessModelOptions} validation={validation} field="businessModel" value={validation.values.businessModel} />
                </Col>
              </Row>
            </>
            <Row>
              <Col>
                <Label htmlFor="marketplace">{t("Marketplace")}</Label>
                <ValidatedSelect
                  className={`new-search filter-input ${validation.errors.marketplace && validation.touched.marketplace && "form-control is-invalid form-select-invalid"}`}
                  options={marketplaceOptions}
                  validation={validation}
                  field="marketplace"
                  value={validation.values.marketplace}
                  errorStyle="container"
                  optionStyle="marketplace"
                  valueStyle="marketplace"
                />
              </Col>
            </Row>
          </div>

          <div className="mt-4 mb-2">
            <div className="d-flex gap-2 justify-content-center">
              <Button type="submit" color="success" disabled={loading.reanalyze}>
                {loading.reanalyze && <Spinner size="sm" color="light" className="me-2" />}
                {t("Common.Dialog.ReAnalyze.SubmitButton")}
              </Button>
              <Button type="button" color="light" onClick={toggle} disabled={loading.reanalyze}>
                {t("Common.Dialog.ReAnalyze.CancelButton")}
              </Button>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ReAnalyze;

import {Modal, ModalHeader, ModalBody, ModalFooter, Button, FormFeedback, Col, Row, Spinner, Form} from "reactstrap";
import {useFormik} from "formik";
import {RefObject, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ScanAndSaveListRef} from "..";
import {saveScan} from "slices/scan-and-save/thunk";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useDispatch, useSelector} from "react-redux";
import {useProfile} from "Components/Hooks/useProfile";
import {getRelativeDate, getToday, isValidHttpUrl} from "helpers/utilities";
import ValidatedLineNumberedTextarea from "Components/Common/ValidatedLineNumberedTextarea";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";
import DisplayNumber from "Components/Common/DisplayNumber";

const DAILY_SCAN_LIMIT = 5;
interface NewScanProps {
  busy: boolean;
  isOpen: boolean;
  listRef: RefObject<ScanAndSaveListRef>;
  toggle: () => void;
}
const NewScan = (props: NewScanProps) => {
  const {getDailyScanLimit} = useProfile();
  const {t} = useTranslation();
  const dispatch: any = useDispatch();
  const [liveLimit, setLiveLimit] = useState<number>(getDailyScanLimit());
  const [maxLineLimit, setMaxLineLimit] = useState<number>(DAILY_SCAN_LIMIT);
  const startDate = getRelativeDate(30);

  const scanAndSave = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.ScanAndSave.loading,
    }),
  );

  const {loading} = useSelector(scanAndSave);

  const validation = useFormik({
    initialValues: {
      searchName: "",
      crawlerUrls: "",
      startDate: startDate,
      endDate: getToday().toDate(),
    },
    validationSchema: Yup.object().shape({
      searchName: Yup.string().required(t("ScanAndSave.Dialog.NewScanAndSave.Validation.ScanName")),
      crawlerUrls: Yup.string().test("invalid-asin-list", function(value) {
        if (!value || !value.trim()) {
          return this.createError({
            path: "crawlerUrls",
            message: t("ScanAndSave.Dialog.NewScanAndSave.Validation.List"),
          });
        }

        let crawlerUrls = value!.split("\n").filter((url: string) => url.trim() !== "");
        if (crawlerUrls.length === 0) {
          return this.createError({
            path: "crawlerUrls",
            message: t("ScanAndSave.Dialog.NewScanAndSave.Validation.ListMin"),
          });
        }

        const invalidExist = crawlerUrls.find((url: string) => !isValidHttpUrl(url));
        if (invalidExist) {
          return this.createError({
            path: "crawlerUrls",
            message: t("ScanAndSave.Dialog.NewScanAndSave.Validation.InvalidUrl"),
          });
        }
        return true;
      }),
    }),
    onSubmit: (values: any) => {
      const urls = values.crawlerUrls.split("\n").filter((url: string) => url.trim() !== ""); // clear empty lines

      const savePromise = saveScan(values.searchName, urls)(dispatch);

      savePromise.then((isSuccess) => {
        if (isSuccess) {
          validation.resetForm();
          props.listRef.current?.reload();
          props.toggle();
        }
      });
    },
  });

  useEffect(() => {
    let listLength = validation.values.crawlerUrls.split("\n").filter((url: string) => url.trim() !== "").length;
    listLength = validation.values.crawlerUrls === "" ? 0 : listLength;
    var remaining = getDailyScanLimit() - listLength;

    if (listLength === 0) {
      setLiveLimit(getDailyScanLimit());
    }
    if (getDailyScanLimit() > DAILY_SCAN_LIMIT) {
      setLiveLimit(remaining);
      setMaxLineLimit(DAILY_SCAN_LIMIT);
    } else {
      setMaxLineLimit(remaining);
      setLiveLimit(remaining);
    }
  }, [validation.values.crawlerUrls]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggle = () => {
    props.toggle();
    validation.resetForm();
  };
  return (
    <>
      <Modal id="showModal" className="modal-lg" isOpen={props.isOpen} toggle={toggle} centered>
        <ModalHeader className="p-3" toggle={toggle}>
          {t("ScanAndSave.Dialog.NewScanAndSave.Title")}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row className="mb-3">
              <Col xs={12} lg={5}>
                <ValidatedInput
                  validation={validation}
                  className="w-100"
                  field={"searchName"}
                  maxLength={100}
                  placeholder={t("ScanAndSave.Dialog.NewScanAndSave.ScanNamePlaceholder")}
                  disableValidationUI
                />
              </Col>
              <Col xs={12} lg className="d-flex justify-content-end">
                <div className="d-block">
                  <div className="rounded-pill bg-opacity-25 bg-warning">
                    <div className="px-3 py-2 d-flex align-items-center justify-content-end">
                      <i className="mdi mdi-speedometer me-1 fs-15 text-black-50"></i>
                      <span className="fw-semibold fs-12 text-black-50">{t("Searches.Dialog.NewSearch.RemainingUsageLimit")}</span>
                      <span className="text-danger ms-1 fw-semibold">{<DisplayNumber value={liveLimit} />}</span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                <ValidatedLineNumberedTextarea validation={validation} field="crawlerUrls" placeholder={t("ScanAndSave.Dialog.NewScanAndSave.ListPlaceholder")} maxLines={maxLineLimit} />
                {validation.touched.crawlerUrls && validation.errors.crawlerUrls ? (
                  <FormFeedback type="invalid" className="new-search validation-width w-100">
                    {validation.errors.crawlerUrls.toString()} <br />
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button type="button" className="btn btn-light" disabled={loading.save} onClick={() => toggle()}>
              {t("ScanAndSave.Dialog.NewScanAndSave.Button.Close")}
            </Button>

            <Button
              type="submit"
              className="btn btn-success"
              disabled={loading.save}
              onClick={() => {
                validation.setFieldTouched("searchName");
                validation.setFieldTouched("crawlerUrls");
                if (validation.isValid) {
                  validation.handleSubmit();
                }
              }}
            >
              {loading.save && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
              {t("ScanAndSave.Dialog.NewScanAndSave.Button.Save")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default NewScan;

import { ApplicationUser } from "models/application_user";
import { Navigate, Outlet } from "react-router-dom";

interface PublicRoutesProps {
  user?: ApplicationUser;
  isTokenValid: boolean;
}
const PublicRoutes = ({ user, isTokenValid }: PublicRoutesProps) => {
  const queryParams = new URLSearchParams(window.location.search);
  const auth = queryParams.get("auth");
  const returnUrl = queryParams.get("return_url");

  if (window.location.pathname === "/account/logout") {
    return <Outlet />;
  }

  if (user && isTokenValid) {
    if (window.location.pathname === "/account/login") {
      if (auth) {
        return <Outlet />;
      }
      return <Navigate to={returnUrl || "/"} replace />;
    }
  }

  return <Outlet />;
};

export default PublicRoutes;
import {UserShipment} from "./user_shipment";
import {UserStore} from "./user_stores";
import {OrderStatus} from "./enums/order_status";
import {UserOrderItem} from "./user_order_item";

export interface UserOrder {
  userOrderId: string;
  userId: string;
  userStoreId: string;
  amazonOrderId?: string;
  purchaseDate?: Date;
  lastUpdateDate?: Date;
  orderStatus?: OrderStatus;
  fulfillmentChannel?: FulfillmentChannel;
  orderTotal?: number;
  numberOfItemsShipped?: number;
  numberOfItemsUnshipped?: number;
  marketplaceId?: string;
  shipmentServiceLevelCategory?: string;
  orderType?: OrderType;
  latestShipDate?: Date;
  latestDeliveryDate?: Date;
  isBusinessOrder?: boolean;
  isPrime?: boolean;
  quantityOrdered?: number;
  totalCost?: number;
  totalCostTarget?: number;
  costIsPredicted?: boolean;
  marketplaceFacilitatorTax?: number;
  promotions?: number;
  totalFBAFee?: number;
  totalReferralFee?: number;
  totalDigitalServicesFee?: number;
  totalOtherFees?: number;
  totalFees?: number;
  totalTax?: number;
  totalSellPrice?: number;
  marketplaceTaxExceptionRate?: number;
  marketplaceTaxException?: number;
  totalSalesProceeds?: number;
  profit?: number;
  margin?: number;
  roi?: number;
  shippingPriceFinance?: number;
  shippingTaxFinance?: number;
  currencyCodeSource?: string;
  currencyCodeTarget?: string;
  exchangeRateSource?: number;
  exchangeRateTarget?: number;
  stateSalesTaxRate?: number;
  financeStatus: FinanceStatus;
  shipmentCompleted: boolean;
  shippedDate?: Date;
  createDate: Date;
  updateDate: Date;
  userStore: UserStore;
  userOrderItems: UserOrderItem[];
}

export interface UserOrderWithShipments extends UserOrder {
  userShipments: UserShipment[];
}

export enum FulfillmentChannel {
  Merchant = 1,
  Amazon = 2,
}

export enum FinanceStatus {
  Initial = 0,
  Partial = 1,
  Completed = 2,
  NotFound = 3,
  Error = 4,
}

enum OrderType {
  STANDARD_ORDER = 1,
  LONG_LEAD_TIME_ORDER = 2,
  PRE_ORDER = 3,
  BACK_ORDER = 4,
  SOURCING_ON_DEMAND_ORDER = 5,
}

export type OrderShipments = {
  userShipmentId: string;
  userShipmentItemId: string;
  shipment: string;
  asin: string;
  quantity: number;
  status: string;
  availableCount: number;
};

import DataTable, {DataTableRef} from "Components/Common/DataTable";
import ReAnalyze, {AnalyzeType, ReAnalyzeModal} from "Components/Common/Modals/ReAnalyze";
import {getRelativeDateByTimezone, getToday, numberFormatter} from "helpers/utilities";
import {CSSProperties, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {UserSearchesQuery, UserSearch} from "models/user_search";
import {Button, Card, CardBody, Container, Progress} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useProfile} from "Components/Hooks/useProfile";
import {ColumnDef} from "@tanstack/react-table";
import {Link} from "react-router-dom";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/useDebounce";
import {deleteSearch, getUserSearchesList, markSearchReviewed, resetSearchesState} from "slices/searches/thunk";
import {BusinessModelBadge} from "Components/Common/Badges/BusinessModelBadge";
import {SearchStatusBadge} from "Components/Common/Badges/SearchStatusBadge";
import {UserSearchStatus} from "models/enums/user_search_status";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import {AllMarketplaces} from "helpers/marketplace_helper";
import {SearchesSlice} from "slices/searches/selector";
import Loader from "Components/Common/Loader";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import NewSearch from "./Modals/NewSearch";
import BreadCrumb from "Components/Common/BreadCrumb";
import Filters from "./Filters";
import NoResult from "Components/Common/NoResult";
import DisplayNumber from "Components/Common/DisplayNumber";
import moment from "moment";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayDate from "Components/Common/DisplayDate";
import defaultAvatar from "assets/images/user-dummy-img.jpg";
import useSelectOptions from "Components/Hooks/useSelectOptions";
import _ from "lodash";

export type SearchesListRef = {
  reload: VoidFunction;
};
interface SearchesListProps {
  bulkSearch?: boolean;
}
const SearchesPage = (props: SearchesListProps) => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const {isReviewedSelectOptions} = useSelectOptions();
  const [localQuery, updateLocalQuery] = useImmer<UserSearchesQuery>({
    action: "filtering",
    startDate: undefined,
    endDate: undefined,
    page: 1,
    pageSize: 10,
  });

  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<SearchesListRef>(null);
  const dispatch: any = useDispatch();

  let filterFields: FieldConfig<UserSearchesQuery>[] = [
    {field: "startDate", queryParam: "start", type: "date", defaultValue: getRelativeDateByTimezone(30, userProfile?.timezone)},
    {field: "endDate", queryParam: "end", type: "date", defaultValue: getToday().toDate()},
    {field: "marketplace", queryParam: "marketplace", type: "array"},
    {field: "statuses", queryParam: "statuses", type: "array"},
    {field: "amazonBusinessModels", queryParam: "businessModels", type: "array"},
    {field: "teamUserIds", queryParam: "teamUsers", type: "array"},
    {field: "searchType", queryParam: "searchType", type: "string"},
    {field: "isReviewed", queryParam: "isReviewed", type: "string", defaultValue: isReviewedSelectOptions[0].value},
    {field: "page", queryParam: "page", type: "number", defaultValue: localQuery.page},
    {field: "pageSize", queryParam: "pageSize", type: "number", defaultValue: localQuery.pageSize},
    {field: "sortBy", queryParam: "sortBy", type: "string", defaultValue: localQuery.sortBy},
    {field: "sortingOrder", queryParam: "sortingOrder", type: "number", defaultValue: localQuery.sortingOrder},
    {field: "action", queryParam: "action", type: "string", defaultValue: "filtering"},
  ];

  const debouncedLoadList = useDebounce(() => {
    getUserSearchesList(localQuery)(dispatch).then(() => {
      tableRef.current?.resetSelection();
    });
  }, 200);
  const {readQueryParams, updateQuery, searchParams} = useUrlQuery<UserSearchesQuery>(filterFields);

  useEffect(() => {
    if (hasPermission("search") && searchParams.has("action")) {
      const params = readQueryParams();
      updateLocalQuery(params as UserSearchesQuery);
      debouncedLoadList();
    }
  }, [debouncedLoadList, searchParams]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const {loading, searchesList} = useSelector(SearchesSlice);

  const columns = useMemo<ColumnDef<UserSearch, any>[]>(
    () => [
      {
        header: t("Actions"),
        enableSorting: false,
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          const [isReviewing, setIsReviewing] = useState<boolean>(false); // eslint-disable-line
          const [isDeletingOrRestoring, setIsDeletingOrRestoring] = useState<boolean>(false); // eslint-disable-line

          const disableStyle: CSSProperties = row.status === UserSearchStatus.INITIAL || row.status === UserSearchStatus.IN_PROGRESS ? {pointerEvents: "none", color: "#dedede"} : {};
          const searchIsBusy: boolean = row.status === UserSearchStatus.INITIAL || row.status === UserSearchStatus.IN_PROGRESS;
          const handleMarkAsReviewed = async () => {
            setIsReviewing(true);
            if (row.isReviewed === true) {
              await markSearchReviewed({userSearchId: row.userSearchId, isReviewed: false})(dispatch);
            } else {
              await markSearchReviewed({userSearchId: row.userSearchId, isReviewed: true})(dispatch);
            }
            setIsReviewing(false);
          };

          const handleDeleteOrRestore = async () => {
            setIsDeletingOrRestoring(true);
            await deleteSearch({searchId: row.userSearchId, deleted: !row.deleted}, row.name)(dispatch);
            setIsDeletingOrRestoring(false);
          };

          return (
            <div className="d-flex align-items-center">
              <Button
                id={`ReAnalyze-${row.userSearchId}`}
                className="btn-link px-1 py-0 fs-18 "
                type="button"
                color="ghost-secondary"
                onClick={() => {
                  ReAnalyzeModal.open({
                    searchName: row.name,
                    store: row.userStoreId || row.marketplaceTarget,
                    searchId: row.userSearchId,
                    businessModel: row.amazonBusinessModel,
                    type: AnalyzeType.SEARCH,
                  });
                }}
                disabled={searchIsBusy}
              >
                <i className="mdi mdi-chart-timeline-variant-shimmer align-middle"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`ReAnalyze-${row.userSearchId}`}>{t("Searches.Tooltip.ReAnalyzeSearch")}</DefaultUncontrolledTooltip>

              <Button
                id={`IsReviewed-${row.userSearchId}`}
                color={row.isReviewed ? "light" : "ghost-dark"}
                className="px-1 py-0 fs-18"
                onClick={handleMarkAsReviewed}
                disabled={isReviewing || searchIsBusy}
                style={disableStyle}
              >
                <i className={`ri-check-double-fill align-middle ${row.isReviewed ? "text-secondary" : "text-primary-emphasis"}`}></i>
              </Button>
              <DefaultUncontrolledTooltip target={`IsReviewed-${row.userSearchId}`}>{row.isReviewed ? t("Searches.Tooltip.NotReviewed") : t("Searches.Tooltip.IsReviewed")}</DefaultUncontrolledTooltip>

              <Button
                id={`DeleteSearch-${row.userSearchId}`}
                color={row.deleted ? "ghost-secondary" : "ghost-danger"}
                className="btn-link px-1 py-0 fs-18"
                onClick={handleDeleteOrRestore}
                disabled={isDeletingOrRestoring || searchIsBusy}
              >
                {row.deleted ? <i className="ri-arrow-go-back-line align-middle" /> : <i className="ri-delete-bin-fill align-middle"></i>}
              </Button>
              <DefaultUncontrolledTooltip target={`DeleteSearch-${row.userSearchId}`}>
                {row.deleted ? t("Searches.Tooltip.RestoreSearch") : t("Searches.Tooltip.DeleteSearch")}
              </DefaultUncontrolledTooltip>
            </div>
          );
        },
      },
      {
        id: "name",
        header: t("Searches.TableColumn.SearchName"),
        enableSorting: true,
        accessorKey: "name",
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <div className="d-flex align-items-center">
              <div className="nowrap">
                <Link className="link-secondary link-offset-2 text-decoration-underline me-1" role="button" to={`/searches/search-results/${row.userSearchId}`}>
                  {userProfile?.teamUsers && userProfile?.teamUsers.length > 0 && (
                    <>
                      <img
                        id={`CreatedUserAvatarTooltip-${row.userSearchId}-${row.user.userId}`}
                        src={row.user.avatar || defaultAvatar}
                        alt={`User Profile - ${row.user.fullName}`}
                        className="rounded-circle me-1 border"
                        style={{width: "16px", height: "16px"}}
                      />
                      <DefaultUncontrolledTooltip target={`CreatedUserAvatarTooltip-${row.userSearchId}-${row.userId}`}>
                        {t("Searches.Tooltip.CreatedBy", {value: row.user.userId === userProfile?.userId ? t("You") : `${row.user.fullName} (${row.user.email})`})}
                      </DefaultUncontrolledTooltip>
                    </>
                  )}
                  {_.truncate(row.name, {
                    length: 30,
                    omission: "...",
                  })}
                </Link>
              </div>
            </div>
          );
        },
      },
      {
        header: t("Searches.TableColumn.Marketplace"),
        enableSorting: true,
        accessorKey: "marketplaceTarget",
        sortingFn: "alphanumeric",
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <>
              <span className="hstack gap-2">
                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                  <img src={AllMarketplaces.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === row.marketplaceTarget)?.flag} alt="Country Flag" className=" rounded-circle" />
                </div>
                {row.userStore?.name ? row.marketplaceTarget : AllMarketplaces.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === row.marketplaceTarget)?.countryName}
                {row.userStore ? ` - ${row.userStore.name}` : ""}
              </span>
            </>
          );
        },
      },
      {
        header: t("Searches.TableColumn.BusinessModel"),
        enableSorting: true,
        accessorKey: "amazonBusinessModel",
        sortingFn: "alphanumeric",
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <>
              <div className="d-flex align-items-center">
                <BusinessModelBadge model={row.amazonBusinessModel} size="sm" />
              </div>
            </>
          );
        },
      },
      {
        header: t("Searches.TableColumn.CreateDate"),
        enableSorting: true,
        accessorKey: "createDate",
        sortingFn: "datetime",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <div className="d-flex align-items-center nowrap">
              <DisplayDate id={`DisplayDateSearchesCreateDate-${row.userSearchId}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
              {getToday().diff(moment.tz(row.createDate, userProfile?.timezone!), "hours") > 48 && (
                <>
                  <i
                    className={`ri-error-warning-fill ${getToday().diff(moment.tz(row.createDate, userProfile?.timezone!), "days") > 7 ? "text-danger" : "text-warning"} ms-1`}
                    id={`AnalysisWarningMessage-${row.userSearchId}`}
                  ></i>
                  <DefaultUncontrolledTooltip target={`AnalysisWarningMessage-${row.userSearchId}`}>{t("SearchResults.Summary.AnalysisWarningMessage", {hours: 48})}</DefaultUncontrolledTooltip>
                </>
              )}
            </div>
          );
        },
      },
      {
        header: t("Searches.TableColumn.Status"),
        accessorKey: "status",
        sortingFn: "alphanumericCaseSensitive",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <>
              <div className="d-flex align-items-center">
                <SearchStatusBadge status={row.status} size="sm" />
                {row.status === UserSearchStatus.COMPLETED && row.restrictionCheckCompletedProductCount !== row.completedProductCount && (
                  <>
                    <i id={`RestrictionCheckTooltip-${row.userSearchId}`} className="bx bxs-time-five fs-16 text-warning ms-1"></i>
                    <DefaultUncontrolledTooltip target={`RestrictionCheckTooltip-${row.userSearchId}`}>
                      {t("Searches.RestrictionChecksInProgressMessage", {productCount: numberFormatter(row.totalProductCount - row.restrictionCheckCompletedProductCount)})}
                    </DefaultUncontrolledTooltip>
                  </>
                )}
              </div>
            </>
          );
        },
      },
      {
        header: t("Searches.TableColumn.Total"),
        enableSorting: true,
        accessorKey: "totalProductCount",
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <Link className="link-secondary link-offset-2 text-decoration-underline me-1" role="button" to={`/searches/search-results/${row.userSearchId}`}>
              <DisplayNumber value={row.totalProductCount} />
            </Link>
          );
        },
      },
      {
        header: t("Searches.TableColumn.Completed"),
        enableSorting: true,
        accessorKey: "completedProductCount",
        sortingFn: "alphanumeric",
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return row.status === UserSearchStatus.IN_PROGRESS ? (
            <>
              <div className="align-items-center d-flex">
                <DisplayNumber value={row.completedProductCount} />
                <i className="mdi mdi-spin mdi-loading fs-20 ms-2"></i>
              </div>
              <div style={{width: "90%"}}>
                <Progress color="secondary" value={(row.completedProductCount / row.totalProductCount) * 100} className="progress-sm" />
              </div>
            </>
          ) : (
            <DisplayNumber value={row.completedProductCount} />
          );
        },
      },
      {
        header: t("Searches.TableColumn.Sellable"),
        enableSorting: true,
        accessorKey: "sellableProductCount",
        sortingFn: "alphanumeric",
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return <DisplayNumber value={row.sellableProductCount} />;
        },
      },
      {
        header: t("Searches.TableColumn.Recheck"),
        enableSorting: true,
        accessorKey: "recheckProductCount",
        sortingFn: "alphanumeric",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return <DisplayNumber value={row.recheckProductCount} />;
        },
      },
    ],
    [t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    return () => {
      dispatch(resetSearchesState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.title = t("PageTitles.Searches");

  return (
    <Restricted require={"search"} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <Restricted require="search" create>
            <NewSearch isOpen={props.bulkSearch || false} busy={loading.filter} listRef={listRef} />
            <ReAnalyze listRef={listRef} />
          </Restricted>
          <BreadCrumb title={t("Searches.Title")} menus={[{label: t("Searches.Title")}]} />
          <Filters busy={loading.filter} fields={filterFields} listRef={listRef} />
          <Card>
            <CardBody>
              {loading.filter ? (
                <>
                  <Loader />
                </>
              ) : searchesList.items && searchesList.items.length > 0 ? (
                <>
                  <DataTable
                    ref={tableRef}
                    busy={loading.list}
                    columns={columns}
                    data={searchesList?.items || []}
                    totalDataLength={searchesList?.totalCount}
                    pagination={{
                      pageIndex: localQuery.page - 1,
                      pageSize: localQuery.pageSize,
                    }}
                    onPaginationChanged={(pagination) => {
                      updateQuery({...localQuery, page: pagination.pageIndex + 1, pageSize: pagination.pageSize, action: "paginating"});
                    }}
                    onSortingChanged={(sorting) => {
                      updateQuery({...localQuery, sortBy: sorting?.sortBy, sortingOrder: sorting?.sortingOrder, action: "sorting"});
                    }}
                    hovered
                  />
                </>
              ) : (
                <NoResult title={t("Searches.NoResult.Title")} description={t("Searches.NoResult.Description")} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </Restricted>
  );
};

export default SearchesPage;

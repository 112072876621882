import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {ButtonGroup, Card, CardBody, Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {memo, useEffect, useImperativeHandle, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useImmer} from "use-immer";
import {SearchResultQuery} from "models/search_result";
import {ColumnDef} from "@tanstack/react-table";
import {UserSearchProduct} from "models/user_search_product";
import {useParams} from "react-router-dom";
import {useDebounce} from "Components/Hooks/useDebounce";
import {useProfile} from "Components/Hooks/useProfile";
import {getUserSearchResults, getUserSearchResultsFilters, resetSearchResultState} from "slices/search-result/thunk";
import {UserSearchProductStatus} from "models/enums/user_search_product_status";
import {BusinessModelBadge} from "Components/Common/Badges/BusinessModelBadge";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import {SorterQuery} from "helpers/types";
import {SortingDirection} from "models/enums/sorting_direction";
import {SearchResultSlice} from "slices/search-result/selector";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import Filters from "./Filter";
import Loader from "Components/Common/Loader";
import NoResult from "Components/Common/NoResult";
import SearchResultItem from "./Item";
import InProgress from "./Item/Components/InProgress";
import ProductNotExist from "./Item/Components/ProductNotExist";
import SellThis from "Components/Common/SellThis";
import useSelectOptions, {SortSelectOption} from "Components/Hooks/useSelectOptions";
import ReportError from "./Modals/ReportError";
import IneligibilityReasonList from "./Modals/IneligibilityReasonList";
import DecisionReasons from "./Modals/DecisionReasons";
import CheckList from "./Modals/CheckList";
import FBASellers from "./Modals/FBASellers";
import RestrictionReasonCodes from "./Modals/RestrictionReason";
import AddToFavorites from "./Modals/AddToFavorites";
import ReAnalyze from "Components/Common/Modals/ReAnalyze";
import DefinedFilters from "./Modals/DefinedFilter";
import Notes from "./Modals/Notes";

export type SearchResultListRef = {
  reload: VoidFunction;
};
const SearchResults = () => {
  const {t} = useTranslation();
  const {searchId, favoriteId} = useParams();
  const {hasPermission} = useProfile();
  const dispatch = useDispatch();
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<SearchResultListRef>(null);
  const {commonProductSelectOptions, restrictionCheckSelectOptions, dateRangeSelectOptions} = useSelectOptions();
  const {result, loading} = useSelector(SearchResultSlice);
  const {search: searchObject, favorite: favoriteObject, products} = result;

  const [localQuery, updateLocalQuery] = useImmer<SearchResultQuery>({
    action: "filtering",
    page: 1,
    pageSize: 10,
    searchId,
    favoriteId,
  });

  const isFavoritePage = favoriteId !== undefined;
  const filterFields: FieldConfig<SearchResultQuery>[] = useMemo(
    () => [
      {field: "searchId", queryParam: "searchId", type: "string", defaultValue: searchId || undefined, hideQuery: true, hideChip: true},
      {field: "favoriteId", queryParam: "favoriteId", type: "string", defaultValue: favoriteId || undefined, hideQuery: true, hideChip: true},
      {field: "decision", queryParam: "decision", type: "array", chipName: t("SearchResults.Filters.Decision")},
      {field: "amazonCatalogStatus", queryParam: "amazonCatalogStatus", chipName: t("SearchResults.Filters.AmazonCatalogStatus"), type: "array"},
      {field: "amazonBusinessModels", queryParam: "businessModels", type: "array", chipName: t("Business Model")},
      {field: "keyword", queryParam: "asin", type: "string", chipName: t("SearchResults.Filters.Search"), defaultValue: undefined},
      {field: "profitMarginRange", queryParam: "profitMargin", type: "numberRange", chipName: t("SearchResults.Filters.ProfitMargin")},
      {field: "fbaSellerCountRange", queryParam: "fbaSellerCount", type: "numberRange", chipName: t("SearchResults.Filters.FBASellerCount")},
      {field: "saleCountRange", queryParam: "saleCount", type: "numberRange", chipName: t("SearchResults.Filters.SaleCount")},
      {field: "priceChangeRange", queryParam: "priceChange", type: "numberRange", chipName: t("SearchResults.Filters.PriceChange")},
      {field: "roiRange", queryParam: "roi", type: "numberRange", chipName: t("SearchResults.Filters.ROI")},
      {field: "fbmSellerCountRange", queryParam: "fbmSellerCount", type: "numberRange", chipName: t("SearchResults.Filters.FBM")},
      {field: "bsrRange", queryParam: "bsr", type: "numberRange", chipName: t("SearchResults.Filters.BSR")},
      {field: "weightRange", queryParam: "weight", type: "numberRange", chipName: t("SearchResults.Filters.Weight")},
      {field: "profitRange", queryParam: "profit", type: "numberRange", chipName: t("Profit")},
      {field: "remoteFbaSellerCountRange", queryParam: "remoteFbaSellerCount", type: "numberRange", chipName: t("Remote FBA Seller Count")},
      {field: "variationCountRange", queryParam: "variationCount", type: "numberRange", chipName: t("SearchResults.Filters.VariationCount")},
      {field: "sellPriceRange", queryParam: "sellPrice", type: "numberRange", chipName: t("SearchResults.Filters.SellPrice")},
      {field: "purchasePriceRange", queryParam: "purchasePrice", type: "numberRange", chipName: t("SearchResults.Filters.PurchasePrice")},
      {
        field: "amazonNotListedSince",
        queryParam: "amazonNotListedSince",
        type: "string",
        chipName: t("SearchResults.Filters.AmazonNotListedSince"),
        defaultValue: commonProductSelectOptions[0].value,
        disabled: isFavoritePage,
      },
      {field: "competition", queryParam: "competition", type: "array", chipName: t("Competition"), disabled: isFavoritePage},
      {field: "priceAnalysisResult", queryParam: "priceAnalysisResult", type: "array", chipName: t("SearchResults.Filters.PriceAnalysisResult"), disabled: isFavoritePage},
      {field: "productAge", queryParam: "productAge", type: "string", chipName: t("SearchResults.Filters.ProductAge"), defaultValue: dateRangeSelectOptions[0].value, disabled: isFavoritePage},
      {
        field: "potentialBrandOwner",
        queryParam: "potentialBrandOwner",
        chipName: t("SearchResults.Filters.PotentialBrandOwner"),
        type: "string",
        defaultValue: commonProductSelectOptions[0].value,
        disabled: isFavoritePage,
      },
      {
        field: "isAmazonExist",
        queryParam: "isAmazonExist",
        type: "string",
        chipName: t("SearchResults.Filters.IsAmazonExist"),
        defaultValue: commonProductSelectOptions[0].value,
        disabled: isFavoritePage,
      },
      {field: "sameProduct", queryParam: "sameProduct", type: "string", chipName: t("SearchResults.Filters.SameProduct"), defaultValue: commonProductSelectOptions[0].value, disabled: isFavoritePage},
      {
        field: "customsRestriction",
        queryParam: "customsRestriction",
        type: "string",
        chipName: t("SearchResults.Filters.CustomsRestriction"),
        defaultValue: restrictionCheckSelectOptions[0].value,
        disabled: isFavoritePage,
      },
      {
        field: "sizeIsEligible",
        queryParam: "sizeIsEligible",
        type: "string",
        chipName: t("SearchResults.Filters.SizeIsEligible"),
        defaultValue: commonProductSelectOptions[0].value,
        disabled: isFavoritePage,
      },
      {
        field: "isOldProduct",
        queryParam: "isOldProduct",
        type: "string",
        chipName: t("SearchResults.Filters.IsOldProduct"),
        defaultValue: commonProductSelectOptions[0].value,
        disabled: isFavoritePage,
      },
      {
        field: "fbaEligibility",
        queryParam: "fbaEligibility",
        type: "string",
        chipName: t("SearchResults.Filters.FbaEligibility"),
        defaultValue: commonProductSelectOptions[0].value,
        disabled: isFavoritePage,
      },
      {
        field: "restrictionCheck",
        queryParam: "restrictionCheck",
        type: "string",
        chipName: t("SearchResults.Filters.RestrictionCheck"),
        defaultValue: commonProductSelectOptions[0].value,
        disabled: isFavoritePage,
      },
      {field: "isReviewed", queryParam: "isReviewed", type: "string", chipName: t("SearchResults.Filters.IsReviewed"), defaultValue: commonProductSelectOptions[0].value, disabled: isFavoritePage},
      {field: "categories", queryParam: "categories", type: "array", chipName: t("SearchResults.Filters.Categories"), disabled: isFavoritePage},
      {field: "page", queryParam: "page", type: "number", defaultValue: localQuery.page, hideChip: true},
      {field: "pageSize", queryParam: "pageSize", type: "number", defaultValue: localQuery.pageSize, hideChip: true},
      {field: "sortBy", queryParam: "sortBy", type: "string", defaultValue: localQuery.sortBy, hideChip: true},
      {field: "sortingOrder", queryParam: "sortingOrder", type: "number", defaultValue: localQuery.sortingOrder, hideChip: true},
      {field: "action", queryParam: "action", type: "string", defaultValue: "filtering", hideChip: true},
    ],
    [searchId, favoriteId, isFavoritePage, t], // eslint-disable-line
  );

  const debouncedLoadList = useDebounce(() => {
    getUserSearchResultsFilters()(dispatch);
    getUserSearchResults(localQuery)(dispatch).then(() => {
      tableRef.current?.resetSelection();
    });
  }, 200);

  const {readQueryParams, updateQuery, searchParams} = useUrlQuery<SearchResultQuery>(filterFields);

  useEffect(() => {
    if (hasPermission("searchResult") && searchParams.has("action")) {
      let params = readQueryParams();
      params.searchId = searchId;
      params.favoriteId = favoriteId;

      updateLocalQuery(params as SearchResultQuery);
      debouncedLoadList();
    }
  }, [debouncedLoadList, searchParams]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  useEffect(() => {
    return () => {
      resetSearchResultState()(dispatch);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo<ColumnDef<UserSearchProduct, any>[]>(
    () => [
      {
        header: " ",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearchProduct;
          return (
            <>
              {row.existAtSource === undefined || row.existAtSource === false || row.existAtTarget === undefined || row.existAtTarget === false ? (
                <ProductNotExist item={row} />
              ) : row.status > UserSearchProductStatus.IN_PROGRESS ? (
                <SearchResultItem key={row.userSearchProductId} item={row} />
              ) : (
                <InProgress item={row} />
              )}
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const handleSortBy = (sorterQuery: SorterQuery) => {
    updateQuery({
      ...localQuery,
      sortBy: sorterQuery.sortBy,
      sortingOrder: sorterQuery.sortingOrder,
      action: "sorting",
    });
  };

  const sortOptions: SortSelectOption[] = useMemo(
    () => [
      {
        key: "profit",
        label: t("SearchResults.SortBy.ProfitDesc"),
        value: {sortBy: "profit", sortingOrder: SortingDirection.DESCENDING},
      },
      {
        key: "profit",
        label: t("SearchResults.SortBy.ProfitAsc"),
        value: {sortBy: "profit", sortingOrder: SortingDirection.ASCENDING},
      },
      {
        key: "profitMargin",
        label: t("SearchResults.SortBy.ProfitMarginDesc"),
        value: {sortBy: "profitMargin", sortingOrder: SortingDirection.DESCENDING},
      },
      {
        key: "profitMargin",
        label: t("SearchResults.SortBy.ProfitMarginAsc"),
        value: {sortBy: "profitMargin", sortingOrder: SortingDirection.ASCENDING},
      },
      {
        key: "roi",
        label: t("SearchResults.SortBy.ROIDesc"),
        value: {sortBy: "roi", sortingOrder: SortingDirection.DESCENDING},
      },
      {
        key: "roi",
        label: t("SearchResults.SortBy.ROIAsc"),
        value: {sortBy: "roi", sortingOrder: SortingDirection.ASCENDING},
      },
      {
        key: "bsr",
        label: t("SearchResults.SortBy.BSRDesc"),
        value: {sortBy: "bsr", sortingOrder: SortingDirection.DESCENDING},
      },
      {
        key: "bsr",
        label: t("SearchResults.SortBy.BSRAsc"),
        value: {sortBy: "bsr", sortingOrder: SortingDirection.ASCENDING},
      },
      {
        key: "saleCount",
        label: t("SearchResults.SortBy.SaleCountDesc"),
        value: {sortBy: "saleCount", sortingOrder: SortingDirection.DESCENDING},
      },
      {
        key: "saleCount",
        label: t("SearchResults.SortBy.SaleCountAsc"),
        value: {sortBy: "saleCount", sortingOrder: SortingDirection.ASCENDING},
      },
      {
        key: "variationCount",
        label: t("SearchResults.SortBy.VariationCountDesc"),
        value: {sortBy: "variationCount", sortingOrder: SortingDirection.DESCENDING},
      },
      {
        key: "variationCount",
        label: t("SearchResults.SortBy.VariationCountAsc"),
        value: {sortBy: "variationCount", sortingOrder: SortingDirection.ASCENDING},
      },
      {
        key: "purchasePrice",
        label: t("SearchResults.SortBy.PurchasePriceDesc"),
        value: {sortBy: "purchasePrice", sortingOrder: SortingDirection.DESCENDING},
      },
      {
        key: "purchasePrice",
        label: t("SearchResults.SortBy.PurchasePriceAsc"),
        value: {sortBy: "purchasePrice", sortingOrder: SortingDirection.ASCENDING},
      },
      {
        key: "sellPrice",
        label: t("SearchResults.SortBy.SellPriceDesc"),
        value: {sortBy: "sellPrice", sortingOrder: SortingDirection.DESCENDING},
      },
      {
        key: "sellPrice",
        label: t("SearchResults.SortBy.SellPriceAsc"),
        value: {sortBy: "sellPrice", sortingOrder: SortingDirection.ASCENDING},
      },
    ],
    [t],
  );

  const currentSortLabel = useMemo(() => {
    const sortBy = searchParams.get("sortBy");
    const sortOrder = searchParams.get("sortingOrder");

    if (!sortBy || !sortOrder) return "";

    const option = sortOptions.find((opt) => opt.value.sortBy === sortBy && opt.value.sortingOrder?.toString() === sortOrder);

    return option?.label || "";
  }, [searchParams, sortOptions]);

  const render_Sorting = (): JSX.Element => {
    return (
      <ButtonGroup className="d-grid">
        <UncontrolledDropdown direction="down">
          <DropdownToggle tag="button" className="btn btn-light w-100">
            {t("Sort by")} {currentSortLabel} <i className="mdi mdi-chevron-down"></i>
          </DropdownToggle>
          <DropdownMenu style={{zIndex: 5}}>
            {sortOptions.map((option, index) => (
              <DropdownItem key={`${option.key}-${option.value.sortingOrder}-${index}`} onClick={() => handleSortBy(option.value)}>
                {option.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </ButtonGroup>
    );
  };

  const breadcrumbTitle = searchObject ? `${t("SearchResults.Title")} - ${searchObject?.name ?? "…"}` : favoriteId ? `${t("Favorites.Title")} - ${favoriteObject?.name ?? "…"}` : "";

  const breadcrumbMenus: BreadcrumbMenuItem[] = searchId
    ? [
        {
          label: t("Searches.Title"),
          url: "/searches",
        },
        {
          label: `${t("SearchResults.Title")} - ${searchObject?.name ?? "…"}`,
          url: "",
        },
      ]
    : favoriteId
    ? [
        {
          label: t("Favorites.Title"),
          url: "/favorites",
        },
        {
          label: `${favoriteObject?.name ?? "…"}`,
          url: "",
        },
      ]
    : [];

  if (searchId) document.title = t("PageTitles.SearchResults");
  else if (favoriteId) document.title = t("PageTitles.FavoriteDetails");

  return (
    <Restricted require="searchResult" read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={breadcrumbTitle} menus={breadcrumbMenus} component={<BusinessModelBadge model={searchObject?.amazonBusinessModel} size="md" />} />
          <>
            <Filters busy={loading.filter || loading.list} fields={filterFields} />
          </>
          <Card className="shadow-none">
            <CardBody>
              {loading.list || loading.filter ? (
                <Loader />
              ) : products?.items && products?.items?.length > 0 ? (
                <>
                  <DataTable
                    theadClass="d-none border-0"
                    tableClass="search-result-item-table border-0"
                    tbodyClass="border-0"
                    trClass="search-result-item"
                    tdClass="border-0"
                    divClass="overflow-hidden"
                    ref={tableRef}
                    busy={false}
                    columns={columns}
                    data={products.items || []}
                    totalDataLength={products.totalCount}
                    renderSortingSelect={render_Sorting}
                    pagination={{
                      pageIndex: localQuery.page - 1,
                      pageSize: localQuery.pageSize,
                    }}
                    onPaginationChanged={(pagination) => updateQuery({...localQuery, page: pagination.pageIndex + 1, pageSize: pagination.pageSize, action: "paginating"})}
                  />
                </>
              ) : (
                <NoResult title={t("SearchResults.NoResult.Title")} description={t("SearchResults.NoResult.Description")} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <SellThis />
      <CheckList />
      <FBASellers />
      <DefinedFilters />
      <DecisionReasons />
      <ReportError />
      <IneligibilityReasonList />
      <AddToFavorites />
      <ReAnalyze />
      <RestrictionReasonCodes />
      <Notes />
    </Restricted>
  );
};

export default memo(SearchResults);

import Dialog, {DialogRef} from "Components/Common/Dialog";
import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import {useEffect, useRef, useState} from "react";
import {Button, Card, CardBody, Col, Container, Row, TabContent, TabPane} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Subscription} from "models/subscription";
import {createSelector} from "reselect";
import {UserSubscriptionStatus} from "models/enums/user_subscription_status";
import {useDispatch, useSelector} from "react-redux";
import {changeUserSubscription, createUserSubscription, getAllSubscriptionData, getUserSubscriptionData} from "slices/account/changeSubscription/thunk";
import {RootState} from "slices";
import {useProfile} from "Components/Hooks/useProfile";
import {ConstantPage} from "helpers/permission_helper";
import {refreshRemainingLimits} from "slices/thunks";
import {SubscriptionPeriodType} from "models/local_storage";
import {numberFormatter} from "helpers/utilities";
import Loader from "Components/Common/Loader";
import ProfileLayout from "Components/Common/Layout/ProfileLayout";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import _ from "lodash";

const MONTHLY_PLAN: SubscriptionPeriodType = "month";
const SIX_MONTH_PLAN: SubscriptionPeriodType = "month6";
const YEARLY_PLAN: SubscriptionPeriodType = "annually";

const PAGE_IDENTIFIER: ConstantPage = "account.subscription";
const ChangeSubscription = () => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [planActiveTab, setPlanActiveTab] = useState<SubscriptionPeriodType>(MONTHLY_PLAN);
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription>();
  const changeSubscriptionDialogRef = useRef<DialogRef>(null);
  const createSubscriptionDialogRef = useRef<DialogRef>(null);

  const subscriptionPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.ChangeSubscription.loading,
      list: state.ChangeSubscription.list,
      userSubscription: state.ChangeSubscription.userSubscription,
    }),
  );

  const {loading, list, userSubscription} = useSelector(subscriptionPageData);

  useEffect(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      dispatch(getAllSubscriptionData());
      dispatch(getUserSubscriptionData());
    }
  }, [dispatch, navigate]); //eslint-disable-line

  useEffect(() => {
    if (userSubscription) {
      if (userSubscription.status === UserSubscriptionStatus.CanceledAtPeriodEnd) {
        navigate("/account/subscription");
      }

      if (userSubscription.billingPeriodMonth === 1) {
        setPlanActiveTab(MONTHLY_PLAN);
      } else if (userSubscription.billingPeriodMonth === 6) {
        setPlanActiveTab(SIX_MONTH_PLAN);
      } else if (userSubscription.billingPeriodMonth === 12) {
        setPlanActiveTab(YEARLY_PLAN);
      }
    }
  }, [userSubscription, navigate]);

  const isCurrentPlan = (subscription: Subscription) => {
    return (
      subscription.subscriptionId === userSubscription.subscriptionId &&
      userSubscription.status !== UserSubscriptionStatus.Canceled &&
      ((userSubscription.billingPeriodMonth === 1 && planActiveTab === MONTHLY_PLAN) ||
        (userSubscription.billingPeriodMonth === 6 && planActiveTab === SIX_MONTH_PLAN) ||
        (userSubscription.billingPeriodMonth === 12 && planActiveTab === YEARLY_PLAN))
    );
  };

  document.title = t("PageTitles.Subscription");

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Account.Title"),
      url: "/account/subscription",
    },
    {
      label: t("Account.Subscription.Title"),
      url: "/account/subscription",
    },
    {
      label: t("Change"),
      url: "",
    },
  ];
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Account.Title")} menus={breadcrumbMenus} />
          <ProfileLayout hasAnyChanges={false} navigateTo="">
            <>
              <Row className="justify-content-center mt-4">
                <Col xs={12} lg={6}>
                  <div className="text-center mb-4">
                    <h4 className="fw-semibold fs-22">{t("Account.Subscription.ChangeSubscription.Title")}</h4>
                    <p className="text-muted mb-4 fs-15">{t("Account.Subscription.ChangeSubscription.Description")}</p>

                    <div className="billing-cycle-selector rounded-pill p-1 mb-2 d-inline-flex shadow-sm">
                      <button className={`btn rounded-pill px-4 py-2 fw-medium ${planActiveTab === MONTHLY_PLAN ? "btn-secondary" : "btn-light"}`} onClick={() => setPlanActiveTab(MONTHLY_PLAN)}>
                        {t("Account.Subscription.Monthly")}
                      </button>
                      <button
                        className={`btn rounded-pill px-4 py-2 mx-2 fw-medium ${planActiveTab === SIX_MONTH_PLAN ? "btn-secondary" : "btn-light"}`}
                        onClick={() => setPlanActiveTab(SIX_MONTH_PLAN)}
                      >
                        {t("Account.Subscription.6-Month")}
                        <span className="badge bg-success ms-2">{t("Account.Subscription.1-MonthFree")}</span>
                      </button>
                      <button className={`btn rounded-pill px-4 py-2 fw-medium ${planActiveTab === YEARLY_PLAN ? "btn-secondary" : "btn-light"}`} onClick={() => setPlanActiveTab(YEARLY_PLAN)}>
                        {t("Account.Subscription.12-Month")}
                        <span className="badge bg-success ms-2">{t("Account.Subscription.2-MonthFree")}</span>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                {Array.from(list).length === 0 && <Loader />}
                {(list || []).map((subscription: Subscription, key: any) => (
                  <Col xxl={4} lg={6} key={key} className="same-height">
                    <Card className={`pricing-box ribbon-box right ${isCurrentPlan(subscription) ? "bg-warning-subtle" : ""}`}>
                      {subscription.name.startsWith("Professional") && (
                        <div className="ribbon-two ribbon-two-danger">
                          <span>{t("Account.Subscription.Popular")}</span>
                        </div>
                      )}
                      <CardBody className="m-2 p-4 d-flex flex-column">
                        <div className="d-flex align-items-center mb-3">
                          <div className="">
                            <TabContent activeTab={planActiveTab}>
                              <TabPane tabId={MONTHLY_PLAN}>
                                <Row>
                                  <Col xs={12} className="mb-3">
                                    <h5 className="mb-0 fw-semibold">{subscription.name}</h5>
                                  </Col>
                                  <Col>
                                    <h2 className="month mb-0">
                                      ${subscription.priceMonth} <small className="fs-13 text-muted">/{t("Month")}</small>
                                    </h2>
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane tabId={SIX_MONTH_PLAN}>
                                <Row>
                                  <Col xs={12} className="mb-3">
                                    <h5 className="mb-0 fw-semibold">{subscription.name}</h5>
                                  </Col>
                                  <Col>
                                    <small className="fs-16">
                                      <del>${subscription.priceSixMonthDel}</del>
                                    </small>
                                    <h2 className=" mb-0">
                                      ${subscription.priceSixMonth} <small className="fs-13 text-muted">/{t("Account.Subscription.6-Month")}</small>
                                    </h2>
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane tabId={YEARLY_PLAN}>
                                <Row>
                                  <Col xs={12} className="mb-3">
                                    <h5 className="mb-0 fw-semibold">{subscription.name}</h5>
                                  </Col>
                                  <Col>
                                    <small className="fs-16">
                                      <del>${subscription.priceYearDel}</del>
                                    </small>
                                    <h2 className="mb-0">
                                      ${subscription.priceYear} <small className="fs-13 text-muted">/{t("Account.Subscription.Year")}</small>
                                    </h2>
                                  </Col>
                                </Row>
                              </TabPane>
                            </TabContent>
                          </div>
                        </div>

                        <p className="text-muted">{t(subscription.description)}</p>
                        <ul className="list-unstyled vstack gap-3">
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">{t("Account.Subscription.Features.DailyProductAnalysis", {limitCount: numberFormatter(subscription.dailySearch)})}</div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">{t("Account.Subscription.Features.DailyScanAndSave", {limitCount: numberFormatter(subscription.dailyCrawlerScan)})}</div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">{t("Account.Subscription.Features.CrossBorderArbitrage")}</div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">{t("Account.Subscription.Features.ShipmentManagement")}</div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">{t("Account.Subscription.Features.ProfitCalculator")}</div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">{t("Account.Subscription.Features.DetailedProductAnalysis")}</div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">{t("Account.Subscription.Features.StoreAndCategoryScanning")}</div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">{t("Account.Subscription.Features.SalesForecasting")}</div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">{t("Account.Subscription.Features.AccountingAndSalesManagement")}</div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">{t("Account.Subscription.Features.CompetitorAnalysis")}</div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">{t("Account.Subscription.Features.ComprehensiveDashboard")}</div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">{t("Account.Subscription.Features.ProductRestrictionChecks")}</div>
                            </div>
                          </li>
                          {subscription.name.toString().startsWith("Premium") && (
                            <li>
                              <div className="d-flex">
                                <div className="flex-shrink-0 text-success me-1">
                                  <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                </div>
                                <div className="flex-grow-1">{t("Account.Subscription.Features.AdditionalUsers")}</div>
                              </div>
                            </li>
                          )}
                        </ul>
                        <div className="mt-3 pt-2 mt-auto">
                          {isCurrentPlan(subscription) ? (
                            <Link to="#" className={`btn btn-danger disabled w-100`}>
                              {t("Account.Subscription.ChangeSubscription.CurrentPlan")}
                            </Link>
                          ) : (
                            <Button
                              type="button"
                              className={`btn btn-info w-100`}
                              onClick={() => {
                                setSelectedSubscription(subscription);

                                if (_.isEmpty(userSubscription)) createSubscriptionDialogRef.current?.show();
                                else changeSubscriptionDialogRef.current?.show();
                              }}
                            >
                              {t("Select")}
                            </Button>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </>
          </ProfileLayout>
        </Container>
        <Dialog
          ref={changeSubscriptionDialogRef}
          color="success"
          buttons={["yes", "no"]}
          busy={loading.change}
          iconClass="ri-checkbox-circle-fill"
          message={t("Account.Subscription.Dialog.ChangeSubscription.Description")}
          title={t("Account.Subscription.Dialog.ChangeSubscription.Title")}
          onButtonClick={async (button, hide) => {
            if (button === "yes") {
              const priceId =
                planActiveTab === MONTHLY_PLAN
                  ? selectedSubscription?.monthPriceId
                  : planActiveTab === SIX_MONTH_PLAN
                  ? selectedSubscription?.sixMonthPriceId
                  : planActiveTab === YEARLY_PLAN && selectedSubscription?.yearPriceId;
              if (selectedSubscription) {
                await dispatch(
                  changeUserSubscription({
                    newSubscriptionPriceId: priceId as string,
                    newSubscriptionId: selectedSubscription.subscriptionId,
                    userId: userProfile?.userId as string,
                  }),
                );
                changeSubscriptionDialogRef.current?.hide();
                await dispatch(refreshRemainingLimits());
              }
            }
            changeSubscriptionDialogRef.current?.hide();
          }}
        />
        <Dialog
          ref={createSubscriptionDialogRef}
          color="info"
          buttons={["yes", "no"]}
          busy={loading.create}
          iconClass="ri-checkbox-circle-fill"
          message={t("Account.Subscription.Dialog.CreateSubscription.Description")}
          title={t("Account.Subscription.Dialog.CreateSubscription.Title")}
          onButtonClick={async (button, hide) => {
            if (button === "yes") {
              const priceId =
                planActiveTab === MONTHLY_PLAN
                  ? selectedSubscription?.monthPriceId
                  : planActiveTab === SIX_MONTH_PLAN
                  ? selectedSubscription?.sixMonthPriceId
                  : planActiveTab === YEARLY_PLAN && selectedSubscription?.yearPriceId;
              if (selectedSubscription) {
                await dispatch(
                  createUserSubscription({
                    newSubscriptionPriceId: priceId as string,
                    newSubscriptionId: selectedSubscription.subscriptionId,
                    userId: userProfile?.userId as string,
                  }),
                );
                createSubscriptionDialogRef.current?.hide();
                await dispatch(refreshRemainingLimits());
              }
            }
            createSubscriptionDialogRef.current?.hide();
          }}
        />
      </div>
    </Restricted>
  );
};

export default ChangeSubscription;

import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader, Col} from "reactstrap";
import {Link} from "react-router-dom";
import {preventScrollUp} from "helpers/utilities";
import {SystemMonitoringSlice} from "slices/admin/systemMonitoring/selector";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import DisplayNumber from "Components/Common/DisplayNumber";

const ContinuousServices = () => {
  const {t} = useTranslation();
  const {systemMonitoring, loading} = useSelector(SystemMonitoringSlice);

  const [spapiSearchStyle, setSpapiSearchStyle] = useState("bg-success");
  const [productOffersStyle, setProductOffersStyle] = useState("bg-success");
  const [feesEstimateStyle, setFeesEstimateStyle] = useState("bg-success");
  const [fbaEligibilityStyle, setFbaEligibilityStyle] = useState("bg-success");
  const [variationsStyle, setVariationsStyle] = useState("bg-success");
  const [categoryStyle, setCategoryStyle] = useState("bg-success");
  const [connectApiStyle, setConnectApiStyle] = useState("bg-success");
  const [saleCountStyle, setSaleCountStyle] = useState("bg-success");

  type Range = {
    min: number;
    max: number;
  };

  type RangeLimits = {
    warning: [number, number];
    danger: [number, number];
  };

  const toRange = (rangeTuple: [number, number]): Range => ({
    min: rangeTuple[0],
    max: rangeTuple[1],
  });
  const getTextStyle = (value: number, successRange: [number, number], warningRange: [number, number], dangerRange: [number, number]): string => {
    const success = toRange(successRange);
    const warning = toRange(warningRange);
    const danger = toRange(dangerRange);

    if (value >= success.min && value <= success.max) {
      return "text-success";
    } else if (value >= warning.min && value <= warning.max) {
      return "text-warning";
    } else if (value >= danger.min && value <= danger.max) {
      return "text-danger";
    }
    return "";
  };

  const getCardStyle = (data: any, ranges: Record<string, RangeLimits>) => {
    if (!data) return "bg-success";

    const checks = Object.entries(ranges).map(([key, range]) => {
      if (data[key] !== undefined) {
        return checkDangerWarning(data[key], range.warning, range.danger);
      }
      return null;
    });

    const dangerCheck = checks.find((check) => check === "bg-danger");
    if (dangerCheck) return dangerCheck;

    const warningCheck = checks.find((check) => check === "bg-warning");
    if (warningCheck) return warningCheck;

    return "bg-success";
  };

  const checkDangerWarning = (value: number, warningRange: [number, number], dangerRange: [number, number]) => {
    const warning = toRange(warningRange);
    const danger = toRange(dangerRange);

    if (value > danger.min && value <= danger.max) {
      return "bg-danger";
    } else if (value > warning.min && value <= warning.max) {
      return "bg-warning";
    }
    return null;
  };

  useEffect(() => {
    if (systemMonitoring.continuousServices) {
      setSpapiSearchStyle(
        getCardStyle(systemMonitoring.continuousServices.spapiSearch, {
          completedCount: {warning: [100000, 200000], danger: [0, 100000]},
          overdue: {warning: [50000, 100000], danger: [100000, Infinity]},
          inProgress: {warning: [150000, 200000], danger: [200000, Infinity]},
          inQueue: {warning: [100000, 200000], danger: [200000, Infinity]},
          averageProcessTimeInMinute: {warning: [10, 20], danger: [20, Infinity]},
        }),
      );

      setVariationsStyle(
        getCardStyle(systemMonitoring.continuousServices.spapiVariations, {
          completedCount: {warning: [50000, 100000], danger: [0, 50000]},
          overdue: {warning: [50000, 100000], danger: [100000, Infinity]},
        }),
      );

      setCategoryStyle(
        getCardStyle(systemMonitoring.continuousServices.spapiCategory, {
          completedCount: {warning: [0, 1000], danger: [0, 0]},
          overdue: {warning: [50000, 100000], danger: [100000, Infinity]},
          inProgress: {warning: [75000, 100000], danger: [100000, Infinity]},
          inQueue: {warning: [50000, 100000], danger: [100000, Infinity]},
          averageProcessTimeInMinute: {warning: [10, 60], danger: [60, Infinity]},
        }),
      );

      setFbaEligibilityStyle(
        getCardStyle(systemMonitoring.continuousServices.spapiFBAEligibility, {
          completedCount: {warning: [0, 1000], danger: [0, 0]},
          overdue: {warning: [50000, 100000], danger: [100000, Infinity]},
          inProgress: {warning: [75000, 100000], danger: [100000, Infinity]},
          inQueue: {warning: [50000, 100000], danger: [100000, Infinity]},
          averageProcessTimeInMinute: {warning: [10, 20], danger: [20, Infinity]},
        }),
      );

      setProductOffersStyle(
        getCardStyle(systemMonitoring.continuousServices.spapiProductOffers, {
          completedCount: {warning: [100000, 200000], danger: [0, 100000]},
          overdue: {warning: [50000, 100000], danger: [100000, Infinity]},
          inProgress: {warning: [150000, 200000], danger: [200000, Infinity]},
          inQueue: {warning: [100000, 200000], danger: [200000, Infinity]},
          averageProcessTimeInMinute: {warning: [10, 20], danger: [20, Infinity]},
        }),
      );

      setFeesEstimateStyle(
        getCardStyle(systemMonitoring.continuousServices.spapiFeesEstimate, {
          completedCount: {warning: [0, 1000], danger: [0, 0]},
          overdue: {warning: [50000, 100000], danger: [100000, Infinity]},
          inProgress: {warning: [150000, 200000], danger: [200000, Infinity]},
          inQueue: {warning: [100000, 200000], danger: [200000, Infinity]},
          averageProcessTimeInMinute: {warning: [10, 20], danger: [20, Infinity]},
        }),
      );

      setConnectApiStyle(
        getCardStyle(systemMonitoring.continuousServices.connectApi, {
          completedCount: {warning: [0, 0], danger: [0, 0]},
          overdue: {warning: [5000, 10000], danger: [10000, Infinity]},
          inProgress: {warning: [25000, 30000], danger: [30000, Infinity]},
          inQueue: {warning: [20000, 30000], danger: [30000, Infinity]},
          averageProcessTimeInMinute: {warning: [10, 20], danger: [20, Infinity]},
          connectApiLeftTokenCount: {warning: [500, 2000], danger: [-Infinity, 500]},
        }),
      );

      setSaleCountStyle(
        getCardStyle(systemMonitoring.continuousServices.saleCount, {
          completedCount: {warning: [100000, 200000], danger: [0, 100000]},
          overdue: {warning: [50000, 100000], danger: [100000, Infinity]},
          inProgress: {warning: [150000, 200000], danger: [200000, Infinity]},
          inQueue: {warning: [100000, 200000], danger: [200000, Infinity]},
          averageProcessTimeInMinute: {warning: [10, 20], danger: [20, Infinity]},
        }),
      );
    }
  }, [systemMonitoring.continuousServices]); // eslint-disable-line

  return (
    <>
      <Col xxl={3} xl={4} lg={6} sm={12} className="same-height">
        <PlaceholderGlow busy={loading.list}>
          <Card className="card-animate mb-0">
            <CardHeader>
              <h5 className="fs-17 fw-semibold d-flex align-items-center justify-content-between mb-0">
                <span>{t("Admin.SystemMonitoring.Services.Title.SPAPISearch")}</span>
                <i className="fs-10 text-muted fw-normal">8/12/24/48/72h</i>
              </h5>
            </CardHeader>
            <CardBody className="d-flex flex-column">
              <ul className="list-group mb-2">
                <li className="mb-1 d-flex justify-content-between">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("Admin.SystemMonitoring.Services.Label.Completed", {value: 1}),
                    }}
                  ></span>
                  <span className="fw-medium">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiSearch?.completedCount, [200000, Infinity], [100000, 200000], [0, 100000])}
                      value={systemMonitoring.continuousServices?.spapiSearch?.completedCount}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.OverDue")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiSearch?.overdue, [0, 50000], [50000, 100000], [100000, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiSearch?.overdue}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.InProgress")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiSearch?.inProgress, [0, 150000], [150000, 200000], [200000, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiSearch?.inProgress}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.InQueue")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiSearch?.inQueue, [0, 100000], [100000, 200000], [200000, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiSearch?.inQueue}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.AvgProcessingTime")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiSearch?.averageProcessTimeInMinute, [0, 10], [10, 20], [20, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiSearch?.averageProcessTimeInMinute}
                      decimals={0}
                      notation="decimal"
                      suffix={` ${t("min")}`}
                    />
                  </span>
                </li>
              </ul>
              <Link
                to={`/admin/system-monitoring/service-details/${systemMonitoring.continuousServices?.spapiSearch?.serviceId}`}
                className="mt-auto link-secondary text-decoration-underline"
                onClick={() => preventScrollUp()}
              >
                {t("Admin.SystemMonitoring.Services.Link.ViewStatistics")}
              </Link>
            </CardBody>
            <div className="progress animated-progess rounded-bottom rounded-0" style={{height: "6px"}}>
              <div className={`progress-bar rounded-0 ${spapiSearchStyle}`} role="progressbar" style={{width: "100%"}}></div>
            </div>
          </Card>
        </PlaceholderGlow>
      </Col>
      <Col xxl={3} xl={4} lg={6} sm={12} className="same-height">
        <PlaceholderGlow busy={loading.list}>
          <Card className="card-animate mb-0">
            <CardHeader>
              <h5 className="fs-17 fw-semibold d-flex align-items-center justify-content-between mb-0">
                <span>{t("Admin.SystemMonitoring.Services.Title.SPAPIVariations")}</span>
                <i className="fs-10 text-muted fw-normal">Parent's SPAPI Search</i>
              </h5>
            </CardHeader>
            <CardBody className="d-flex flex-column">
              <ul className="list-group mb-2">
                <li className="mb-1 d-flex justify-content-between">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("Admin.SystemMonitoring.Services.Label.Completed", {value: 1}),
                    }}
                  ></span>
                  <span className="fw-medium">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiVariations?.completedCount, [100000, Infinity], [50000, 100000], [0, 50000])}
                      value={systemMonitoring.continuousServices?.spapiVariations?.completedCount}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.OverDue")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiVariations?.overdue, [0, 50000], [50000, 100000], [100000, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiVariations?.overdue}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
              </ul>
            </CardBody>
            <div className="progress animated-progess rounded-bottom rounded-0" style={{height: "6px"}}>
              <div className={`progress-bar rounded-0 ${variationsStyle}`} role="progressbar" style={{width: "100%"}}></div>
            </div>
          </Card>
        </PlaceholderGlow>
      </Col>
      <Col xxl={3} xl={4} lg={6} sm={12} className="same-height">
        <PlaceholderGlow busy={loading.list}>
          <Card className="card-animate mb-0">
            <CardHeader>
              <h5 className="fs-17 fw-semibold d-flex align-items-center justify-content-between mb-0">
                <span>{t("Admin.SystemMonitoring.Services.Title.SPAPICategory")}</span>
                <i className="fs-10 text-muted fw-normal">30d</i>
              </h5>
            </CardHeader>
            <CardBody className="d-flex flex-column">
              <ul className="list-group mb-2">
                <li className="mb-1 d-flex justify-content-between">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("Admin.SystemMonitoring.Services.Label.Completed", {value: 1}),
                    }}
                  ></span>
                  <span className="fw-medium">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiCategory?.completedCount, [1000, Infinity], [0, 1000], [0, 0])}
                      value={systemMonitoring.continuousServices?.spapiCategory?.completedCount}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.OverDue")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiCategory?.overdue, [0, 50000], [50000, 100000], [100000, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiCategory?.overdue}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.InProgress")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiCategory?.inProgress, [0, 75000], [75000, 100000], [100000, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiCategory?.inProgress}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.InQueue")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiCategory?.inQueue, [0, 50000], [50000, 100000], [100000, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiCategory?.inQueue}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.AvgProcessingTime")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiCategory?.averageProcessTimeInMinute, [0, 10], [10, 60], [60, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiCategory?.averageProcessTimeInMinute}
                      decimals={0}
                      notation="decimal"
                      suffix={` ${t("min")}`}
                    />
                  </span>
                </li>
              </ul>
              <Link
                to={`/admin/system-monitoring/service-details/${systemMonitoring.continuousServices?.spapiCategory?.serviceId}`}
                className="mt-auto link-secondary text-decoration-underline"
                onClick={() => preventScrollUp()}
              >
                {t("Admin.SystemMonitoring.Services.Link.ViewStatistics")}
              </Link>
            </CardBody>
            <div className="progress animated-progess rounded-bottom rounded-0" style={{height: "6px"}}>
              <div className={`progress-bar rounded-0 ${categoryStyle}`} role="progressbar" style={{width: "100%"}}></div>
            </div>
          </Card>
        </PlaceholderGlow>
      </Col>
      <Col xxl={3} xl={4} lg={6} sm={12} className="same-height">
        <PlaceholderGlow busy={loading.list}>
          <Card className="card-animate mb-0">
            <CardHeader>
              <h5 className="fs-17 fw-semibold d-flex align-items-center justify-content-between mb-0">
                <span>{t("Admin.SystemMonitoring.Services.Title.SPAPIFBAEligibility")}</span>
                <i className="fs-10 text-muted fw-normal">30d</i>
              </h5>
            </CardHeader>
            <CardBody className="d-flex flex-column">
              <ul className="list-group mb-2">
                <li className="mb-1 d-flex justify-content-between">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("Admin.SystemMonitoring.Services.Label.Completed", {value: 1}),
                    }}
                  ></span>
                  <span className="fw-medium">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiFBAEligibility?.completedCount, [1000, Infinity], [0, 1000], [0, 0])}
                      value={systemMonitoring.continuousServices?.spapiFBAEligibility?.completedCount}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.OverDue")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiFBAEligibility?.overdue, [0, 50000], [50000, 100000], [100000, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiFBAEligibility?.overdue}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.InProgress")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiFBAEligibility?.inProgress, [0, 75000], [75000, 100000], [100000, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiFBAEligibility?.inProgress}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.InQueue")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiFBAEligibility?.inQueue, [0, 50000], [50000, 100000], [100000, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiFBAEligibility?.inQueue}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.AvgProcessingTime")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiFBAEligibility?.averageProcessTimeInMinute, [0, 10], [10, 20], [20, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiFBAEligibility?.averageProcessTimeInMinute}
                      decimals={0}
                      notation="decimal"
                      suffix={` ${t("min")}`}
                    />
                  </span>
                </li>
              </ul>
              <Link
                to={`/admin/system-monitoring/service-details/${systemMonitoring.continuousServices?.spapiFBAEligibility?.serviceId}`}
                className="mt-auto link-secondary text-decoration-underline"
                onClick={() => preventScrollUp()}
              >
                {t("Admin.SystemMonitoring.Services.Link.ViewStatistics")}
              </Link>
            </CardBody>
            <div className="progress animated-progess rounded-bottom rounded-0" style={{height: "6px"}}>
              <div className={`progress-bar rounded-0 ${fbaEligibilityStyle}`} role="progressbar" style={{width: "100%"}}></div>
            </div>
          </Card>
        </PlaceholderGlow>
      </Col>
      <Col xxl={3} xl={6} lg={6} sm={12} className="same-height">
        <PlaceholderGlow busy={loading.list}>
          <Card className="card-animate mb-0">
            <CardHeader>
              <h5 className="fs-17 fw-semibold d-flex align-items-center justify-content-between mb-0">
                <span>{t("Admin.SystemMonitoring.Services.Title.SPAPIProductOffers")}</span>
                <i className="fs-10 text-muted fw-normal">8/12/24/48/72h</i>
              </h5>
            </CardHeader>
            <CardBody className="d-flex flex-column">
              <ul className="list-group mb-2">
                <li className="mb-1 d-flex justify-content-between">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("Admin.SystemMonitoring.Services.Label.Completed", {value: 1}),
                    }}
                  ></span>
                  <span className="fw-medium">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiProductOffers?.completedCount, [200000, Infinity], [100000, 200000], [0, 100000])}
                      value={systemMonitoring.continuousServices?.spapiProductOffers?.completedCount}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.OverDue")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiProductOffers?.overdue, [0, 50000], [50000, 100000], [100000, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiProductOffers?.overdue}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.InProgress")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiProductOffers?.inProgress, [0, 150000], [150000, 200000], [200000, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiProductOffers?.inProgress}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.InQueue")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiProductOffers?.inQueue, [0, 100000], [100000, 200000], [200000, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiProductOffers?.inQueue}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.AvgProcessingTime")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiProductOffers?.averageProcessTimeInMinute, [0, 10], [10, 20], [20, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiProductOffers?.averageProcessTimeInMinute}
                      decimals={0}
                      notation="decimal"
                      suffix={` ${t("min")}`}
                    />
                  </span>
                </li>
              </ul>
              <Link
                to={`/admin/system-monitoring/service-details/${systemMonitoring.continuousServices?.spapiProductOffers?.serviceId}`}
                className="mt-auto link-secondary text-decoration-underline"
                onClick={() => preventScrollUp()}
              >
                {t("Admin.SystemMonitoring.Services.Link.ViewStatistics")}
              </Link>
            </CardBody>
            <div className="progress animated-progess rounded-bottom rounded-0" style={{height: "6px"}}>
              <div className={`progress-bar rounded-0 ${productOffersStyle}`} role="progressbar" style={{width: "100%"}}></div>
            </div>
          </Card>
        </PlaceholderGlow>
      </Col>
      <Col xxl={3} xl={4} lg={6} sm={12} className="same-height">
        <PlaceholderGlow busy={loading.list}>
          <Card className="card-animate mb-0">
            <CardHeader>
              <h5 className="fs-17 fw-semibold d-flex align-items-center justify-content-between mb-0">
                {t("Admin.SystemMonitoring.Services.Title.SPAPIFeesEstimate")}
                <i className="fs-10 text-muted fw-normal">24h</i>
              </h5>
            </CardHeader>
            <CardBody className="d-flex flex-column">
              <ul className="list-group mb-2">
                <li className="mb-1 d-flex justify-content-between">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("Admin.SystemMonitoring.Services.Label.Completed", {value: 1}),
                    }}
                  ></span>
                  <span className="fw-medium">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiFeesEstimate?.completedCount, [1000, Infinity], [0, 1000], [0, 0])}
                      value={systemMonitoring.continuousServices?.spapiFeesEstimate?.completedCount}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.OverDue")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiFeesEstimate?.overdue, [0, 50000], [50000, 100000], [100000, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiFeesEstimate?.overdue}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.InProgress")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiFeesEstimate?.inProgress, [0, 150000], [150000, 200000], [200000, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiFeesEstimate?.inProgress}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.InQueue")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiFeesEstimate?.inQueue, [0, 100000], [100000, 200000], [200000, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiFeesEstimate?.inQueue}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.AvgProcessingTime")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.spapiFeesEstimate?.averageProcessTimeInMinute, [0, 10], [10, 20], [20, Infinity])}
                      value={systemMonitoring.continuousServices?.spapiFeesEstimate?.averageProcessTimeInMinute}
                      decimals={0}
                      notation="decimal"
                      suffix={` ${t("min")}`}
                    />
                  </span>
                </li>
              </ul>
              <Link
                to={`/admin/system-monitoring/service-details/${systemMonitoring.continuousServices?.spapiFeesEstimate?.serviceId}`}
                className="mt-auto link-secondary text-decoration-underline"
                onClick={() => preventScrollUp()}
              >
                {t("Admin.SystemMonitoring.Services.Link.ViewStatistics")}
              </Link>
            </CardBody>
            <div className="progress animated-progess rounded-bottom rounded-0" style={{height: "6px"}}>
              <div className={`progress-bar rounded-0 ${feesEstimateStyle}`} role="progressbar" style={{width: "100%"}}></div>
            </div>
          </Card>
        </PlaceholderGlow>
      </Col>
      <Col xxl={3} xl={4} lg={6} sm={12} className="same-height">
        <PlaceholderGlow busy={loading.list}>
          <Card className="card-animate mb-0">
            <CardHeader>
              <h5 className="fs-17 fw-semibold d-flex align-items-center justify-content-between mb-0">
                <span>{t("Admin.SystemMonitoring.Services.Title.ConnectAPI")}</span>
                <i className="fs-10 text-muted fw-normal">x1</i>
              </h5>
            </CardHeader>
            <CardBody className="d-flex flex-column">
              <ul className="list-group mb-2">
                <li className="mb-1 d-flex justify-content-between">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("Admin.SystemMonitoring.Services.Label.Completed", {value: 1}),
                    }}
                  ></span>
                  <span className="fw-medium">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.connectApi?.completedCount, [0, Infinity], [0, 0], [0, 0])}
                      value={systemMonitoring.continuousServices?.connectApi?.completedCount}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.OverDue")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.connectApi?.overdue, [0, 5000], [5000, 10000], [10000, Infinity])}
                      value={systemMonitoring.continuousServices?.connectApi?.overdue}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.InProgress")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.connectApi?.inProgress, [0, 25000], [25000, 30000], [30000, Infinity])}
                      value={systemMonitoring.continuousServices?.connectApi?.inProgress}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.InQueue")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.connectApi?.inQueue, [0, 20000], [20000, 30000], [30000, Infinity])}
                      value={systemMonitoring.continuousServices?.connectApi?.inQueue}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.AvgProcessingTime")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.connectApi?.averageProcessTimeInMinute, [-Infinity, 10], [10, 20], [20, Infinity])}
                      value={systemMonitoring.continuousServices?.connectApi?.averageProcessTimeInMinute}
                      decimals={0}
                      notation="decimal"
                      suffix={` ${t("min")}`}
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.CurrentTokenCount")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.connectApi?.connectApiLeftTokenCount || 0, [2000, Infinity], [500, 200], [-Infinity, 500])}
                      value={systemMonitoring.continuousServices?.connectApi?.connectApiLeftTokenCount || 0}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
              </ul>
              <Link
                to={`/admin/system-monitoring/service-details/${systemMonitoring.continuousServices?.connectApi?.serviceId}`}
                className="mt-auto link-secondary text-decoration-underline"
                onClick={() => preventScrollUp()}
              >
                {t("Admin.SystemMonitoring.Services.Link.ViewStatistics")}
              </Link>
            </CardBody>
            <div className="progress animated-progess rounded-bottom rounded-0" style={{height: "6px"}}>
              <div className={`progress-bar rounded-0 ${connectApiStyle}`} role="progressbar" style={{width: "100%"}}></div>
            </div>
          </Card>
        </PlaceholderGlow>
      </Col>
      <Col xxl={3} xl={4} lg={6} sm={12} className="same-height">
        <PlaceholderGlow busy={loading.list}>
          <Card className="card-animate mb-0">
            <CardHeader>
              <h5 className="fs-17 fw-semibold d-flex align-items-center justify-content-between mb-0">
                {t("Admin.SystemMonitoring.Services.Title.SaleCount")}
                <i className="fs-10 text-muted fw-normal">12h</i>
              </h5>
            </CardHeader>
            <CardBody className="d-flex flex-column">
              <ul className="list-group mb-2">
                <li className="mb-1 d-flex justify-content-between">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("Admin.SystemMonitoring.Services.Label.Completed", {value: 1}),
                    }}
                  ></span>
                  <span className="fw-medium">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.saleCount?.completedCount, [200000, Infinity], [100000, 200000], [0, 100000])}
                      value={systemMonitoring.continuousServices?.saleCount?.completedCount}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.OverDue")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.saleCount?.overdue, [0, 50000], [50000, 100000], [100000, Infinity])}
                      value={systemMonitoring.continuousServices?.saleCount?.overdue}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.InProgress")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.saleCount?.inProgress, [0, 150000], [150000, 200000], [200000, Infinity])}
                      value={systemMonitoring.continuousServices?.saleCount?.inProgress}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.InQueue")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.saleCount?.inQueue, [0, 100000], [100000, 200000], [200000, Infinity])}
                      value={systemMonitoring.continuousServices?.saleCount?.inQueue}
                      decimals={0}
                      notation="decimal"
                    />
                  </span>
                </li>
                <li className="mb-1 d-flex justify-content-between">
                  <span>{t("Admin.SystemMonitoring.Services.Label.AvgProcessingTime")}</span>
                  <span className="fw-medium text-success">
                    <DisplayNumber
                      className={getTextStyle(systemMonitoring.continuousServices?.saleCount?.averageProcessTimeInMinute, [0, 10], [10, 20], [20, Infinity])}
                      value={systemMonitoring.continuousServices?.saleCount?.averageProcessTimeInMinute}
                      decimals={0}
                      notation="decimal"
                      suffix={` ${t("min")}`}
                    />
                  </span>
                </li>
              </ul>
              <Link
                to={`/admin/system-monitoring/service-details/${systemMonitoring.continuousServices?.saleCount?.serviceId}`}
                className="mt-auto link-secondary text-decoration-underline"
                onClick={() => preventScrollUp()}
              >
                {t("Admin.SystemMonitoring.Services.Link.ViewStatistics")}
              </Link>
            </CardBody>
            <div className="progress animated-progess rounded-bottom rounded-0" style={{height: "6px"}}>
              <div className={`progress-bar rounded-0 ${saleCountStyle}`} role="progressbar" style={{width: "100%"}}></div>
            </div>
          </Card>
        </PlaceholderGlow>
      </Col>
    </>
  );
};

export default ContinuousServices;

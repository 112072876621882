import React, {useEffect, useRef, useState} from "react";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {Button, Card, CardBody, Col, Row, Spinner} from "reactstrap";
import {useTranslation} from "react-i18next";
import {ISetUserShipmentDetailsDto, UserShipmentStatus} from "models/user_shipment";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setUserShipmentDetails} from "slices/thunks";
import {AnalyzeType, ReAnalyzeModal} from "Components/Common/Modals/ReAnalyze";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import { AmazonMarketplace } from "helpers/marketplace_helper";
import { ShipmentSlice } from "slices/shipment/selector";
import EditShipment from "../../Shipments/Modal/EditShipment";
import Moment from "react-moment";
import i18n from "../../../../i18n";
import img from "assets/images/amazon-package.png";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import "moment/locale/tr";

export type ListRef = {
  reload: VoidFunction;
};

interface ShipmentInfoProps {
  sourceMarketplace?: AmazonMarketplace;
  destinationMarketplace?: AmazonMarketplace
}
const ShipmentInfo = ({sourceMarketplace, destinationMarketplace}: ShipmentInfoProps) => {
  const {t} = useTranslation();
  const [status, setStatus] = useState<UserShipmentStatus>();
  const [isEditing, setIsEditing] = useState(false);
  const implementSettingsDialogRef = useRef<DialogRef>(null);
  const dispatch: any = useDispatch();
  const listRef = useRef<ListRef>(null);
  const {loading, currentUserShipment, currentUserShipmentOrders} = useSelector(ShipmentSlice);
  useEffect(() => {
    if (currentUserShipment) {
      setStatus(Number(currentUserShipment.status));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserShipment]);

  const handleUpdateSettings = () => {
    let newShipmentDetails: ISetUserShipmentDetailsDto = {
      userShipmentId: currentUserShipment.userShipmentId,
      userInventoryId: "",
      userShipmentItemId: "",
      field: "ShipmentSettings",
      newValue: 0,
      shipDate: "",
      amazonShipmentId: "",
      from: "Shipments",
    };

    setUserShipmentDetails(newShipmentDetails, "implementSettings")(dispatch);
  };

  const handleMarkAsDraft = () => {
    let newShipmentDetails: ISetUserShipmentDetailsDto = {
      userShipmentId: currentUserShipment.userShipmentId,
      userInventoryId: "",
      userShipmentItemId: "",
      field: "MarkAsDraft",
      newValue: 0,
      shipDate: "",
      amazonShipmentId: "",
      from: "Shipments",
    };

    setUserShipmentDetails(newShipmentDetails, "implementSettings")(dispatch);
  };

  return (
    <React.Fragment>
      <Card className="mx-n4 mb-n5">
        <div className={status === UserShipmentStatus.SHIPPED ? "bg-success-subtle" : status === UserShipmentStatus.COMPLETED ? "bg-secondary-subtle" : ""}>
          <CardBody className="pb-4 mb-5">
            <Row>
              <Col xs="auto">
                <div className="avatar-md mb-md-0 mb-4">
                  <div className="avatar-title bg-white rounded-circle">
                    <img src={img} alt="" className="avatar-sm" />
                  </div>
                </div>
              </Col>
              <Col>
                <Row>
                  <Col xs="auto">
                    <div className="hstack gap-3 flex-wrap">
                      <h4 className="mb-0 d-flex align-items-center">
                        <span>{"#" + currentUserShipment.nameId + " - " + currentUserShipment.name}</span>
                        <Link
                          to=""
                          id={`EditButton1`}
                          className="btn btn-ghost-secondary px-1 py-0 fs-16 ms-1"
                          onClick={() => {
                            setIsEditing(true);
                          }}
                        >
                          <i className="ri-pencil-fill"></i>
                        </Link>
                        <DefaultUncontrolledTooltip target={`EditButton1`}>{t("Edit")}</DefaultUncontrolledTooltip>
                      </h4>
                      <div className="vr"></div>
                      <div>
                        <div className="hstack gap-1">
                          <i className="bx bxs-location-plus text-success fs-20"></i>
                          <span>
                            <span className="text-nowrap">{sourceMarketplace?.countryName} </span>
                            <img src={sourceMarketplace?.flag} alt={`${sourceMarketplace?.countryName} Flag`} className="rounded-circle ms-1" height="20" />
                          </span>
                          <span className="mx-1">
                            <i className="ri-arrow-right-line fs-20 align-bottom"></i>
                          </span>
                          <span className="text-nowrap">
                            <img src={destinationMarketplace?.flag} alt={`${t(destinationMarketplace?.countryName ?? "")} Flag`} className="rounded-circle me-1" height="20" />{" "}
                            {t(destinationMarketplace?.countryName ?? "")}
                            {" - "}
                            <span className="text-nowrap">{currentUserShipment.userStore.name}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col xs="auto">
                    <div className="hstack gap-3 flex-wrap">
                      <div className="d-flex align-items-center">
                        <i className=" ri-calendar-2-line align-bottom me-1"></i>
                        <span>{t("Shipped Date")}:</span>
                        <span className="fw-medium ms-1">
                          <Moment locale={i18n.language} format="DD/MM/Y">
                            {currentUserShipment.shipDate}
                          </Moment>

                          <span className="text-nowrap">
                            {" ("}
                            <Moment className="text-primary" locale={i18n.language} fromNow>
                              {currentUserShipment.shipDate}
                            </Moment>
                            {")"}
                          </span>
                        </span>
                      </div>
                      <div className="vr"></div>
                      <div className="hstack gap-4 flex-wrap">
                        <div className="d-flex align-items-center">
                          <i className="ri-flight-takeoff-line me-1"></i>
                          <span className="me-1 text-nowrap">{t("Shipments.AmazonShipmentId")}:</span>
                          <span className="fw-medium">
                            {currentUserShipment.amazonShipmentId !== "" && currentUserShipment.amazonShipmentId !== null ? (
                              currentUserShipment.amazonShipmentId
                            ) : (
                              <i className="las la-ellipsis-h align-bottom"></i>
                            )}
                            {status === UserShipmentStatus.SHIPPED ? (
                              <>
                                <Link
                                  to=""
                                  id={`EditButton2`}
                                  className="btn btn-ghost-secondary px-1 py-0 fs-14 ms-1"
                                  onClick={() => {
                                    setIsEditing(true);
                                  }}
                                >
                                  <i className="ri-pencil-fill"></i>
                                </Link>
                                <DefaultUncontrolledTooltip target={`EditButton2`}>{t("Edit")}</DefaultUncontrolledTooltip>
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs="auto" className="mt-3 mt-md-0">
                <div className="hstack gap-3 flex-wrap">
                  <div>
                    <Button
                      size="sm"
                      id={`AnalyzeShipmentItems`}
                      className="btn btn-secondary fw-semibold"
                      onClick={() => {
                        ReAnalyzeModal.open({
                          searchName: currentUserShipment.name,
                          store: currentUserShipment.userStoreId,
                          shipmentId: currentUserShipment.userShipmentId,
                          businessModel: AmazonBusinessModel.CROSS_BORDER_ARBITRAGE,
                          type: AnalyzeType.SHIPMENT,
                        });
                      }}
                    >
                      <i className="mdi mdi-chart-timeline-variant-shimmer align-middle me-1"></i>
                      {t("Shipments.ShipmentDetails.Button.ReanalyzeTheProducts")}
                    </Button>
                    <DefaultUncontrolledTooltip target={`AnalyzeShipmentItems`}>{t("Shipments.ShipmentDetails.Info.ReAnalyze")}</DefaultUncontrolledTooltip>
                  </div>
                  {status === UserShipmentStatus.SHIPPED && currentUserShipmentOrders.length <= 0 && (
                    <Button size="sm" className="btn btn-warning fw-semibold" onClick={handleMarkAsDraft}>
                      <i className="ri-arrow-go-back-fill label-icon align-middle me-2"></i>
                      {loading.changeStatus && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
                      {t("Shipments.ShipmentDetails.Button.MarkAsDraft")}
                    </Button>
                  )}
                  <span
                    className={`badge rounded-pill fs-12 p-2 ${status === UserShipmentStatus.SHIPPED ? "bg-success" : status === UserShipmentStatus.COMPLETED ? "bg-secondary" : "bg-warning"}`}
                    id="shipment-status"
                  >
                    {status === UserShipmentStatus.SHIPPED ? t("Shipped") : status === UserShipmentStatus.COMPLETED ? t("Completed") : ""}
                  </span>
                </div>
              </Col>
            </Row>
          </CardBody>
        </div>
      </Card>
      <Dialog
        ref={implementSettingsDialogRef}
        color="info"
        buttons={["yes", "no"]}
        buttonConfig={(buttonConfigs) => {
          return {
            ...buttonConfigs,
            yes: {
              ...buttonConfigs.yes,
              label: t("Yes"),
            },
            no: {
              ...buttonConfigs.no,
              label: t("Close"),
            },
          };
        }}
        busy={loading.implementSettings}
        iconClass="mdi mdi-download"
        message={t("Shipments.ShipmentDetails.Dialog.ImplementSettings.Description")}
        title={t("Shipments.ShipmentDetails.Dialog.ImplementSettings.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            handleUpdateSettings();
            listRef.current?.reload();
          }
          implementSettingsDialogRef.current?.hide();
        }}
      />
      <EditShipment isOpen={isEditing} onReload={() => listRef.current?.reload()} toggle={() => setIsEditing(!isEditing)} from="ShipmentDetails" shipment={currentUserShipment} />
    </React.Fragment>
  );
};

export default ShipmentInfo;

import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {UserSearch} from "models/user_search";
import {Badge, Button, Card, CardBody, Col, Container, Progress} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {getRelativeDateByTimezone, getToday} from "helpers/utilities";
import {createSelector} from "reselect";
import {useTranslation} from "react-i18next";
import {useProfile} from "Components/Hooks/useProfile";
import {ColumnDef} from "@tanstack/react-table";
import {Link} from "react-router-dom";
import {RootState} from "slices";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/useDebounce";
import {AdminUserSearchesQuery} from "api/query";
import {cancelSearch, getAdminUserSearchesList, resetAdminSearchesState, restartSearch, undoDeleteSearch} from "slices/admin/searches/thunk";
import {ConstantPage} from "helpers/permission_helper";
import {BusinessModelBadge} from "Components/Common/Badges/BusinessModelBadge";
import {SearchStatusBadge} from "Components/Common/Badges/SearchStatusBadge";
import {UserSearchStatus} from "models/enums/user_search_status";
import Moment from "react-moment";
import Loader from "Components/Common/Loader";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import BreadCrumb from "Components/Common/BreadCrumb";
import Filters from "./Filters";
import NoResult from "Components/Common/NoResult";
import defaultAvatar from "assets/images/user-dummy-img.jpg";
import DisplayNumber from "Components/Common/DisplayNumber";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayDate from "Components/Common/DisplayDate";
import i18n from "i18n";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import {AllMarketplaces} from "helpers/marketplace_helper";

export type AdminSearchesListRef = {
  reload: VoidFunction;
};
interface AdminSearchesListProps {
  edit?: boolean;
}
const PAGE_IDENTIFIER: ConstantPage = "adminSearch";
const AdminSearchesPage = (props: AdminSearchesListProps) => {
  const {t} = useTranslation();
  const dispatch: any = useDispatch();
  const tableRef = useRef<DataTableRef>(null);
  const restartDialogRef = useRef<DialogRef>(null);
  const cancelDialogRef = useRef<DialogRef>(null);
  const undoDeleteDialogRef = useRef<DialogRef>(null);
  const listRef = useRef<AdminSearchesListRef>(null);

  const [currentSearch, setCurrentSearch] = useState<UserSearch>();
  const {userProfile, hasPermission} = useProfile();
  const [localQuery, updateLocalQuery] = useImmer<AdminUserSearchesQuery>({
    action: "filtering",
    startDate: undefined,
    endDate: undefined,
    page: 1,
    pageSize: 10,
  });

  let filterFields: FieldConfig<AdminUserSearchesQuery>[] = [
    {field: "startDate", queryParam: "start", type: "date", defaultValue: getRelativeDateByTimezone(30, userProfile?.timezone)},
    {field: "endDate", queryParam: "end", type: "date", defaultValue: getToday().toDate()},
    {field: "marketplace", queryParam: "marketplace", type: "array"},
    {field: "userId", queryParam: "userId", type: "string"},
    {field: "amazonBusinessModels", queryParam: "businessModels", type: "array"},
    {field: "statuses", queryParam: "statuses", type: "array"},
    {field: "page", queryParam: "page", type: "number", defaultValue: localQuery.page},
    {field: "pageSize", queryParam: "pageSize", type: "number", defaultValue: localQuery.pageSize},
    {field: "sortBy", queryParam: "sortBy", type: "string", defaultValue: localQuery.sortBy},
    {field: "sortingOrder", queryParam: "sortingOrder", type: "number", defaultValue: localQuery.sortingOrder},
    {field: "action", queryParam: "action", type: "string", defaultValue: "filtering"},
  ];
  const {readQueryParams, updateQuery, searchParams} = useUrlQuery<AdminUserSearchesQuery>(filterFields);

  const debouncedLoadList = useDebounce(() => {
    getAdminUserSearchesList(localQuery)(dispatch).then(() => {
      tableRef.current?.resetSelection();
    });
  }, 200);

  useEffect(() => {
    if (hasPermission("adminSearch") && searchParams.has("action")) {
      const params = readQueryParams();
      updateLocalQuery(params as AdminUserSearchesQuery);
      debouncedLoadList();
    }
  }, [debouncedLoadList, searchParams]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const searchesData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.AdminSearches.loading,
      searchesList: state.AdminSearches.searchesList,
    }),
  );

  const {loading, searchesList} = useSelector(searchesData);

  const columns = useMemo<ColumnDef<UserSearch, any>[]>(
    () => [
      {
        header: t("Actions"),
        enableSorting: false,
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          const statusText: UserSearchStatus = row.status;
          return (
            <>
              <div className="d-flex flex-row align-items-center">
                {statusText !== UserSearchStatus.IN_PROGRESS && ( // Restart button is only visible when the search is not in progress
                  <Col xs="auto">
                    <Button
                      id={`RestartSearch-${row.userSearchId}`}
                      color="link"
                      className="p-0 btn btn-ghost-success px-1 py-0 fs-18"
                      onClick={() => {
                        setCurrentSearch(row);
                        restartDialogRef.current?.show();
                      }}
                    >
                      <i className="mdi mdi-sync-circle align-middle"></i>
                    </Button>
                    <DefaultUncontrolledTooltip target={`RestartSearch-${row.userSearchId}`}>{t("Admin.Searches.Tooltip.Restart")}</DefaultUncontrolledTooltip>
                  </Col>
                )}

                <Col xs="auto">
                  <Link to={`/admin/search-logs/${row.userSearchId}`} id={`LogSearch-${row.userSearchId}`} className="btn btn-ghost-secondary px-1 py-0 fs-18" target="_blank">
                    <i className="mdi mdi-text-box align-middle"></i>
                  </Link>
                  <DefaultUncontrolledTooltip target={`LogSearch-${row.userSearchId}`}>{t("Admin.Searches.Tooltip.Logs")}</DefaultUncontrolledTooltip>
                </Col>
                {statusText === UserSearchStatus.IN_PROGRESS && ( // Cancel button is only visible when the search is in progress
                  <Col xs="auto">
                    <Button
                      id={`CancelSearch-${row.userSearchId}`}
                      color="link"
                      className="p-0 btn btn-ghost-danger px-1 py-0 fs-18"
                      onClick={() => {
                        setCurrentSearch(row);
                        cancelDialogRef.current?.show();
                      }}
                    >
                      <i className="mdi mdi-close-circle align-middle"></i>
                    </Button>
                    <DefaultUncontrolledTooltip target={`CancelSearch-${row.userSearchId}`}>{t("Admin.Searches.Tooltip.Cancel")}</DefaultUncontrolledTooltip>
                  </Col>
                )}
              </div>
            </>
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.User"),
        enableSorting: true,
        accessorKey: "user.fullName",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-2">
                <img src={row.user.avatar || defaultAvatar} className="rounded-circle avatar-sm img-thumbnail user-profile-image object-fit-cover" alt="user-profile" />
              </div>
              <div className="flex-grow-1 ellipsis-two-lines">
                <div className="fw-semibold">
                  {row.user.fullName}
                  {row.user.userId === userProfile?.userId && <Badge className="ms-1 rounded-pill">{t("You")}</Badge>}
                </div>
                <div className="text-muted" title={row.user.email}>
                  {row.user.email}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.BusinessModel"),
        enableSorting: true,
        accessorKey: "amazonBusinessModel",
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <>
              <BusinessModelBadge key={row.userSearchId} model={row.amazonBusinessModel} size="sm" />
            </>
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.SearchName"),
        enableSorting: true,
        accessorKey: "name",
        size: 250,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <Link className="link-secondary link-offset-2 text-decoration-underline me-1 ellipsis-two-lines" role="button" to={`/searches/search-results/${row.userSearchId}`}>
              {row.name}
            </Link>
          );
        },
      },

      {
        header: t("Admin.Searches.TableColumn.Marketplace"),
        enableSorting: true,
        accessorKey: "marketplaceTarget",
        sortingFn: "alphanumeric",
        size: 250,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <>
              <span className="hstack gap-2">
                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                  <img src={AllMarketplaces.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === row.marketplaceTarget)?.flag} alt="Country Flag" className=" rounded-circle" />
                </div>
                <div className="flex-grow-1 ellipsis-two-lines">
                  {row.userStore?.name ? row.marketplaceTarget : AllMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === row.marketplaceTarget)?.countryName}
                  {row.userStore ? ` - ${row.userStore.name}` : ""}
                </div>
              </span>
            </>
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.CreateDate"),
        enableSorting: true,
        accessorKey: "createDate",
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <div className="ellipsis-two-lines">
              <DisplayDate id={`DisplayDateSearchesCreateDate-${row.userSearchId}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </div>
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.ProcessingTime"),
        enableSorting: false,
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          const diffDate = row.status === UserSearchStatus.IN_PROGRESS ? new Date() : row.statusDate;
          return (
            <>
              <Moment className="text-primary" locale={i18n.language} tz={userProfile?.timezone} diff={row.createDate} decimal={false} unit="minutes">
                {diffDate}
              </Moment>{" "}
              {t("min")}
            </>
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.Status"),
        enableSorting: true,
        accessorKey: "status",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <div className="d-flex align-items-center gap-1">
              {row.deleted ? (
                <>
                  <span className={`float-start badge rounded-pill bg-danger-subtle text-danger fs-12`}>{t("Deleted")}</span>
                  <Button
                    type="button"
                    id={`UndoDelete-${row.userSearchId}`}
                    className="btn btn-ghost-secondary waves-effect py-0 px-1 fs-16"
                    onClick={() => {
                      setCurrentSearch(row);
                      undoDeleteDialogRef.current?.show();
                    }}
                  >
                    <i className="mdi mdi-undo-variant align-middle"></i>
                  </Button>
                  <DefaultUncontrolledTooltip target={`UndoDelete-${row.userSearchId}`}>{t("Admin.Searches.Tooltip.UndoDelete")}</DefaultUncontrolledTooltip>
                </>
              ) : (
                <SearchStatusBadge status={row.status} size="sm" />
              )}
            </div>
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.Total"),
        enableSorting: true,
        accessorKey: "totalProductCount",
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <Link className="link-secondary link-offset-2 text-decoration-underline me-1" role="button" to={`/searches/search-results/${row.userSearchId}`}>
              <DisplayNumber value={row.totalProductCount} />
            </Link>
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.Completed"),
        enableSorting: true,
        accessorKey: "completedProductCount",
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          const statusText: UserSearchStatus = row.status;
          return statusText === UserSearchStatus.IN_PROGRESS ? (
            <>
              <div className="align-items-center d-flex">
                <DisplayNumber value={row.completedProductCount} />
                <i className="mdi mdi-spin mdi-loading fs-20 ms-2"></i>
              </div>
              <div style={{width: "90%"}}>
                <Progress color="secondary" value={(row.completedProductCount / row.totalProductCount) * 100} className="progress-sm" />
              </div>
            </>
          ) : (
            <DisplayNumber value={row.completedProductCount} />
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.Sellable"),
        enableSorting: true,
        accessorKey: "sellableProductCount",
        sortingFn: "alphanumeric",
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return <DisplayNumber value={row.sellableProductCount} />;
        },
      },
      {
        header: t("Admin.Searches.TableColumn.Recheck"),
        enableSorting: true,
        accessorKey: "recheckProductCount",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return <DisplayNumber value={row.recheckProductCount} />;
        },
      },
      {
        header: t("Admin.Searches.TableColumn.RestrictionCheck"),
        enableSorting: true,
        accessorKey: "restrictionCheckCompletedProductCount",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return <DisplayNumber value={row.restrictionCheckCompletedProductCount} />;
        },
      },
      {
        header: t("Admin.Searches.TableColumn.New"),
        enableSorting: true,
        accessorKey: "newProductCount",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return <DisplayNumber value={row.newProductCount} />;
        },
      },
    ],
    [t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    return () => {
      dispatch(resetAdminSearchesState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.title = t("PageTitles.Searches");

  //#endregion
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Searches.Title")} menus={[{label: t("Searches.Title")}]} />
          <Filters busy={loading.filter} fields={filterFields} listRef={listRef} />
          <Card>
            <CardBody>
              {loading.filter ? (
                <>
                  <Loader />
                </>
              ) : searchesList.items && searchesList.items.length > 0 ? (
                <>
                  <DataTable
                    ref={tableRef}
                    busy={loading.list}
                    columns={columns}
                    data={searchesList?.items || []}
                    totalDataLength={searchesList?.totalCount}
                    pagination={{
                      pageIndex: localQuery.page - 1,
                      pageSize: localQuery.pageSize,
                    }}
                    onPaginationChanged={(pagination) => {
                      updateQuery({...localQuery, page: pagination.pageIndex + 1, pageSize: pagination.pageSize, action: "paginating"});
                    }}
                    onSortingChanged={(sorting) => {
                      updateQuery({...localQuery, sortBy: sorting?.sortBy, sortingOrder: sorting?.sortingOrder, action: "sorting"});
                    }}
                    hovered
                  />
                </>
              ) : (
                <NoResult title={t("Searches.NoResult.Title")} description={t("Searches.NoResult.Description")} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <Dialog
        ref={restartDialogRef}
        color="success"
        buttons={["yes", "no"]}
        busy={loading.restart}
        iconClass="ri-restart-line"
        message={t("Admin.Searches.Dialog.Restart.Description")}
        title={t("Admin.Searches.Dialog.Restart.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (currentSearch) {
              await restartSearch(currentSearch, localQuery)(dispatch);
            }
          }
          restartDialogRef.current?.hide();
        }}
      />
      <Dialog
        ref={cancelDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.cancel}
        iconClass="ri-close-circle-line"
        message={t("Admin.Searches.Dialog.Cancel.Description")}
        title={t("Admin.Searches.Dialog.Cancel.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (currentSearch) {
              await cancelSearch(currentSearch, localQuery)(dispatch);
            }
          }
          cancelDialogRef.current?.hide();
        }}
      />

      <Dialog
        ref={undoDeleteDialogRef}
        color="secondary"
        buttons={["yes", "no"]}
        busy={loading.cancel}
        iconClass="mdi mdi-undo-variant"
        message={t("Admin.Searches.Dialog.UndoDelete.Description")}
        title={t("Admin.Searches.Dialog.UndoDelete.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (currentSearch) {
              await undoDeleteSearch(currentSearch, localQuery)(dispatch);
            }
          }
          undoDeleteDialogRef.current?.hide();
        }}
      />
    </Restricted>
  );
};

export default AdminSearchesPage;

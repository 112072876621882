import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import {useCallback, useEffect, useState} from "react";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {getProductDetails, resetSearchProductState, setProductDetailsPageUrl} from "slices/product-details/thunk";
import {useProfile} from "Components/Hooks/useProfile";
import {convertToAmazonBusinessModel} from "models/enums/user_search_type";
import {BusinessModelBadge} from "Components/Common/Badges/BusinessModelBadge";
import {AllMarketplaces, AmazonMarketplace} from "helpers/marketplace_helper";
import {ProductDetailsSlice} from "slices/product-details/selector";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import ProductInfo from "./_ProductInfo";
import AnalysisSummary from "./_AnalysisSummary";
import ProfitAnalysis from "./_ProfitAnalysis";
import CompetitorAnalysis from "./_CompetitorAnalysis";
import VariationAnalysis from "./_VariationAnalysis";
import SellersAndListingAnalysis from "./_SellersAndListingAnalysis";
import SalesAnalysis from "./_SalesAnalysis";
import SellThis from "Components/Common/SellThis";
import NoResult from "Components/Common/NoResult";
import LimitExceeded from "./_LimitExceeded";
import ProfitCalculator from "Components/Common/Calculator/_ProfitCalculator";
import ReAnalyze from "Components/Common/Modals/ReAnalyze";
import CheckList from "pages/Searches/SearchResults/Modals/CheckList";
import IneligibilityReasonList from "pages/Searches/SearchResults/Modals/IneligibilityReasonList";

const ProductDetails = () => {
  const {t} = useTranslation();
  const {isUserLoggedIn, userProfile} = useProfile();
  const [sourceMarketplace, setSourceMarketplace] = useState<AmazonMarketplace>();
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplace>();
  const {asin, searchId} = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {loading, isLimitExceeded ,details, pageUrl, error} = useSelector(ProductDetailsSlice);

  const findPageUrl = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchId && asin) {
      setProductDetailsPageUrl(`/dp/${asin}/${searchId}`)(dispatch);
    } else if (asin && searchParams.has("marketplace")) {
      setProductDetailsPageUrl(`/dp/${asin}?marketplace=${searchParams.get("marketplace")}&asin=${asin}&ref=${searchParams.get("ref")}`)(dispatch);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    findPageUrl();
  }, [findPageUrl]);

  useEffect(() => {
    let source = AllMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === details.productInfo?.sourceMarketplace);
    let destination = AllMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === details.productInfo?.targetMarketplace);
    setSourceMarketplace(source);
    setDestinationMarketplace(destination);
  }, [details]); // eslint-disable-line

  const onUrlChange = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const isUrlSame = location.pathname === pageUrl || location.pathname === pageUrl + "/";

    if (searchParams.has("singleSearch") || isUrlSame) {
      return;
    }
    if (asin && searchId) {
      // For SearchResult
      getProductDetails({asin, userSearchId: searchId, ref: searchParams.get("ref") || undefined}, userProfile)(dispatch);
    } else if (asin && searchParams.has("marketplace")) {
      // For Single Search with marketplace
      const mpValue = searchParams.get("marketplace");
      const amazonBusinessModelValue = convertToAmazonBusinessModel(searchParams.get("amazonBusinessModel"));
      if (mpValue!.length > 2) {
        getProductDetails({asin: asin, userStoreId: mpValue!, amazonBusinessModel: amazonBusinessModelValue, ref: searchParams.get("ref") || undefined}, userProfile)(dispatch);
      } else {
        getProductDetails({asin: asin, marketplaceTarget: searchParams.get("marketplace")!, amazonBusinessModel: amazonBusinessModelValue, ref: searchParams.get("ref") || undefined}, userProfile)(dispatch);
      }
    } else {
      navigate("/");
    }
  }, [asin, searchId]); // eslint-disable-line


  useEffect(() => {
    onUrlChange();
  }, [onUrlChange]);

  useEffect(() => {
    return () => {
      resetSearchProductState()(dispatch);
    };
  }, []); // eslint-disable-line

  document.title = `${t("PageTitles.ProductDetails")} - ${asin}`;

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: `${t("PageTitles.ProductDetails")} - ${asin}`,
    },
  ];

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <PlaceholderGlow busy={loading.list}>
            {!isUserLoggedIn() && isLimitExceeded ? (
              <>
                <LimitExceeded />
              </>
            ) : loading.list === false && error ? (
              <Card>
                <CardBody>
                  <NoResult title={t("ProductDetails.NoResult.Title")} description={t(error.message)} />
                </CardBody>
              </Card>
            ) : (
              <>
                <BreadCrumb title={`${t("ProductDetails.Title")} - ${asin}`} menus={breadcrumbMenus} component={<BusinessModelBadge model={details.productInfo?.amazonBusinessModel} size="md" />} />
                <Row>
                  <Col>
                    <div className="h-100">
                      <Row>
                        <Col>
                          <ProductInfo destinationMarketplace={destinationMarketplace} sourceMarketplace={sourceMarketplace} />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <AnalysisSummary destinationMarketplace={destinationMarketplace} sourceMarketplace={sourceMarketplace} />
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={4} className="same-height">
                          <ProfitCalculator
                            title={t("ProductDetails.ProfitCalculation.Title")}
                            destinationMarketplace={destinationMarketplace}
                            sourceMarketplace={sourceMarketplace}
                            amazonBusinessModel={details?.productInfo.amazonBusinessModel}
                            buyBoxType={details?.sellerAndListingAnalysis.buyBoxType}
                            itemProfitAnalysis={details?.profitCalculation}
                            offerCount={details?.sellerAndListingAnalysis.offerCount}
                            userSearchProductId={details?.productInfo.userSearchProductId}
                          />
                        </Col>
                        <Col xl={8} className="same-height">
                          <ProfitAnalysis destinationMarketplace={destinationMarketplace} sourceMarketplace={sourceMarketplace} />
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={12} className="same-height">
                          <SalesAnalysis />
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={12} className="same-height">
                          <CompetitorAnalysis destinationMarketplace={destinationMarketplace} />
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={12} className="same-height">
                          <SellersAndListingAnalysis destinationMarketplace={destinationMarketplace} sourceMarketplace={sourceMarketplace} />
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={12} className="same-height">
                          <VariationAnalysis destinationMarketplace={destinationMarketplace} />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </PlaceholderGlow>
        </Container>
      </div>
      <SellThis />
      <CheckList />
      <ReAnalyze />
      <IneligibilityReasonList />
    </>
  );
};

export default ProductDetails;

import React, {useState} from "react";
import {Modal, ModalHeader, ModalBody, Button, ModalFooter, Col, Input, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {setUserShowCheckListOptionChoice} from "slices/search-result/thunk";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {createTypedModal} from "helpers/modal_helpers";
import {UserSearchProductCheckList} from "models/user_search_product_check_list";
import {SellThisModal} from "Components/Common/SellThis";
import CheckListItem from "./Components/CheckListItem";

interface ModalData {
  asin: string;
  checkList?: UserSearchProductCheckList;
  marketplaceTarget: string;
  amazonBusinessModel: AmazonBusinessModel;
}

export const CheckListModal = createTypedModal<ModalData>("check_list");
const CheckList = () => {
  const {open, data} = CheckListModal.useModal();

  if (!open) return null;
  return <CheckListContent data={data} />;
};

const CheckListContent = ({data}: {data: ModalData | undefined}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const updateShowCheckListOption = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    setUserShowCheckListOptionChoice(!event.target.checked as boolean, data?.amazonBusinessModel!)(dispatch);
    setLoading(false);
  };

  const toggle = () => {
    CheckListModal.close();
  };

  return (
    <Modal backdrop="static" isOpen={true} toggle={toggle} fade={true} centered={true}>
      <ModalHeader className="p-3" toggle={toggle}>
        {t("SearchResults.Dialog.ShowCheckList.Title")}
      </ModalHeader>
      <ModalBody>
        <ul className="list-unstyled vstack gap-1 mb-0">
          <h5>{t("SearchResults.Dialog.ShowCheckList.Description")}</h5>
          <CheckListItem
            labelName={t("SearchResults.Analyzes.CheckList.SameProduct")}
            status={data?.checkList?.sameProductCheckResult === 1 ? undefined : null}
            hide={data?.amazonBusinessModel === AmazonBusinessModel.WHOLESALE}
          />
          <CheckListItem
            labelName={t("SearchResults.Analyzes.CheckList.CustomsCheck")}
            status={data?.checkList?.customsCheckCategoryCheckResult === 1 || data?.checkList?.customsCheckKeywordCheckResult === 1 ? undefined : null}
            hide={data?.amazonBusinessModel === AmazonBusinessModel.WHOLESALE}
          />

          <CheckListItem
            labelName={t("SearchResults.Analyzes.CheckList.ProductSizeEligibleToFba")}
            status={data?.checkList?.productSizeEligibleToFBA ?? null}
            hide={data?.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING}
          />
          <CheckListItem
            labelName={
              t("SearchResults.Analyzes.CheckList.IsOldProduct") +
              (data?.checkList?.productExistSince
                ? "(" +
                  data?.checkList.productExistSince
                    .replace("years", t("years"))
                    .replace("year", t("year"))
                    .replace("months", t("months"))
                    .replace("month", t("month"))
                    .replace("days", t("days"))
                    .replace("day", t("day"))
                    .replace("and", t("and"))
                    .replace("ago", t("ago")) +
                  ")"
                : "")
            }
            status={data?.checkList?.isOldProduct ?? null}
            hide={data?.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING}
          />

          <CheckListItem labelName={t("SearchResults.Analyzes.CheckList.IsTheRatingAverageGood")} status={data?.checkList?.isTheRatingAverageGood ?? null} />
        </ul>
        <br></br>
      </ModalBody>
      <ModalFooter>
        <div className="vstack justify-content-start">
          <Row className="d-flex align-items-center">
            <Col xs="auto">
              <span>{t("SearchResults.Dialog.ShowCheckList.SwitchShowCheckListTitle")}</span>
            </Col>
            <Col xs="auto">
              <div className="form-check form-switch">
                <Input id="showCheckList" className="form-check-input form-switch-md " disabled={loading} type="checkbox" role="switch" onChange={updateShowCheckListOption} />
              </div>
            </Col>
          </Row>
        </div>
        <div className="hstack gap-2 justify-content-end">
          <Button type="button" color="light" onClick={toggle}>
            {t("Close")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            className="w-sm"
            onClick={() => {
              CheckListModal.close();
              SellThisModal.open({
                asin: data?.asin!,
                marketplace: data?.marketplaceTarget!,
              });
            }}
          >
            {t("SearchResults.Dialog.ShowCheckList.SubmitButton")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
export default CheckList;

import Dialog, {DialogRef} from "Components/Common/Dialog";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, FormFeedback, Col, Row, Spinner, Label, Card, Form} from "reactstrap";
import {useFormik} from "formik";
import {useProfile} from "Components/Hooks/useProfile";
import {Link, useNavigate} from "react-router-dom";
import {RefObject, useCallback, useEffect, useRef, useState} from "react";
import {read, utils} from "xlsx";
import {registerPlugin} from "react-filepond";
import {useTranslation} from "react-i18next";
import {saveNewSearch} from "slices/searches/thunk";
import {useDispatch, useSelector} from "react-redux";
import {SearchesListRef} from "..";
import {formatBytes, getActiveUserStoresWithActiveMarketplaceOptions, preventScrollUp} from "helpers/utilities";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {SaveUserSearchCommand} from "api/command";
import {AmazonHelper} from "helpers/amazon_helper";
import {ActiveMarketplaceCurrencyOptions} from "helpers/marketplace_helper";
import {SearchesSlice} from "slices/searches/selector";
import {CommonSliceSelector} from "slices/common/selector";
import Dropzone from "react-dropzone";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import ValidatedLineNumberedTextarea from "Components/Common/ValidatedLineNumberedTextarea";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import ValidatedInput from "Components/Common/ValidatedInput";
import DisplayNumber from "Components/Common/DisplayNumber";
import useSelectOptions, {SelectOptionsType} from "Components/Hooks/useSelectOptions";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import * as Yup from "yup";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const DAILY_SEARCH_LIMIT = 5000;
enum DataMode {
  MANUAL_DATA = "MANUAL_DATA",
  UPLOAD_DATA = "UPLOAD_DATA",
}
interface NewSearchProps {
  isOpen: boolean;
  listRef: RefObject<SearchesListRef>;
  busy: boolean;
}
const NewSearch = (props: NewSearchProps) => {
  const {t} = useTranslation();
  const {businessModelSelectOptions} = useSelectOptions();
  const {getDailySearchLimit} = useProfile();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const exceedLimitConfirmationDialog = useRef<DialogRef>(null);
  const [isValidFile, setIsValidFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [limitExceeding, setLimitExceeding] = useState<boolean>(false);
  const [maxLineLimit, setMaxLineLimit] = useState<number>(DAILY_SEARCH_LIMIT);
  const [liveLimit, setLiveLimit] = useState<number>(getDailySearchLimit());
  const [marketplaceOptions, setMarketplaceOptions] = useState<SelectOptionsType[]>([]);
  const [invalidLineNumbers, setInvalidLineNumbers] = useState<number[]>([]);
  const [hasDuplicateValues, setHasDuplicateValues] = useState<boolean>(false);
  const {loading} = useSelector(SearchesSlice);
  const {activeUserStoreOptions, activeMarketplaceOptions} = useSelector(CommonSliceSelector);

  const dataValidator = () =>
    Yup.string().test("data", function(value) {
      if (!value || !value.trim()) {
        return this.createError({
          path: "data",
          message: t("Searches.Dialog.NewSearch.Validation.Asin"),
        });
      }

      const identifierList = value.split("\n");
      let invalidLineNumbersArray: number[] = [];
      let duplicateLineNumbersArray: number[] = [];
      let invalidRows: string[] = [];
      let duplicateRows: string[] = [];

      // Map for duplicate checking
      const identifierMap = new Map<string, number[]>();

      if (validation.values.businessModel.toString() === AmazonBusinessModel.WHOLESALE.toString()) {
        identifierList.forEach((line: string, lineIndex: number) => {
          let [identifier, price] = line.trim().split(/[,;]/);
          identifier = identifier.trim();

          const isValidIdentifier = AmazonHelper.isValidAsin(identifier) || AmazonHelper.isValidEan(identifier) || AmazonHelper.isValidUpc(identifier);

          // Add to identifier map
          if (!identifierMap.has(identifier)) {
            identifierMap.set(identifier, []);
          }
          identifierMap.get(identifier)?.push(lineIndex + 1);

          if (!price || isNaN(price as any) || !isValidIdentifier) {
            invalidRows.push(line);
            invalidLineNumbersArray.push(lineIndex + 1);
          }
        });
      } else {
        identifierList.forEach((asin: string, lineIndex: number) => {
          let value = asin.trim();

          // Add to identifier map
          if (!identifierMap.has(value)) {
            identifierMap.set(value, []);
          }
          identifierMap.get(value)?.push(lineIndex + 1);

          if (!AmazonHelper.isValidAsin(value)) {
            invalidRows.push(value);
            invalidLineNumbersArray.push(lineIndex + 1);
          }
        });
      }

      // Mark duplicate number lines
      identifierMap.forEach((lineNumbers, identifier) => {
        if (lineNumbers.length > 1) {
          duplicateLineNumbersArray.push(...lineNumbers);
          duplicateRows.push(identifier);
        }
      });

      // Create error messages
      if (invalidRows.length > 0) {
        setInvalidLineNumbers(invalidLineNumbersArray);
        return this.createError({
          path: "data",
          message: t("InvalidValue", {value: invalidRows.join(", ")}),
        });
      }

      setHasDuplicateValues(duplicateRows.length > 0);
      setInvalidLineNumbers([]);
      return true;
    });

  const validation = useFormik({
    initialValues: {
      searchName: "",
      marketplace: "",
      businessModel: AmazonBusinessModel.CROSS_BORDER_ARBITRAGE,
      currency: "",
      identifierList: [],
      data: "" as any, // For holding data for front-side
      dataMode: DataMode.MANUAL_DATA, // For holding data for front-side
    },
    validationSchema: Yup.object().shape({
      searchName: Yup.string()
        .required(t("Searches.Dialog.NewSearch.Validation.Name"))
        .max(50, t("Searches.Dialog.NewSearch.Validation.NameMaxLength")),
      businessModel: Yup.mixed<AmazonBusinessModel>().required(t("Searches.Dialog.NewSearch.Validation.BusinessModel")),
      marketplace: Yup.string().required(t("Searches.Dialog.NewSearch.Validation.Marketplace")),
      currency: Yup.string().when("businessModel", (businessModel, schema) => {
        if (businessModel.toString() === AmazonBusinessModel.WHOLESALE.toString()) {
          return schema.required(t("FieldRequired"));
        } else {
          return schema.notRequired();
        }
      }),
      dataMode: Yup.mixed<DataMode>(),
      data: dataValidator().required(t("Searches.Dialog.NewSearch.Validation.Asin")),
    }),
    onSubmit: (values) => {
      const searchParameters: SaveUserSearchCommand = {
        searchName: values.searchName,
        marketplace: values.marketplace,
        amazonBusinessModel: values.businessModel,
        identifierList: values.identifierList,
        currency: values.currency,
      };

      searchParameters.identifierList = values.data
        .toUpperCase()
        .split("\n")
        .map((item: any) =>
          item
            .trim()
            .replace(/\s+/g, "")
            .replace(/\r/g, ""),
        );

      if (values.dataMode === DataMode.UPLOAD_DATA) {
        if (limitExceeding) {
          searchParameters.identifierList = values.data
            .toUpperCase()
            .split("\n")
            .slice(0, getDailySearchLimit());
        } else {
          searchParameters.identifierList = values.data.toUpperCase().split("\n");
        }
      }

      if (searchParameters.identifierList?.length === 1 && values.businessModel.toString() !== AmazonBusinessModel.WHOLESALE.toString()) {
        navigate(`/dp/${searchParameters.identifierList[0]}?marketplace=${searchParameters.marketplace}&amazonBusinessModel=${searchParameters.amazonBusinessModel}`);
        return;
      }
      const savePromise = saveNewSearch(searchParameters)(dispatch);
      savePromise.then((isSuccess) => {
        if (isSuccess) {
          toggle();
          props.listRef.current?.reload();
          validation.resetForm();
        }
      });
    },
  });

  const toggle = () => {
    navigate("/searches");

    preventScrollUp();

    validation.resetForm();
    setSelectedFile(undefined);
    setIsValidFile(false);
    setHasDuplicateValues(false);
  };

  const handleBusinessModelChange = useCallback(() => {
    if (validation.values.businessModel !== undefined) {
      validation.setFieldValue("marketplace", null);

      var selectedBusinessModel: AmazonBusinessModel = validation.values.businessModel as AmazonBusinessModel;
      if (selectedBusinessModel.toString() === AmazonBusinessModel.CROSS_BORDER_ARBITRAGE.toString()) {
        const nonUSoptions = getActiveUserStoresWithActiveMarketplaceOptions().filter((x) => x.marketplace !== "US");
        setMarketplaceOptions([...nonUSoptions]);
      } else {
        setMarketplaceOptions([...getActiveUserStoresWithActiveMarketplaceOptions()]);
      }
    }
  }, [validation.values.businessModel, activeUserStoreOptions, activeMarketplaceOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDataChange = useCallback(() => {
    if (validation.values.dataMode === DataMode.MANUAL_DATA) {
      let listLength = validation.values.data.split("\n").length;
      if (listLength === 1 && validation.values.data.length > 0) {
        listLength = 1;
      } else if (listLength === 1 && validation.values.data.length === 0) {
        listLength = 0;
      }

      var remaining = getDailySearchLimit() - listLength;
      if (listLength === 0) {
        setLiveLimit(getDailySearchLimit());
      }
      if (getDailySearchLimit() > DAILY_SEARCH_LIMIT) {
        setLiveLimit(remaining);
        setMaxLineLimit(DAILY_SEARCH_LIMIT);
      } else {
        setMaxLineLimit(remaining + 1);
        setLiveLimit(remaining);
      }
    }
  }, [validation.values.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnDataModeChange = useCallback(() => {
    validation.setFieldValue("data", "");
  }, [validation.values.dataMode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleBusinessModelChange();
  }, [handleBusinessModelChange]);

  useEffect(() => {
    handleDataChange();
  }, [handleDataChange]);

  useEffect(() => {
    handleOnDataModeChange();
  }, [handleOnDataModeChange]);

  const handleUploadedFile = (file: any) => {
    if (validation.values.dataMode !== DataMode.UPLOAD_DATA) {
      return; // It must only work on UPLOAD_DATA mode
    }

    switch (file.type) {
      case "text/csv":
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        if (file) {
          const reader = new FileReader();

          reader.onload = (e) => {
            if (e.target) {
              const data = e.target.result;
              const workbook = read(data, {type: "binary"});
              const firstSheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[firstSheetName];

              // Always treat first row as headers
              const excelData = utils.sheet_to_json(worksheet, {
                header: 1,
                blankrows: false, // Skip blank rows
              });

              // Ensure we have data
              if (excelData.length < 2) {
                // At least header row + 1 data row
                setIsValidFile(false);
                validation.setFieldError("data", t("Searches.Dialog.NewSearch.Validation.InvalidFile"));
                return;
              }

              const expectedHeaders = ["ASIN/EAN/UPC", "Price"];
              const fileHeaders: string[] = (excelData[0] as string[]).map((header: string) => header?.trim());
              const headersMatch = expectedHeaders.every((header) => fileHeaders.includes(header));

              if (!headersMatch) {
                setIsValidFile(false);
                validation.setFieldError("data", t("Searches.Dialog.NewSearch.Validation.InvalidFile"));
                return;
              }

              const IDENTIFIER_INDEX = fileHeaders.indexOf(expectedHeaders[0]);
              const PRICE_INDEX = fileHeaders.indexOf(expectedHeaders[1]);

              // Always start from index 1 to skip header row
              let identifierList = excelData
                .slice(1)
                .map((row: any) => row[IDENTIFIER_INDEX]?.toString().toUpperCase())
                .filter(Boolean);

              let formattedData: string;
              if (validation.values.businessModel.toString() === AmazonBusinessModel.WHOLESALE.toString()) {
                let priceList = excelData
                  .slice(1) // Skip header row
                  .map((row: any) => row[PRICE_INDEX])
                  .filter((cell: any) => cell)
                  .map((cell: any) => parseFloat(cell.toString().replace(/[^\d.]/g, "")));

                if (identifierList.length !== priceList.length) {
                  setIsValidFile(false);
                  validation.setFieldError("data", t("Searches.Dialog.NewSearch.Validation.InvalidFile"));
                  return;
                }

                formattedData = identifierList.map((identifier, index) => `${identifier},${priceList[index]}`).join("\n");
              } else {
                formattedData = identifierList.join("\n");
              }

              // Check with dataValidator
              validation.setFieldValue("data", formattedData);
              const isValid = dataValidator().isValidSync(formattedData);

              if (isValid) {
                setIsValidFile(true);
                setSelectedFile(file);
              } else {
                setIsValidFile(false);
                validation.validateField("data");
              }
            }
          };

          reader.readAsBinaryString(file);
        }
        break;

      default:
        setSelectedFile(undefined);
        setIsValidFile(false);
        validation.setFieldError("uploadData", t("Searches.Dialog.NewSearch.Validation.InvalidFile"));
        break;
    }
  };

  return (
    <>
      <Modal id="showNewSearchModal" className="modal-xl" isOpen={props.isOpen} toggle={toggle} centered={true}>
        <ModalHeader className="p-3" toggle={toggle}>
          {t("Searches.Dialog.NewSearch.Title")}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col className="d-flex justify-content-end">
                <div className="rounded-pill bg-opacity-25 bg-warning">
                  <div className="px-3 py-2 d-flex align-items-center justify-content-end">
                    <i className="mdi mdi-speedometer me-1 fs-15 text-black-50"></i>
                    <span className="fw-semibold fs-12 text-black-50">{t("Searches.Dialog.NewSearch.RemainingUsageLimit")}</span>
                    <span className="text-danger ms-1 fw-semibold">{<DisplayNumber value={liveLimit} />}</span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="g-3 mt-1">
              <Col xs={12} lg={3}>
                <Label htmlFor="searchName">{t("Searches.Dialog.NewSearch.Search")}</Label>
                <ValidatedInput validation={validation} type="text" field="searchName" maxLength={100} placeholder={t("Searches.Dialog.NewSearch.Search")} disableValidationUI />
              </Col>
              <Col xs={12} lg={3}>
                <Label htmlFor="businessModel">{t("Business Model")}</Label>
                <ValidatedSelect
                  className="w-100"
                  errorStyle="container"
                  options={businessModelSelectOptions}
                  validation={validation}
                  field="businessModel"
                  value={validation.values.businessModel.toString()}
                  placeholder={t("Select")}
                />
              </Col>
              {validation.values.businessModel !== undefined && (
                <Col xs={12} lg={3}>
                  <Label htmlFor="marketplace">{t("Marketplace")}</Label>
                  <ValidatedSelect
                    className={`w-100`}
                    errorStyle="container"
                    options={marketplaceOptions}
                    validation={validation}
                    field="marketplace"
                    value={validation.values.marketplace}
                    placeholder={t("Searches.Dialog.NewSearch.SelectMarketplace")}
                    optionStyle="marketplace"
                    valueStyle="marketplace"
                  />
                </Col>
              )}
              {validation.values.businessModel.toString() === AmazonBusinessModel.WHOLESALE.toString() && (
                <Col xs={12} lg={3}>
                  <Label htmlFor="marketplace">{t("Currency")}</Label>
                  <ValidatedSelect
                    className="w-100"
                    errorStyle="container"
                    options={ActiveMarketplaceCurrencyOptions}
                    validation={validation}
                    field="currency"
                    value={validation.values.currency}
                    placeholder={t("Select")}
                  />
                </Col>
              )}
            </Row>

            <Row className="align-items-center mt-3">
              <Col xs="auto">
                <div className="form-check form-radio-outline form-radio-success mb-3">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="identifierListChecker"
                    id="identifierListChecker"
                    value="identifierList"
                    checked={validation.values.dataMode === DataMode.MANUAL_DATA}
                    onChange={() => validation.setFieldValue("dataMode", DataMode.MANUAL_DATA)}
                  />
                  <Label className="form-check-label" for="identifierListChecker">
                    {t("Searches.Dialog.NewSearch.Radio.List", {value: validation.values.businessModel.toString() === AmazonBusinessModel.WHOLESALE.toString() ? "ASIN/EAN/UPC" : "ASIN"})}
                  </Label>
                </div>
              </Col>
              <Col className="new-search check-col-others">
                <div className="form-check form-radio-outline form-radio-success mb-3">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="uploadFileChecker"
                    id="uploadFileChecker"
                    value="uploadFile"
                    checked={validation.values.dataMode === DataMode.UPLOAD_DATA}
                    onChange={() => validation.setFieldValue("dataMode", DataMode.UPLOAD_DATA)}
                  />
                  <Label className="form-check-label" for="uploadFileChecker">
                    {t("Searches.Dialog.NewSearch.Radio.ExcelUpload")}
                  </Label>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                {validation.values.dataMode === DataMode.MANUAL_DATA && (
                  <>
                    <ValidatedLineNumberedTextarea
                      validation={validation}
                      field="data"
                      placeholder={t("Searches.Dialog.NewSearch.AddAsinInfo")}
                      maxLines={maxLineLimit}
                      invalidLineNumbers={invalidLineNumbers}
                    />
                    {validation.touched.data && validation.errors.data ? (
                      <FormFeedback type="invalid" className="new-search validation-width w-100">
                        <span className="ellipsis-single-line">{validation.errors.data.toString()} </span> <br />
                      </FormFeedback>
                    ) : null}
                  </>
                )}
                {validation.values.dataMode === DataMode.UPLOAD_DATA && (
                  <div id="uploadFile">
                    <div className="hstack justify-content-end">
                      <Link
                        to={validation.values.businessModel.toString() === AmazonBusinessModel.WHOLESALE.toString() ? "/templates/WholesaleTemplate.xlsx" : "/templates/SearchTemplate.xlsx"}
                        target="_blank"
                        className="ms-1"
                        download
                      >
                        <i className="ri-download-2-line align-bottom me-1"></i> {t("Searches.Dialog.NewSearch.DownloadSample")}
                      </Link>
                    </div>
                    <br></br>
                    <Dropzone
                      maxFiles={1}
                      onDrop={(acceptedFiles: any) => handleUploadedFile(acceptedFiles[0])}
                      accept={{
                        "text/csv": [".csv"],
                        "application/vnd.ms-excel": [".xls"],
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
                      }}
                    >
                      {({getRootProps, getInputProps}) => (
                        <div className={"dropzone dz-clickable new-search upload-height form-control form-control" + (validation.touched.data && validation.errors.data ? " is-invalid" : "")}>
                          <div className="dz-message needsclick" {...getRootProps()}>
                            <div className="mb-3">
                              <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                            </div>
                            <h4>{t("Searches.Dialog.NewSearch.UploaderTitle")}</h4>
                            <h5>({t("Searches.Dialog.NewSearch.AddAsinLimit")})</h5>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    {validation.errors.data && !isValidFile ? (
                      <FormFeedback type="invalid" className="new-search validation-width w-100">
                        <span className="ellipsis-single-line">{validation.errors.data.toString()}</span>
                      </FormFeedback>
                    ) : (
                      <div className="list-unstyled mb-0" id="file-previews">
                        {selectedFile && selectedFile !== undefined && (
                          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={selectedFile.name + "-file"}>
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col>
                                  <Link to="#" className="text-muted font-weight-bold">
                                    {selectedFile.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{formatBytes(selectedFile?.size)}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )}
                      </div>
                    )}
                    {hasDuplicateValues && (
                      <FormFeedback type="invalid" className="new-search validation-width w-100 text-warning mt-2">
                        <span className="ellipsis-single-line">{t("Searches.Dialog.NewSearch.Validation.DuplicateValue")}</span>
                      </FormFeedback>
                    )}
                  </div>
                )}
              </Col>
            </Row>
            <Dialog
              ref={exceedLimitConfirmationDialog}
              color="info"
              buttons={["yes", "no"]}
              busy={loading.update}
              iconClass="ri-alert-fill"
              message={t("Searches.Dialog.ExcelExceedLimitConfirmation.Description", {value: getDailySearchLimit()})}
              title={t("Searches.Dialog.ExcelExceedLimitConfirmation.Title")}
              onButtonClick={async (button, hide) => {
                if (button === "yes") {
                } else {
                  validation.resetForm();
                  setLimitExceeding(false);
                }
                exceedLimitConfirmationDialog.current?.hide();
              }}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button type="button" className="btn btn-light" disabled={loading.save} onClick={toggle}>
              {loading.save && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
              {t("Searches.Dialog.NewSearch.CloseButton")}
            </Button>

            <Button
              type="submit"
              className="btn btn-success"
              disabled={loading.save}
              onClick={() => {
                validation.setFieldTouched("searchName");
                validation.setFieldTouched("businessModel");
                validation.setFieldTouched("marketplace");
                validation.setFieldTouched("currency");
                validation.setFieldTouched("identifierList");
                validation.setFieldTouched("data");

                if ((validation.isValid && validation.values.dataMode !== DataMode.UPLOAD_DATA) || (validation.values.dataMode === DataMode.UPLOAD_DATA && isValidFile)) {
                  validation.handleSubmit();
                }
              }}
            >
              {loading.save && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
              {t("Searches.Dialog.NewSearch.SubmitButton")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default NewSearch;

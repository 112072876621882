import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody, Col, Row, Button, ModalFooter, Form, Label, Spinner} from "reactstrap";
import {memo, RefObject} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createUserFavoriteList, editFavoriteList} from "slices/search-result/thunk";
import {createTypedModal} from "helpers/modal_helpers";
import {EditUserFavoriteListCommand, SaveUserFavoriteListCommand, UserFavorite} from "models/user_favorites";
import {FavoriteListRef} from "./AddToFavorites";
import {SearchResultSlice} from "slices/search-result/selector";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";

interface ModalData {
  marketplaceTarget: string;
  favorite?: UserFavorite;
}

export const CreateOrEditFavoriteListModal = createTypedModal<ModalData>("create_or_edit_favorite_list");

interface CreateOrEditFavoriteListProps {
  listRef?: RefObject<FavoriteListRef>;
}
const CreateOrEditFavoriteList = (props: CreateOrEditFavoriteListProps) => {
  const {open, data} = CreateOrEditFavoriteListModal.useModal();
  if (!open) return null;

  return <CreateOrEditFavoriteListContent data={data} listRef={props.listRef} />;
};

const CreateOrEditFavoriteListContent = ({data, listRef}: {data: ModalData | undefined, listRef?: RefObject<FavoriteListRef>}) => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const {loading} = useSelector(SearchResultSlice);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      favoriteListName: data?.favorite?.name,
      description: data?.favorite?.description,
      marketplace: data?.marketplaceTarget,
    } as SaveUserFavoriteListCommand,
    validationSchema: Yup.object<SaveUserFavoriteListCommand>().shape({
      favoriteListName: Yup.string().required(t("Favorites.Validation.ListName")),
      description: Yup.string().notRequired(),
    }),
    onSubmit: (values: SaveUserFavoriteListCommand) => {
      if (data?.favorite) {
        // update
        const payload: EditUserFavoriteListCommand = {
          userFavoriteId: data?.favorite.userFavoriteId,
          name: values.favoriteListName,
          description: values.description,
        };
        const editPromise = editFavoriteList(payload)(dispatch);
        editPromise.then((success) => {
          if (success) {
            validation.resetForm();
            toggle();
          }
        });
      } else {
        // create
        const payload: SaveUserFavoriteListCommand = {
          marketplace: data?.marketplaceTarget!,
          favoriteListName: values.favoriteListName,
          description: values.description,
        };

        const createPromise = createUserFavoriteList(payload)(dispatch);
        createPromise.then((success) => {
          if (success) {
            listRef?.current?.reload();
            toggle();
          }
        });
      }
    },
  });

  const toggle = () => {
    CreateOrEditFavoriteListModal.close();
    validation.resetForm();
  };

  return (
    <>
      <Modal backdrop="static" id="reportItemModal" isOpen={true} toggle={toggle} fade={true} centered={true}>
        <div className="placeholder-glow">
          <ModalHeader className="p-3" toggle={toggle}>
            {data?.favorite ? t("Edit") : t("SearchResults.Dialog.AddToFavorites.Dialog.Create.Title")}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={validation.handleSubmit}>
              <div className="d-flex justify-content-center flex-column gap-3">
                <Row>
                  <Col xs="12">
                    <Label htmlFor="favoriteListName">{t("SearchResults.Dialog.AddToFavorites.Label.ListName")}</Label>
                    <ValidatedInput
                      validation={validation}
                      type="text"
                      field="favoriteListName"
                      placeholder={t("SearchResults.Dialog.AddToFavorites.Label.ListName")}
                      maxLength={100}
                      disableValidationUI
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <Label htmlFor="description">{t("SearchResults.Dialog.AddToFavorites.Label.Description")}</Label>
                    <Label className="text-muted ms-1 fw-normal small">{t("Optional")}</Label>
                    <ValidatedInput
                      validation={validation}
                      type="textarea"
                      field="description"
                      placeholder={t("SearchResults.Dialog.AddToFavorites.Label.Description")}
                      maxLength={100}
                      disableValidationUI
                    />
                  </Col>
                </Row>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn btn-light" onClick={toggle} disabled={loading.save || loading.editFavoriteList}>
                {t("Close")}
              </Button>
              <Button
                type="submit"
                className="btn btn-success"
                disabled={loading.save || loading.editFavoriteList}
                onClick={() => {
                  if (validation.isValid) {
                    validation.handleSubmit();
                  } else {
                    Object.keys(validation.errors).forEach((key) => {
                      validation.setFieldTouched(key, true);
                    });
                  }
                }}
              >
                {(loading.save || loading.editFavoriteList) && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
                {data?.favorite ? t("Save") : t("Create")}
              </Button>
            </div>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

export default memo(CreateOrEditFavoriteList);

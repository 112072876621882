import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import ProfitCalculatorContent, {ProfitCalculatorModal} from "./Modals/ProfitCalculatorModal";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {ShipmentSlice} from "slices/shipment/selector";
import {ColumnDef} from "@tanstack/react-table";
import {UserShipmentItem} from "models/user_shipment_item";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {dayDifference, getToday} from "helpers/utilities";
import {useImmer} from "use-immer";
import {PagerQuery} from "helpers/types";
import {Link} from "react-router-dom";
import {AmazonMarketplace} from "helpers/marketplace_helper";
import DisplayPrice from "Components/Common/DisplayPrice";
import DisplayNumber from "Components/Common/DisplayNumber";
import errorImage from "assets/images/svg/product.svg";
import CopyWidget from "Components/Common/CopyWidget";
import SuccessRateCalculator from "Components/Common/Calculator/SuccessRateCalculator";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import {ProfitCalculatorProps} from "Components/Common/Calculator/_ProfitCalculator";
import {AmazonHelper} from "helpers/amazon_helper";

type ProductStatusFilter = "All" | "InStock" | "OutOfStock";
interface ProductListProps {
  sourceMarketplace?: AmazonMarketplace;
  destinationMarketplace?: AmazonMarketplace;
}
const ProductList = ({sourceMarketplace, destinationMarketplace}: ProductListProps) => {
  const {t} = useTranslation();
  const tableRef = useRef<DataTableRef>(null);
  const [tableFilteredData, setTableFilteredData] = useState<UserShipmentItem[]>([]);
  const [tableOriginalData, setTableOriginalData] = useState<UserShipmentItem[]>([]);
  const [dataLength, setDataLength] = useState(0);
  const [selectedProductStatusFilter, setSelectedProductStatusFilter] = useState<ProductStatusFilter>("All");
  const noSourceDialogRef = useRef<DialogRef>(null);
  const [query] = useImmer<PagerQuery>({
    page: 1,
    pageSize: 999,
  });

  const {currentUserShipment, loading} = useSelector(ShipmentSlice);

  useEffect(() => {
    if (currentUserShipment) {
      setTableOriginalData(currentUserShipment.userShipmentItems);
      setTableFilteredData(currentUserShipment.userShipmentItems);
    }
  }, [currentUserShipment]); // eslint-disable-line

  const handleProfitCalculatorModal = (item: UserShipmentItem) => {
    const fbaFeeTax = AmazonHelper.calculateFBAFeeTax(item.fbaFee, currentUserShipment.userShipmentSetting.fbaFeeTaxRate);
    const referralFeeTax = AmazonHelper.calculateReferralFeeTax(item.referralFee, currentUserShipment.userShipmentSetting.referralFeeTaxRate);
    const bankTransferCommission = AmazonHelper.calculateBankTransferCommission(
      item.expectedSalesProceeds,
      item.fbaFee,
      fbaFeeTax ?? null,
      item.referralFee,
      referralFeeTax ?? null,
      null,
      currentUserShipment.userShipmentSetting.bankTransferCommissionRate,
    );
    const estimatedRefund = AmazonHelper.calculateEstimatedRefund(
      item.expectedSalesProceeds,
      item.fbaFee,
      fbaFeeTax ?? null,
      item.referralFee,
      referralFeeTax ?? null,
      null,
      currentUserShipment.userShipmentSetting.estimatedRefundRate,
    );
    const currencyExchangeLoss = AmazonHelper.calculateCurrencyExchangeLoss(
      item.expectedSalesProceeds,
      item.fbaFee,
      fbaFeeTax ?? null,
      item.referralFee,
      referralFeeTax ?? null,
      null,
      currentUserShipment.userShipmentSetting.currencyExchangeLossRate,
    );
    const payload: ProfitCalculatorProps = {
      title: "",
      amazonBusinessModel: destinationMarketplace?.marketplace !== "US" ? AmazonBusinessModel.CROSS_BORDER_ARBITRAGE : AmazonBusinessModel.WHOLESALE,
      buyBoxType: undefined,
      offerCount: undefined,
      userSearchProductId: undefined,
      destinationMarketplace: destinationMarketplace,
      sourceMarketplace: sourceMarketplace,
      itemProfitAnalysis: {
        userSearchProductProfitAnalysisId: "shipmentProfitAnalysis",
        userSearchProductId: "shipmentSearch",
        purchasePrice: item.purchasePrice * (item.userInventory.exchangeRateTarget ?? 1),
        purchasePriceSource: item.purchasePrice,
        tax: item.tax * (item.userInventory.exchangeRateTarget ?? 1),
        taxSource: item.tax,
        shippingPrice: item.shippingPrice * (item.userInventory.exchangeRateTarget ?? 1),
        shippingPriceSource: item.shippingPrice,
        shippingPriceDS: undefined,
        shippingPriceSourceDS: undefined,
        totalCost: item.expectedTotalCost,
        totalCostSource: item.expectedTotalCost * (item.userInventory.exchangeRateTarget ?? 1),
        totalCostDS: undefined,
        totalCostSourceDS: undefined,
        fbaFee: item.fbaFee,
        fbaFeeTaxRate: currentUserShipment.userShipmentSetting.fbaFeeTaxRate,
        fbaFeeTax: fbaFeeTax,
        referralFee: item.referralFee,
        referralFeeTaxRate: currentUserShipment.userShipmentSetting.referralFeeTaxRate,
        referralFeeTax: referralFeeTax,
        bankTransferCommissionRate: currentUserShipment.userShipmentSetting.bankTransferCommissionRate,
        bankTransferCommission: bankTransferCommission,
        bankTransferCommissionDS: undefined,
        estimatedRefundRate: currentUserShipment.userShipmentSetting.estimatedRefundRate,
        estimatedRefund: estimatedRefund,
        estimatedRefundDS: undefined,
        currencyExchangeLossRate: currentUserShipment.userShipmentSetting.currencyExchangeLossRate,
        currencyExchangeLoss: currencyExchangeLoss,
        currencyExchangeLossDS: undefined,
        otherFees: item.otherFees,
        otherFeesDS: undefined,
        totalFees: item.totalFees,
        totalFeesDS: undefined,
        overallCost: item.expectedOverallCost,
        overallCostDS: undefined,
        sellPrice: item.expectedSellPrice,
        currentPrice: item.currentBuyBoxPrice,
        currentShippingPrice: item.shippingPrice,
        marketplaceTaxRate: currentUserShipment.userShipmentSetting.marketplaceTaxRate,
        marketplaceTax: item.marketplaceTax,
        marketplaceTaxExceptionRate: currentUserShipment.userShipmentSetting.marketplaceTaxExceptionRate,
        marketplaceTaxException: item.marketplaceTaxException,
        profit: item.expectedProfit,
        profitSource: item.expectedProfit,
        profitDS: undefined,
        profitSourceDS: undefined,
        margin: item.expectedMargin,
        marginDS: undefined,
        roi: item.expectedROI,
        roids: undefined,
        exchangeRateSource: item.userInventory.exchangeRateSource,
        exchangeRateTarget: item.userInventory.exchangeRateTarget,
      },
    };

    ProfitCalculatorModal.open({...payload});
  };
  const columns = useMemo<ColumnDef<UserShipmentItem, any>[]>(
    () => [
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.Product"),
        size: 100,
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          const imagePath = `https://m.media-amazon.com/images/I/${row.userInventory.imageSmall}`;

          const handleNoSourceClick = (e: React.MouseEvent) => {
            const LEFT_BUTTON_CLICK = 0;
            const MIDDLE_BUTTON_CLICK = 1;
            if (e.button === MIDDLE_BUTTON_CLICK || e.button === LEFT_BUTTON_CLICK) {
              e.preventDefault();
              noSourceDialogRef.current?.show();
            }
          };

          return (
            <Row className="align-items-center" style={{minWidth: "250px"}}>
              <Col xs={"auto"}>
                <div className="avatar-sm rounded-circle flex-shrink-0 overflow-hidden">
                  <img
                    className="rounded w-100"
                    src={imagePath}
                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                      e.currentTarget.onerror = null;
                      e.currentTarget.src = errorImage;
                    }}
                    alt=""
                  />
                </div>
              </Col>
              <Col xs={"auto"}>
                <div className="d-flex align-items-center">
                  {row.userSearchId !== undefined && row.userSearchId !== null ? (
                    <>
                      <CopyWidget
                        text={row.userInventory.asin}
                        index={row.userInventory.asin}
                        asLink={true}
                        customLink={`/searches/search-results/${row.userSearchId}?asin=${row.userInventory.asin}`}
                      />
                      <Link to={`https://www.amazon.${destinationMarketplace?.domain}/dp/${row.userInventory.asin}?th=1&psc=1`} target="_blank" className="ms-1">
                        <i className="bx bxl-amazon label-icon align-middle fs-18"></i>
                      </Link>
                      <Button
                        id={`ProfitCalculator-${row.userShipmentItemId}`}
                        type="button"
                        color="link"
                        className="label-icon p-0 ms-1 d-flex align-items-center"
                        onClick={() => handleProfitCalculatorModal(row)}
                      >
                        <i className="ri ri-calculator-line fs-13" />
                      </Button>
                      <DefaultUncontrolledTooltip target={`ProfitCalculator-${row.userShipmentItemId}`}>
                        {t("Shipments.ShippedOrCompleted.ProductList.Tooltip.ProfitCalculator")}
                      </DefaultUncontrolledTooltip>
                    </>
                  ) : (
                    <>
                      <CopyWidget text={row.userInventory.asin} index={row.userInventory.asin} asLink={true} customClick={handleNoSourceClick} hideLinkIcon={true} />
                      <Link to={`https://www.amazon.${destinationMarketplace?.domain}/dp/${row.userInventory.asin}?th=1&psc=1`} target="_blank" className="ms-1">
                        <i className="bx bxl-amazon label-icon align-middle fs-18"></i>
                      </Link>
                    </>
                  )}
                </div>
                <div className="d-flex align-items-center">
                  <CopyWidget text={row.userInventory.sku} index={row.userInventory.sku} />
                </div>
              </Col>
            </Row>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.Total"),
        size: 50,
        accessorFn: (row) => row.totalItems,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          return <>{row.totalItems}</>;
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.Sold"),
        size: 50,
        accessorFn: (row) => row.soldItems,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          return <>{row.soldItems}</>;
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.Left"),
        size: 50,
        accessorFn: (row) => row.totalItems - row.soldItems,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          return (
            <>
              {row.totalItems - row.soldItems}
              {row.totalItems - row.soldItems === 0 && (
                <>
                  <i id={`AllItemsSoldOut-${row.userShipmentItemId}`} className="text-success mdi mdi-check-bold ms-1 fs-14"></i>
                  <DefaultUncontrolledTooltip target={`AllItemsSoldOut-${row.userShipmentItemId}`}>{t("Shipments.ShippedOrCompleted.ProductList.Tooltip.SoldOut")}</DefaultUncontrolledTooltip>
                </>
              )}
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.ActualUnitCost"),
        size: 120,
        accessorFn: (row) => row.actualTotalCostPerItemTarget,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;

          return (
            <>
              <span className="text-muted">
                <DisplayPrice source={sourceMarketplace?.currency} value={row.actualTotalCostPerItem} decimals={2} notation="decimal" />
              </span>
              <h5 className="fs-14 my-1 text-nowrap">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.actualTotalCostPerItemTarget} decimals={2} notation="decimal" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.InitialBuyBoxPriceAndCurrentBuyBoxPrice"),
        size: 120,
        accessorFn: (row) => row.initialBuyBoxPrice,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          return (
            <>
              <span className="text-muted">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.initialBuyBoxPrice} decimals={2} notation="decimal" />
              </span>
              <h5 className="fs-14 my-1 text-nowrap">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.currentBuyBoxPrice} decimals={2} notation="decimal" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.ExpectedSellPriceAndAvgSellPrice"),
        size: 120,
        accessorFn: (row) => row.expectedSellPrice,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          return (
            <>
              <span className="text-muted">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.expectedSellPrice} decimals={2} notation="decimal" />
              </span>
              <h5 className="fs-14 my-1 text-nowrap">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.actualSellPrice} decimals={2} notation="decimal" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.ExpectedProfitAndAvgProfit"),
        size: 120,
        accessorFn: (row) => row.expectedProfitPerItem * row.soldItems,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          const expectedProfit = row.soldItems === 0 ? row.expectedProfitPerItem : row.expectedProfitPerItem!;
          const avgProfit = row.soldItems === 0 ? row.expectedProfitPerItem : row.actualProfitPerItem!;
          return (
            <>
              <span className="text-muted">
                <DisplayPrice source={destinationMarketplace?.currency} value={expectedProfit} decimals={2} notation="decimal" />
              </span>
              <h5 className="fs-14 my-1 text-nowrap">
                <DisplayPrice source={destinationMarketplace?.currency} value={avgProfit} decimals={2} notation="decimal" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.ExpectedRoiMarginAndActualRoiMargin"),
        size: 120,
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          return (
            <>
              <span className="text-muted text-nowrap d-flex">
                <DisplayNumber value={row.expectedROI} decimals={0} prefix={`%`} suffix="" />
                <i className="mdi mdi-slash-forward"></i>
                <DisplayNumber value={row.expectedMargin} decimals={0} prefix={`%`} suffix="" />
              </span>
              <h5 className="fs-14 my-1 text-nowrap d-flex">
                <DisplayNumber value={row.actualROI} decimals={0} prefix={`%`} suffix="" />
                <i className="mdi mdi-slash-forward"></i>
                <DisplayNumber value={row.actualMargin} decimals={0} prefix={"%"} suffix="" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.InitialFbaSellerAndCurrentFbaSeller"),
        size: 120,
        accessorFn: (row) => row.initialFBASellerCount,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          return (
            <>
              <span className="text-muted">
                <DisplayNumber value={row.initialFBASellerCount} />
              </span>
              <h5 className="fs-14 my-1">
                <DisplayNumber value={row.currentFBASellerCount} />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.InitialBsrAndCurrentBsr"),
        size: 120,
        accessorFn: (row) => row.initialBSR,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          return (
            <>
              <span className="text-muted">
                <DisplayNumber value={row.initialBSR} />
              </span>
              <h5 className="fs-14 my-1 text-nowrap">
                <DisplayNumber value={row.currentBSR} />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.Speed"),
        size: 100,
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          const {currentUserShipment} = useSelector(ShipmentSlice); // eslint-disable-line
          const [speed, setSpeed] = useState<number>(0); // eslint-disable-line

          // eslint-disable-next-line
          useEffect(() => {
            const completedDate = row.completedDate ? new Date(row.completedDate) : getToday().toDate();
            const shipDate = currentUserShipment?.shipDate ? new Date(currentUserShipment?.shipDate) : getToday().toDate();
            let dayElapsed = dayDifference(completedDate, shipDate);
            setSpeed(row.soldItems / dayElapsed);
          }, [currentUserShipment]); // eslint-disable-line

          return (
            <>
              <h5 className="fs-14 my-1 text-nowrap mb-0">
                <i className="text-info mdi mdi-rocket-launch me-1 fs-14"></i>
                {speed.toFixed(2)}
              </h5>
              <span className="text-muted fs-12 text-nowrap">
                {t("items")}
                <i className="mdi mdi-slash-forward"></i>
                {t("day")}
              </span>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.SuccessRate"),
        size: 150,
        accessorFn: (row) => SuccessRateCalculator(row.actualProfitPerItem, row.expectedProfitPerItem),
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          const rate = SuccessRateCalculator(row.actualProfitPerItem, row.expectedProfitPerItem);

          return (
            <h5 className="fs-14 my-1 text-nowrap align-items-center d-flex">
              <i className="text-secondary mdi mdi-trophy me-1 fs-14"></i>
              <DisplayNumber value={rate} decimals={2} suffix="%" />
            </h5>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, destinationMarketplace],
  );

  const handleFilterChange = useCallback(() => {
    const filterByProductStatus = tableOriginalData.filter((item) => {
      switch (selectedProductStatusFilter) {
        case "All":
          return true;
        case "InStock":
          return item.totalItems - item.soldItems !== 0 ? true : false;
        case "OutOfStock":
          return item.totalItems - item.soldItems === 0 ? true : false;
        default:
          return true;
      }
    });

    setDataLength(filterByProductStatus.length);
    const start = (query.page - 1) * query.pageSize;
    const end = start + query.pageSize;
    setTableFilteredData(filterByProductStatus.slice(start, end));
  }, [tableOriginalData, selectedProductStatusFilter, query]);

  useEffect(() => {
    handleFilterChange();
  }, [handleFilterChange]);

  return (
    <Row>
      <Col>
        <ProfitCalculatorContent />
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs={12} md="auto" className="d-flex justify-content-lg-center align-items-lg-center mb-2 mb-md-0">
                <h5 className="mb-0 border-0 align-items-center d-flex">{t("Shipments.ShippedOrCompleted.ProductList.Title")}</h5>
              </Col>
              <Col md></Col>
              <Col xs={12} md="auto">
                <div className="d-flex justify-content-end gap-1">
                  <Button type="button" color="soft-secondary" size="sm" onClick={() => setSelectedProductStatusFilter("All")} active={selectedProductStatusFilter === "All"}>
                    {t("Shipments.ShippedOrCompleted.ProductList.Button.All")}
                  </Button>
                  <Button type="button" color="soft-secondary" size="sm" onClick={() => setSelectedProductStatusFilter("InStock")} active={selectedProductStatusFilter === "InStock"}>
                    {t("Shipments.ShippedOrCompleted.ProductList.Button.InStock")}
                  </Button>
                  <Button type="button" color="soft-secondary" size="sm" onClick={() => setSelectedProductStatusFilter("OutOfStock")} active={selectedProductStatusFilter === "OutOfStock"}>
                    {t("Shipments.ShippedOrCompleted.ProductList.Button.OutOfStock")}
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <DataTable
              className="align-middle table-nowrap table table-hover"
              trClass="text-start"
              tdClass=""
              ref={tableRef}
              busy={loading.list}
              columns={columns}
              data={tableFilteredData}
              totalDataLength={dataLength}
              pagination={{
                pageIndex: query.page - 1,
                pageSize: query.pageSize,
              }}
              hovered
            />
          </CardBody>

          <Dialog
            ref={noSourceDialogRef}
            color="info"
            buttons={["ok"]}
            busy={false}
            iconClass="mdi mdi-chart-timeline-variant-shimmer"
            message={t("Shipments.ShipmentItems.Dialog.NoSource.Description")}
            title={t("Shipments.ShipmentItems.Dialog.NoSource.Title")}
            onButtonClick={() => {
              noSourceDialogRef.current?.hide();
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default ProductList;

import {SorterQuery} from "helpers/types";
import {OrderStatus} from "models/enums/order_status";
import {TagColor} from "models/enums/tag_color";
import {UserLimitType} from "models/enums/user_limit_type";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {UserStatus} from "models/user";
import {useTranslation} from "react-i18next";

export type SelectOptionsType = {
  value: any;
  label: string;
  disabled?: boolean;
  isDivider?: boolean;
};

export interface MarketplaceSelectOption extends SelectOptionsType {
  marketplace: string;
}
export interface UserSelectOption extends SelectOptionsType {
  avatar?: string;
  email?: string;
}
export interface SortSelectOption extends Omit<SelectOptionsType, "value"> {
  key: string;
  value: SorterQuery;
}

export type MultiSelectTranslations = {
  allItemsAreSelected: string;
  clearSearch: string;
  clearSelected: string;
  noOptions: string;
  search: string;
  selectAll: string;
  selectAllFiltered: string;
  selectSomeItems: string;
  create: string;
};

const useSelectOptions = () => {
  const {t} = useTranslation();

  const searchStatusSelectOptions = [
    {value: "Completed", label: t("Completed")},
    {value: "InProgress", label: t("In Progress")},
    {value: "Initial", label: t("Initial")},
    {value: "Error", label: t("Error")},
  ];
  const searchTypeSelectOptions = [
    {value: "single-search", label: t("Single Search")},
    {value: "bulk-search", label: t("Bulk Search")},
  ];

  const scanAndSaveStatusSelectOptions = [
    {value: "Undefined", label: t("ScanAndSave.Status.Undefined")},
    {value: "Waiting", label: t("ScanAndSave.Status.Waiting")},
    {value: "Planned", label: t("ScanAndSave.Status.Planned")},
    {value: "Working", label: t("ScanAndSave.Status.Working")},
    {value: "Done", label: t("ScanAndSave.Status.Done")},
    {value: "Canceled", label: t("ScanAndSave.Status.Canceled")},
  ];
  const decisionSelectOptions = [
    {value: "Sellable", label: t("Sellable")},
    {value: "Recheck", label: t("Recheck")},
    {value: "Not Sellable", label: t("Not Sellable")},
  ];
  const amazonCatalogStatusSelectOptions = [
    {
      value: "product-not-exist-in-amazon-com",
      label: t("Product not exist in Amazon.com"),
    },
    {
      value: "product-not-exist-in-target-marketplace",
      label: t("Product not exist in target marketplace"),
    },
    {
      value: "product-not-available-in-amazon-com",
      label: t("Product not available in Amazon.com"),
    },
    {
      value: "product-not-available-in-target-marketplace",
      label: t("Product not available in target marketplace"),
    },
  ];
  const commonProductSelectOptions = [
    {value: "all", label: t("All")},
    {value: "yes", label: t("Yes")},
    {value: "no", label: t("No")},
  ];
  const restrictionCheckSelectOptions = [
    {value: "all", label: t("All")},
    {value: "allowed", label: t("SearchResults.Filters.Allowed")},
    {value: "approval_needed", label: t("SearchResults.Filters.ApprovalNeeded")},
  ];
  const shipmentStatusSelectOptions = [
    {value: "Draft", label: t("Draft")},
    {value: "Shipped", label: t("Shipped")},
    {value: "Completed", label: t("Completed")},
  ];
  const dateRangeSelectOptions = [
    {value: "all", label: t("SearchResults.Filters.All")},
    {value: "1-month", label: t("SearchResults.Filters.1-Month")},
    {value: "3-months", label: t("SearchResults.Filters.3-Months")},
    {value: "6-months", label: t("SearchResults.Filters.6-Months")},
    {value: "1-year", label: t("SearchResults.Filters.1-Year")},
    {value: "more-than-1-year", label: t("SearchResults.Filters.MoreThanOneYear")},
  ];
  const competitionSelectOptions = [
    {value: "Weak", label: t("Weak")},
    {value: "Medium", label: t("Medium")},
    {value: "Strong", label: t("Strong")},
  ];
  const priceAnalysisResultSelectOptions = [
    {value: "Stable", label: t("SearchResults.Filters.Stable")},
    {value: "Not Stable", label: t("SearchResults.Filters.NotStable")},
    {value: "Recheck", label: t("SearchResults.Filters.Recheck")},
  ];
  const isReviewedSelectOptions = [
    {value: "all", label: t("All")},
    {value: "reviewed", label: t("Reviewed")},
    {value: "not-reviewed", label: t("Not Reviewed")},
  ];

  const supportIssueSelectOptions = [
    {value: "ScanAndSave", label: t("Account.Support.Issue.ScanAndSave")},
    {value: "ProductAnalysis", label: t("Account.Support.Issue.ProductAnalysis")},
    {value: "Orders", label: t("Account.Support.Issue.Orders")},
    {value: "Inventory", label: t("Account.Support.Issue.Inventory")},
    {value: "Shipments", label: t("Account.Support.Issue.Shipments")},
    {value: "Reports", label: t("Account.Support.Issue.Reports")},
    {value: "Stores", label: t("Account.Support.Issue.Stores")},
    {value: "Account", label: t("Account.Support.Issue.Account")},
    {value: "Limits", label: t("Account.Support.Issue.Limits")},
    {value: "Subscriptions", label: t("Account.Support.Issue.Subscriptions")},
    {value: "Payments", label: t("Account.Support.Issue.Payments")},
    {value: "NotListed", label: t("Account.Support.Issue.NotListed")},
  ];
  const supportIssuePrioritySelectOptions = [
    {value: "Low", label: t("Account.Support.Priority.Low")},
    {value: "Medium", label: t("Account.Support.Priority.Medium")},
    {value: "High", label: t("Account.Support.Priority.High")},
  ];
  const serviceSelectOptions = [
    {value: "spapi_search_source", label: "SPAPI Search Source"},
    {value: "spapi_search_target", label: "SPAPI Search Target"},
    {value: "product_offers_source", label: "Product Offers Source"},
    {value: "product_offers_target", label: "Product Offers Target"},
    {value: "fees_estimate", label: "Fees Estimate"},
    {value: "variations", label: "Variations"},
    {value: "category", label: "Category"},
    {value: "connect_api", label: "Connect Api"},
    {value: "sale_count", label: "Sale Count"},
    {value: "fba_eligibility", label: "FBA Eligibility"},
    {value: "restriction_check", label: "Restriction Check"},
    {value: "fast_analyze", label: "Fast Analyze"},
    {value: "decision", label: "Decision"},
  ];
  const microserviceSelectOptions = [
    {value: "SellThis.Services.Identity", label: "SellThis.Services.Identity"},
    {value: "SellThis.Services.Admin", label: "SellThis.Services.Admin"},
    {value: "SellThis.Services.Amazon", label: "SellThis.Services.Amazon"},
    {value: "SellThis.Services.Payment", label: "SellThis.Services.Payment"},
    {value: "SellThis.Services.Notification", label: "SellThis.Services.Notification"},
    {value: "SellThis.Services.Search", label: "SellThis.Services.Search"},
    {value: "SellThis.Services.Crawler", label: "SellThis.Services.Crawler"},
  ];
  const logOperationSelectOptions = [
    {value: "/GetUserWebNotifications", label: "/GetUserWebNotifications"},
    {value: "/GetUserRemainingLimit", label: "/GetUserRemainingLimit"},
    {value: "/GetUserSubscription", label: "/GetUserSubscription"},
    {value: "/GetUserSearchResults", label: "/GetUserSearchResults"},
    {value: "/GetUserSearches", label: "/GetUserSearches"},
    {value: "/GetUserOrders", label: "/GetUserOrders"},
    {value: "/GetScanAndSave", label: "/GetScanAndSave"},
    {value: "/GetUserInventories", label: "/GetUserInventories"},
    {value: "/GetUserShipments", label: "/GetUserShipments"},
    {value: "/GetUserShipmentDetails", label: "/GetUserShipmentDetails"},
    {value: "/GetSystemMonitoringAsync", label: "/GetSystemMonitoringAsync"},
    {value: "/Login", label: "/Login"},
    {value: "/UpdateUIPreferences", label: "/UpdateUIPreferences"},
    {value: "/SaveUserNewSearch", label: "/SaveUserNewSearch"},
    {value: "/GetSearches", label: "/GetSearches"},
    {value: "/DeleteSearch", label: "/DeleteSearch"},
    {value: "/GetTags", label: "/GetTags"},
    {value: "/GetUsers", label: "/GetUsers"},
    {value: "/GetProductDetails", label: "/GetProductDetails"},
    {value: "/MarkReviewed", label: "/MarkReviewed"},
    {value: "/AddToUserFavoriteList", label: "/AddToUserFavoriteList"},
    {value: "/GetUserOrderDetails", label: "/GetUserOrderDetails"},
    {value: "/AddUserShipmentItemAsync", label: "/AddUserShipmentItemAsync"},
    {value: "/UpdateAnalyzeItem", label: "/UpdateAnalyzeItem"},
    {value: "/SubmitForAnalysis", label: "/SubmitForAnalysis"},
    {value: "/SaveNewScan", label: "/SaveNewScan"},
    {value: "/GetUserLimitUsageHistory", label: "/GetUserLimitUsageHistory"},
    {value: "/ClearTopicsAsync", label: "/ClearTopicsAsync"},
    {value: "/GetUserLogs", label: "/GetUserLogs"},
    {value: "/GetUserPaymentMethods", label: "/GetUserPaymentMethods"},
    {value: "/MarkAllAsRead", label: "/MarkAllAsRead"},
    {value: "/DeleteScan", label: "/DeleteScan"},
    {value: "/GenerateUserLoginToken", label: "/GenerateUserLoginToken"},
    {value: "/SearchQuery", label: "/SearchQuery"},
    {value: "/UpdateExchangeRates", label: "/UpdateExchangeRates"},
    {value: "/RegisterUser", label: "/RegisterUser"},
    {value: "/GetSystemMonitoringDetails", label: "/GetSystemMonitoringDetails"},
    {value: "/GetUserPayments", label: "/GetUserPayments"},
    {value: "/UpdateCostAndFeeItem", label: "/UpdateCostAndFeeItem"},
    {value: "/RemoveFromUserFavoriteList", label: "/RemoveFromUserFavoriteList"},
    {value: "/DeleteTag", label: "/DeleteTag"},
    {value: "/InitializeSubscription", label: "/InitializeSubscription"},
    {value: "/ReAnalyzeSearch", label: "/ReAnalyzeSearch"},
    {value: "/SaveUserFavoriteList", label: "/SaveUserFavoriteList"},
    {value: "/ReadUserWebNotification", label: "/ReadUserWebNotification"},
    {value: "/DeleteUserFavoriteList", label: "/DeleteUserFavoriteList"},
    {value: "/Export", label: "/Export"},
    {value: "/UndoDeletedSearch", label: "/UndoDeletedSearch"},
    {value: "/UpdateUserInventoryCost", label: "/UpdateUserInventoryCost"},
    {value: "/ResetDefaultCostAndFeeItem", label: "/ResetDefaultCostAndFeeItem"},
    {value: "/UpdateUserOrderCost", label: "/UpdateUserOrderCost"},
    {value: "/ResetDefaultAnalyzeItem", label: "/ResetDefaultAnalyzeItem"},
    {value: "/AnalyzeShipment", label: "/AnalyzeShipment"},
    {value: "/ChangeSubscription", label: "/ChangeSubscription"},
    {value: "/CreateTag", label: "/CreateTag"},
    {value: "/UpdateGeneralInformation", label: "/UpdateGeneralInformation"},
    {value: "/CancelUserSubscription", label: "/CancelUserSubscription"},
    {value: "/DeleteAnalyzeItem", label: "/DeleteAnalyzeItem"},
    {value: "/DeleteUserShipmentItemAsync", label: "/DeleteUserShipmentItemAsync"},
    {value: "/ExportAsin", label: "/ExportAsin"},
    {value: "/RefreshUserShipmentItemAsync", label: "/RefreshUserShipmentItemAsync"},
    {value: "/SaveAnalyzeItem", label: "/SaveAnalyzeItem"},
    {value: "/AddToAmazonInventoryAsync", label: "/AddToAmazonInventoryAsync"},
    {value: "/DeleteCostAndFeeItem", label: "/DeleteCostAndFeeItem"},
    {value: "/DeleteSubUser", label: "/DeleteSubUser"},
    {value: "/DeleteUserTagItem", label: "/DeleteUserTagItem"},
    {value: "/DisconnectUserStoreAsync", label: "/DisconnectUserStoreAsync"},
    {value: "/DownloadUserInventories", label: "/DownloadUserInventories"},
    {value: "/SaveCostAndFeeItem", label: "/SaveCostAndFeeItem"},
  ];

  const subscriptionStatusSelectOptions = [
    {
      label: t("Admin.Users.Filters.Dropdown.SubscriptionStatus.All"),
      value: "all",
    },
    {
      label: t("Admin.Users.Filters.Dropdown.SubscriptionStatus.TrialNotStarted"),
      value: "trial-not-started",
    },
    {
      label: t("Admin.Users.Filters.Dropdown.SubscriptionStatus.Trial"),
      value: "trial",
    },
    {
      label: t("Admin.Users.Filters.Dropdown.SubscriptionStatus.Active"),
      value: "active",
    },
    {
      label: t("Admin.Users.Filters.Dropdown.SubscriptionStatus.Canceled"),
      value: "canceled",
    },
    {
      label: t("Admin.Users.Filters.Dropdown.SubscriptionStatus.PaymentFailed"),
      value: "payment-failed",
    },
    {
      label: t("Admin.Users.Filters.Dropdown.SubscriptionStatus.NoSubscription"),
      value: "no-subscription",
    },
  ];
  const storeStatusSelectOptions = [
    {
      label: t("Admin.Users.Filters.Dropdown.StoreStatus.All"),
      value: "all",
    },
    {
      label: t("Admin.Users.Filters.Dropdown.StoreStatus.Connected"),
      value: "connected",
    },
    {
      label: t("Admin.Users.Filters.Dropdown.StoreStatus.NotConnected"),
      value: "not-connected",
    },
  ];
  const userStatusSelectOptions = [
    {
      label: t("Admin.Users.Filters.Dropdown.UserStatus.Active"),
      value: UserStatus.ACTIVE.toString(),
    },
    {
      label: t("Admin.Users.Filters.Dropdown.UserStatus.Banned"),
      value: UserStatus.BANNED.toString(),
    },
    {
      label: t("Admin.Users.Filters.Dropdown.UserStatus.PasswordLocked"),
      value: UserStatus.PASSWORD_LOCKED.toString(),
    },
    {
      label: t("Admin.Users.Filters.Dropdown.UserStatus.WaitingForCreditCardConfirmation"),
      value: UserStatus.WAITING_FOR_CREDIT_CARD_CONFIRMATION.toString(),
    },
    {
      label: t("Admin.Users.Filters.Dropdown.UserStatus.Deleted"),
      value: UserStatus.DELETED.toString(),
    },
  ];

  const orderStatusSelectOptions = [
    {
      label: t("OrderStatus.PENDING"),
      value: OrderStatus.PENDING.toString(),
    },
    {
      label: t("OrderStatus.UNSHIPPED"),
      value: OrderStatus.UNSHIPPED.toString(),
    },
    {
      label: t("OrderStatus.PARTIALLY_SHIPPED"),
      value: OrderStatus.PARTIALLY_SHIPPED.toString(),
    },
    {
      label: t("OrderStatus.SHIPPED"),
      value: OrderStatus.SHIPPED.toString(),
    },
    {
      label: t("OrderStatus.CANCELED"),
      value: OrderStatus.CANCELED.toString(),
    },
    {
      label: t("OrderStatus.UNFULFILLABLE"),
      value: OrderStatus.UNFULFILLABLE.toString(),
    },
    {
      label: t("OrderStatus.INVOICE_UNCONFIRMED"),
      value: OrderStatus.INVOICE_UNCONFIRMED.toString(),
    },
    {
      label: t("OrderStatus.PENDING_AVAILABILITY"),
      value: OrderStatus.PENDING_AVAILABILITY.toString(),
    },
  ];

  const limitUsageHistoryOperationSelectOptions = [
    {value: UserLimitType.SEARCH.toString(), label: t("Search")},
    {value: UserLimitType.CRAWLER_SCAN.toString(), label: t("Scan")},
  ];
  const businessModelSelectOptions = [
    {value: AmazonBusinessModel.CROSS_BORDER_ARBITRAGE.toString(), label: t("Cross-border Arbitrage")},
    {value: AmazonBusinessModel.WHOLESALE.toString(), label: t("Wholesale")},
    {value: AmazonBusinessModel.DROPSHIPPING.toString(), label: t("Dropshipping")},
  ];

  const tagColorSelectOptions = [
    {value: TagColor.PRIMARY.toString(), label: t("TagColor.NavyBlue")},
    {value: TagColor.SECONDARY.toString(), label: t("TagColor.Blue")},
    {value: TagColor.SUCCESS.toString(), label: t("TagColor.Green")},
    {value: TagColor.DANGER.toString(), label: t("TagColor.Red")},
    {value: TagColor.WARNING.toString(), label: t("TagColor.Orange")},
    {value: TagColor.INFO.toString(), label: t("TagColor.LightBlue")},
    {value: TagColor.DARK.toString(), label: t("TagColor.Black")},
  ];

  const multiSelectTranslations: MultiSelectTranslations = {
    allItemsAreSelected: t("General.Select.AllItemsAreSelected"),
    clearSearch: t("General.Select.ClearSearch"),
    clearSelected: t("General.Select.ClearSelected"),
    noOptions: t("General.Select.NoOptions"),
    search: t("General.Select.Search"),
    selectAll: t("General.Select.SelectAll"),
    selectAllFiltered: t("General.Select.SelectAllFiltered"),
    selectSomeItems: t("General.Select.SelectSomeItems"),
    create: t("General.Select.Create"),
  };

  return {
    searchStatusSelectOptions,
    searchTypeSelectOptions,
    decisionSelectOptions,
    amazonCatalogStatusSelectOptions,
    commonProductSelectOptions,
    restrictionCheckSelectOptions,
    shipmentStatusSelectOptions,
    scanAndSaveStatusSelectOptions,
    dateRangeSelectOptions,
    competitionSelectOptions,
    priceAnalysisResultSelectOptions,
    isReviewedSelectOptions,
    supportIssueSelectOptions,
    supportIssuePrioritySelectOptions,
    serviceSelectOptions,
    microserviceSelectOptions,
    logOperationSelectOptions,
    limitUsageHistoryOperationSelectOptions,
    subscriptionStatusSelectOptions,
    storeStatusSelectOptions,
    userStatusSelectOptions,
    orderStatusSelectOptions,
    businessModelSelectOptions,
    tagColorSelectOptions,
    multiSelectTranslations,
  };
};

export default useSelectOptions;

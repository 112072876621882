import {Role} from "./role";
import {UserSubscription} from "./user_subscription";
import { UserTag } from "./user_tag";

export interface User {
  userId: string;
  parentUserId?: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string;
  phoneVerified: boolean;
  stripeCustomerId: number;
  avatar?: string;
  stripeBalance: number;
  stripeTotalSpend: number;
  loginFailureCount: number;
  passwordChangeDate: Date;
  registrationDate: Date;
  lastLogin?: Date;
  status: UserStatus;
  statusDate: Date;
  statusReason: string;
  statusUser?: string;
  language: string;
  theme: string;
  userRoles: UserRole[];
  userSubscriptions?: UserSubscription[];
  userTags: UserTag[];
}
export interface TeamUser {
  userId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string;
  avatar?: string;
  lastLogin?: Date;
  status: UserStatus;
  isCodeExpired?: boolean;
  emailConfirmed?: boolean;
  userRoles: UserRole[];
}

export type UserRole = {
  userRoleId: string;
  roleId: string;
  userId: string;
  createDate: Date;
  updateDate: Date;
  role: Role;
};

export enum UserStatus {
  ACTIVE = 0,
  BANNED = 1,
  PASSWORD_LOCKED = 2,
  WAITING_FOR_CREDIT_CARD_CONFIRMATION = 3,
  DELETED = 4,
}

export type SearchUserQuery = {
  query: string;
};

export interface ICreateOrUpdateSubUserInvitationDto {
  email: string;
  analyzer: boolean;
  accounting: boolean;
  reporting: boolean;
}

export interface IRefreshSubUserInvitationDto {
  subUserId: string;
}

export interface IDeleteSubUserDto {
  subUserId: string;
}

export interface SendSupportRequest {
  description: string;
  issue: string;
  issueDetail: string;
  priority: string;
  attachments: File[];
}

export interface SendReportErrorRequest {
  profitAnalysisError: boolean;
  priceAnalysisError: boolean;
  saleAnalysisError: boolean;
  sellerAnalysisError: boolean;
  productSizeError: boolean;
  restrictionCheckError: boolean;
  variationAnalysisError: boolean;
  saleCountError: boolean;
  competitorAnalysisError: boolean;
  additionalInformation: string;
  searchId: string;
  asin: string;
  marketplaceName: string;
}

import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Card, CardBody, Col} from "reactstrap";
import {UserShipmentItem} from "models/user_shipment_item";
import {useSelector} from "react-redux";
import {UserStore} from "models/user_stores";
import {SellThisModal} from "Components/Common/SellThis";
import {ShipmentSlice} from "slices/shipment/selector";
import SimpleBar from "simplebar-react";
import errorImage from "assets/images/svg/product.svg";
import DomainToFlag from "Components/Common/DomainToFlag";
import CopyWidget from "Components/Common/CopyWidget";
import NoData from "Components/Common/NoData";

const SoldOutProducts = () => {
  const {t} = useTranslation();
  const [soldOutProductsData, setSoldOutProductsData] = useState<UserShipmentItem[]>([]);
  const [userStore, setUserStore] = useState<UserStore>();
  const {currentUserShipment} = useSelector(ShipmentSlice);

  useEffect(() => {
    if (currentUserShipment) {
      const soldOutProductsFiltered = currentUserShipment.userShipmentItems.filter((item) => item.totalItems - item.soldItems === 0);
      setSoldOutProductsData(soldOutProductsFiltered);
      setUserStore(currentUserShipment.userStore);
    }
  }, [currentUserShipment]);

  return (
    <Col xs={12} lg={6} className="same-height">
      <Card className="card-animate">
        <div className="align-items-center d-flex card-header">
          <h4 className="card-title mb-0 flex-grow-1">{t("Shipments.ShippedOrCompleted.SoldOutProducts.Title")}</h4>
          <div className="flex-shrink-0">
            {soldOutProductsData && soldOutProductsData.length > 0 && (
              <span className="fw-semibold fs-14">
                {soldOutProductsData.length} {soldOutProductsData.length > 0 ? t("SKUs") : t("SKU")}
              </span>
            )}
          </div>
        </div>
        <CardBody>
          {soldOutProductsData && soldOutProductsData.length > 0 ? (
            <div className="table-responsive table-card">
              <SimpleBar style={{height: "500px"}}>
                <table className="table-hover table table-centered align-middle mb-0">
                  <tbody>
                    {soldOutProductsData &&
                      soldOutProductsData.map((product) => {
                        const imagePath = product && `https://m.media-amazon.com/images/I/${product.userInventory.imageSmall}`;
                        return (
                          <tr key={product.userInventoryId}>
                            <td>
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <h5 className="fs-14 mb-2 fw-normal text-center">
                                    <span className="badge rounded-pill bg-danger-subtle text-danger">{t("Shipments.ShippedOrCompleted.SoldOutProducts.Label.OutOfStock")}</span>
                                  </h5>
                                  <div className="avatar-md rounded flex-shrink-0 overflow-hidden">
                                    <img
                                      src={imagePath}
                                      alt=""
                                      className="member-img img-fluid d-block rounded"
                                      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                        e.currentTarget.onerror = null;
                                        e.currentTarget.src = errorImage;
                                      }}
                                    ></img>
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <div className="text-muted d-flex align-items-center text-nowrap mb-1">
                                    <DomainToFlag marketplace={userStore?.marketplace ?? ""} />
                                    <span>{`${userStore?.marketplace} - ${userStore?.name}`}</span>
                                  </div>
                                  <p className="ellipsis-two-lines mb-0" title={product.userInventory.productName}>
                                    {product.userInventory.productName}
                                  </p>
                                  <div className="d-flex flex-wrap gap-2 align-items-center">
                                    <CopyWidget text={product.userInventory.asin} index={product.userInventory.asin} asLink={true} marketplace={userStore?.marketplace} />
                                    <CopyWidget text={product.userInventory.sku} index={product.userInventory.sku} asLink={false} />
                                    <Button
                                      type="button"
                                      color="link"
                                      onClick={() => {
                                        SellThisModal.open({asin: product.userInventory.asin, marketplace: userStore?.marketplace!});
                                      }}
                                      className="link-dark link-offset-1 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover align-items-center d-flex p-0"
                                    >
                                      <i className="mdi mdi-cube-send fs-20 me-1"></i>
                                      {t("Shipments.ShippedOrCompleted.SoldOutProducts.Button.Resend")}
                                    </Button>
                                  </div>
                                  <div className="d-flex gap-4 mt-1 text-muted">
                                    <div className="d-flex align-items-center">
                                      <i className="ri-bar-chart-fill text-danger fs-16 me-1"></i> {t("Shipments.ShippedOrCompleted.SoldOutProducts.Label.Remaining")}: 0
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <i className="ri-history-fill text-success fs-16 me-1"></i> {t("Shipments.ShippedOrCompleted.SoldOutProducts.Label.TotalSoldItems")}: {product.soldItems}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </SimpleBar>
            </div>
          ) : (
            <NoData icon="mdi mdi-note-search-outline" />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default SoldOutProducts;

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DeleteNoteCommand, MarkSearchProductReviewedCommand} from "api/command";
import {getSearchResultLocalData} from "helpers/local_storage";
import {ApiError, LoadingStates, PagedList} from "helpers/types";
import {SearchResults} from "models/search_result";
import {DeleteUserFavoriteListCommand, UserFavorite} from "models/user_favorites";
import {UserSearch} from "models/user_search";
import {UserSearchNote} from "models/user_search_note";
import {UserSearchResultsFilter} from "models/user_search_result_filter";

type SearchResultLoadingStates = LoadingStates<"list" | "definedFilters" | "filter" | "update" | "save" | "delete" | "listFavorite" | "editFavoriteList" | "markReviewed" | "note" | "export">;

export type SearchResultState = {
  result: SearchResults;
  favoriteList: Partial<PagedList<UserFavorite>>;
  currentFavoriteList?: UserFavorite;
  definedFilters: UserSearchResultsFilter[];
  loading: SearchResultLoadingStates;
  detailedModeVisibility: boolean;
  error?: ApiError;
};

const initialState: SearchResultState = {
  result: {
    products: {items: []},
    showCheckList: false,
    favorite: {} as UserFavorite,
    search: {} as UserSearch,
    stores: [],
    categories: [],
    notes: [],
  },
  favoriteList: {items: []},
  currentFavoriteList: {} as UserFavorite,
  definedFilters: [],
  loading: {
    list: false,
    listFavorite: false,
    definedFilters: false,
    filter: true,
    update: false,
    save: false,
    delete: false,
    editFavoriteList: false,
    markReviewed: false,
    note: false,
    export: false,
  },
  detailedModeVisibility: getSearchResultLocalData()?.showDetails ?? true,
  error: {} as ApiError,
};

const SearchResultsSlice = createSlice({
  name: "SearchResults",
  initialState,
  reducers: {
    loading(state, action: PayloadAction<[keyof SearchResultLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    setResult(state, action: PayloadAction<SearchResults>) {
      state.result = action.payload;
    },
    setDefinedFilters(state, action: PayloadAction<UserSearchResultsFilter[]>) {
      state.definedFilters = action.payload;
    },
    setShowCheckList(state, action: PayloadAction<boolean>) {
      state.result.showCheckList = action.payload;
    },
    setFavoriteList(state, action: PayloadAction<Partial<PagedList<UserFavorite>>>) {
      state.favoriteList = action.payload;
    },
    setCurrentFavoriteList(state, action: PayloadAction<UserFavorite>) {
      state.currentFavoriteList = action.payload;
    },
    addFavItem(state, action) {
      const {asin, userFavoriteId} = action.payload;
      state.result = {
        ...state.result,
        products: {
          ...state.result.products,
          items: state.result.products.items?.map((item) => (item.asin === asin ? {...item, userFavoriteId} : item)),
        },
      };
    },
    removeFavItem(state, action) {
      const {asin} = action.payload;
      state.result = {
        ...state.result,
        products: {
          ...state.result.products,
          items: state.result.products.items?.map((item) => (item.asin === asin ? {...item, userFavoriteId: undefined} : item)),
        },
      };
    },
    deleteOrRestoreFavoriteItem(state, action: PayloadAction<DeleteUserFavoriteListCommand>) {
      state.favoriteList = {
        ...state.favoriteList,
        items: state.favoriteList.items?.map((item) => (item.userFavoriteId === action.payload.userFavoriteId ? {...item, deleted: action.payload.deleted} : item)),
      };
    },
    setInUserShipment(state, action) {
      const {asin, inUserShipment} = action.payload;
      state.result = {
        ...state.result,
        products: {
          ...state.result.products,
          items: state.result.products.items?.map((item) => (item.asin === asin ? {...item, inUserShipment} : item)),
        },
      };
    },
    markReviewedItem(state, action: PayloadAction<MarkSearchProductReviewedCommand>) {
      const {userSearchProductId, isReviewed} = action.payload;
      state.result = {
        ...state.result,
        products: {
          ...state.result.products,
          items: state.result.products.items?.map((item) => (item.userSearchProductId === userSearchProductId ? {...item, isReviewed: isReviewed} : item)),
        },
      };
    },
    addOrUpdateNoteAction(state, action: PayloadAction<UserSearchNote>) {
      if (!state.result.notes) {
        state.result.notes = [action.payload];
        return;
      }

      const existingNoteIndex = state.result.notes.findIndex((note) => note.userSearchNoteId === action.payload.userSearchNoteId);

      existingNoteIndex === -1 ? state.result.notes.push(action.payload) : (state.result.notes[existingNoteIndex] = action.payload);
    },
    addOrRemoveNoteAction(state, action: PayloadAction<DeleteNoteCommand>) {
      state.result = {
        ...state.result,
        notes: state.result.notes?.map((item) => (item.userSearchId === action.payload.userSearchId ? {...item, deleted: action.payload.deleted} : item)),
      };
    },
    setDetailedModeVisibility(state, action: PayloadAction<boolean>) {
      state.detailedModeVisibility = action.payload;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.currentFavoriteList = initialState.currentFavoriteList;
      state.definedFilters = initialState.definedFilters;
      state.error = initialState.error;
      state.favoriteList = initialState.favoriteList;
      state.loading = initialState.loading;
      state.result = initialState.result;
    },
  },
});

export const {
  loading,
  setResult,
  setDefinedFilters,
  setShowCheckList,
  setFavoriteList,
  setCurrentFavoriteList,
  addFavItem,
  removeFavItem,
  deleteOrRestoreFavoriteItem,
  setInUserShipment,
  markReviewedItem,
  addOrUpdateNoteAction,
  addOrRemoveNoteAction,
  setDetailedModeVisibility,
  apiError,
  reset,
} = SearchResultsSlice.actions;

export default SearchResultsSlice.reducer;

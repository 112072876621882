import useSelectOptions, {UserSelectOption} from "Components/Hooks/useSelectOptions";
import {Button, Card, CardBody, Col, Form, Label, Row, Spinner} from "reactstrap";
import {useFormik} from "formik";
import {RefObject, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ShipmentListRef} from "..";
import {FilterShipmentsQuery} from "models/user_shipment";
import {DateRange} from "helpers/types";
import {useProfile} from "Components/Hooks/useProfile";
import {useSelector} from "react-redux";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import {CommonSliceSelector} from "slices/common/selector";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import NewShipment, {NewShipmentModal} from "../Modal/NewShipment";
import Restricted from "Components/Common/Restricted";
import ValidatedDateRangeInput from "Components/Common/ValidatedDateRangeInput";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";

interface FiltersProps {
  listRef: RefObject<ShipmentListRef>;
  busy: boolean;
  fields: FieldConfig<FilterShipmentsQuery>[];
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const {shipmentStatusSelectOptions, multiSelectTranslations} = useSelectOptions();
  const {userProfile} = useProfile();
  const {activeUserStoreOptions, disconnectedUserStoreOptions} = useSelector(CommonSliceSelector);
  const [teamUserOptions, setTeamUserOptions] = useState<UserSelectOption[]>([]);

  const onLoad = useCallback(
    () => {
      validation.handleSubmit();

      if (userProfile?.teamUsers) {
        let ownerOption: UserSelectOption[] = [{value: userProfile.userId, label: userProfile.fullName, avatar: userProfile.avatar, email: userProfile.email}];
        const teamOptions: UserSelectOption[] = userProfile.teamUsers.map((user) => ({
          label: user.fullName,
          value: user.userId,
          avatar: user.avatar,
          email: user.email,
        }));
        setTeamUserOptions([...ownerOption, ...teamOptions]);
      }
    },
    [], // eslint-disable-line
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  // validation
  const validation = useFormik({
    initialValues: {} as FilterShipmentsQuery,
    validationSchema: Yup.object<FilterShipmentsQuery>({
      search: Yup.string(),
      startDate: Yup.date(),
      endDate: Yup.date(),
      shipDateRange: Yup.object<DateRange>({
        startDate: Yup.date(),
        endDate: Yup.date(),
      }),
      marketplaceFilter: Yup.string(),
      statusFilter: Yup.string(),
      teamUserIds: Yup.array<String>(),
    }),
    onSubmit: (values) => {
      const payload: FilterShipmentsQuery = {
        page: values.page,
        pageSize: values.pageSize,
        search: values.search || undefined,
        createDateRange: {
          start: values.createDateRange?.start,
          end: values.createDateRange?.end,
        },
        shipDateRange: {
          start: values.shipDateRange?.start,
          end: values.shipDateRange?.end,
        },
        userStoreIds: values.userStoreIds || undefined,
        statuses: values.statuses || undefined,
        teamUserIds: values.teamUserIds,
        sortBy: values.sortBy,
        sortingOrder: values.sortingOrder,
        action: "filtering",
      };
      updateQuery(payload);
    },
  });
  const {updateQuery} = useUrlQuery<FilterShipmentsQuery>(props.fields, validation);

  return (
    <PlaceholderGlow busy={props.busy}>
      <Card style={{zIndex: 4}}>
        <CardBody>
          <div className="live-preview">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row className="align-items-center">
                <Col>
                  <div className="flex-shrink-0">
                    <Label className="form-label text-muted">{t("Shipments.Filters.Title")}</Label>
                  </div>
                </Col>
                <Col xs="auto" align="right">
                  <Restricted require="shipments" create>
                    <Button
                      type="button"
                      className="fw-semibold btn-sm"
                      disabled={props.busy}
                      onClick={() =>
                        NewShipmentModal.open({
                          listRef: props.listRef,
                        })
                      }
                    >
                      {props.busy ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : <i className="ri-add-line align-middle me-1"></i>}
                      {t("Shipments.NewShipment")}
                    </Button>
                    <NewShipment />
                  </Restricted>
                </Col>
              </Row>
              <Row className="mt-2 gx-5 gy-3">
                <Col xs={12} sm={6} md={4} lg={3}>
                  <Label htmlFor="endDate">{t("Shipments.Filters.Label.Search")}</Label>
                  <ValidatedInput validation={validation} field="search" placeholder={t("Shipments.Filters.Label.SearchPlaceholder")} disableValidationUI />
                </Col>
                <Col xs={12} sm={6} md={4} lg={3}>
                  <Label htmlFor="endDate">{t("Shipments.Filters.Label.CreateDate")}</Label>
                  <ValidatedDateRangeInput validation={validation} field="createDateRange" />
                </Col>
                <Col xs={12} sm={6} md={4} lg={3}>
                  <Label htmlFor="endDate">{t("Shipments.Filters.Label.ShipDate")}</Label>
                  <ValidatedDateRangeInput validation={validation} field="shipDateRange" />
                </Col>
                <Col sm={2}>
                  <Label htmlFor="userStoreIds">{t("Shipments.Filters.Label.Store")}</Label>
                  <ValidatedMultiSelect
                    options={[...activeUserStoreOptions, ...disconnectedUserStoreOptions]}
                    validation={validation}
                    field="userStoreIds"
                    value={validation.values.userStoreIds}
                    translations={{
                      ...multiSelectTranslations,
                      selectSomeItems: t("General.Select.SelectStore"),
                      allItemsAreSelected: t("General.Select.AllStoresSelected"),
                    }}
                    optionStyle="marketplace"
                  />
                </Col>
                <Col sm={2}>
                  <Label htmlFor="statuses">{t("Shipments.Filters.Label.Status")}</Label>
                  <ValidatedMultiSelect options={shipmentStatusSelectOptions} validation={validation} field="statuses" value={validation.values.statuses} hasSelectAll />
                </Col>
                {userProfile?.teamUsers && userProfile?.teamUsers.length > 0 && userProfile?.subscription === "Premium" && (
                  <Col sm={2}>
                    <Label htmlFor="statuses">{t("User")}</Label>
                    <ValidatedMultiSelect options={teamUserOptions} validation={validation} field="teamUserIds" value={validation.values.teamUserIds} optionStyle="user" hasSelectAll />
                  </Col>
                )}
                <Col sm={2}>
                  <Label>&nbsp;</Label>
                  <Button type="submit" color="secondary" className="d-block" disabled={props.busy} onClick={() => validation.setFieldValue("page", 1)}>
                    {props.busy ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                    {t("Shipments.Filters.Button.Apply")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </CardBody>
      </Card>
    </PlaceholderGlow>
  );
};

export default Filters;

import useSelectOptions, {MarketplaceSelectOption} from "Components/Hooks/useSelectOptions";
import {useCallback, useEffect, useState} from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row, Spinner, Form, Label} from "reactstrap";
import {useFormik} from "formik";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getSingleSearchLocalData, updateSingleSearchLocalData} from "helpers/local_storage";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {createTypedModal} from "helpers/modal_helpers";
import {getActiveUserStoresWithActiveMarketplaceOptions} from "helpers/utilities";
import {SearchesSlice} from "slices/searches/selector";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";
import _ from "lodash";

interface ModalData {
  asin?: string;
}

export const SingleSearchModal = createTypedModal<ModalData>("single_search");
const SingleSearch = () => {
  const {t} = useTranslation();
  const {open, data} = SingleSearchModal.useModal();
  const navigate = useNavigate();
  const {businessModelSelectOptions} = useSelectOptions();
  const [marketplaceOptions, setMarketplaceOptions] = useState<MarketplaceSelectOption[]>([]);

  const {loading} = useSelector(SearchesSlice);

  const validation = useFormik({
    initialValues: {
      asin: data?.asin,
      businessModel: getSingleSearchLocalData()?.amazonBusinessModel,
      marketplace: getSingleSearchLocalData()?.marketplace,
    },
    validationSchema: Yup.object({
      asin: Yup.string().required(t("Common.Dialog.SingleSearch.Validation.Asin")),
      businessModel: Yup.mixed<AmazonBusinessModel>().required(t("Common.Dialog.SingleSearch.Validation.BusinessModel")),
      marketplace: Yup.string().required(t("Common.Dialog.SingleSearch.Validation.Marketplace")),
    }),
    onSubmit: (values) => {
      navigate(`/dp/${values.asin}?marketplace=${values.marketplace}&amazonBusinessModel=${values.businessModel}`);
      toggle();
    },
  });

  const initModal = useCallback(() => {
    if (open) {
      validation.setFieldValue("asin", data?.asin);
      setMarketplaceOptions([...getActiveUserStoresWithActiveMarketplaceOptions()]);
    }
  }, [open]); // eslint-disable-line

  const onMarketplaceChange = useCallback(() => {
    if (validation.values.marketplace) {
      updateSingleSearchLocalData({marketplace: validation.values.marketplace});
    }
  }, [validation.values.marketplace]);

  const onAmazonBusinessModelChange = useCallback(() => {
    if (!_.isNil(validation.values.businessModel)) {
      if (validation.values.businessModel.toString() === AmazonBusinessModel.CROSS_BORDER_ARBITRAGE.toString()) {
        const nonUSOptions = getActiveUserStoresWithActiveMarketplaceOptions().filter((x) => x.marketplace !== "US");
        setMarketplaceOptions([...nonUSOptions]);
      } else {
        setMarketplaceOptions([...getActiveUserStoresWithActiveMarketplaceOptions()]);
      }
      updateSingleSearchLocalData({amazonBusinessModel: validation.values.businessModel});
    }
  }, [validation.values.businessModel, open]); // eslint-disable-line

  useEffect(() => {
    initModal();
  }, [initModal]);

  useEffect(() => {
    onMarketplaceChange();
  }, [onMarketplaceChange]);

  useEffect(() => {
    onAmazonBusinessModelChange();
  }, [onAmazonBusinessModelChange]);

  const toggle = () => {
    SingleSearchModal.close();
    validation.resetForm();
  };

  return (
    <>
      <Form onSubmit={validation.submitForm}>
        <Modal id="showNewSearchModal" size="lg" isOpen={open} toggle={toggle} centered={true} backdrop="static">
          <ModalHeader className="p-3" toggle={toggle}>
            {t("Common.Dialog.SingleSearch.Title")}
          </ModalHeader>
          <ModalBody>
            <Row className="g-3">
              <Col xs={12} lg={4}>
                <Label htmlFor="asin">{t("ASIN")}</Label>
                <ValidatedInput validation={validation} type="text" field="asin" maxLength={10} placeholder={t("Asin")} disableValidationUI />
              </Col>
              <Col xs={12} lg={4}>
                <Label htmlFor="businessModel">{t("Business Model")}</Label>
                <ValidatedSelect
                  className="w-100"
                  errorStyle="container"
                  options={businessModelSelectOptions.filter((x) => x.value !== AmazonBusinessModel.WHOLESALE.toString())}
                  validation={validation}
                  field="businessModel"
                  value={validation.values.businessModel}
                  placeholder={t("Select")}
                />
              </Col>
              <Col xs={12} lg={4}>
                <Label htmlFor="asin">{t("Marketplace")}</Label>
                <ValidatedSelect
                  validation={validation}
                  className={`w-100`}
                  errorStyle="container"
                  options={marketplaceOptions}
                  value={validation.values.marketplace}
                  field="marketplace"
                  optionStyle="marketplace"
                  valueStyle="marketplace"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn btn-light" disabled={loading.save} onClick={toggle}>
                {loading.save && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
                {t("Common.Dialog.SingleSearch.CancelButton")}
              </Button>

              <Button
                type="submit"
                className="btn btn-success"
                disabled={loading.save}
                onClick={() => {
                  validation.setFieldTouched("asin");
                  validation.setFieldTouched("businessModel");
                  validation.setFieldTouched("marketplace");

                  if (validation.isValid) {
                    validation.handleSubmit();
                  }
                }}
              >
                {loading.save && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
                {t("Common.Dialog.SingleSearch.SubmitButton")}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </Form>
    </>
  );
};

export default SingleSearch;

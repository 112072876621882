import React, {useEffect, useMemo, useRef, useState} from "react";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {SelectOptionsType} from "Components/Hooks/useSelectOptions";
import {setCurrentCostAndFeeOption} from "slices/settings/reducer";
import {Link, useNavigate} from "react-router-dom";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {deleteCostAndFeeSettings, getCostAndFeesList, setAsDefaultCostAndFees} from "slices/settings/thunk";
import {UserSettingCostAndFeeOptions} from "models/user_setting_cost_and_fee_options";
import {ColumnDef} from "@tanstack/react-table";
import {useProfile} from "Components/Hooks/useProfile";
import {ConstantPage} from "helpers/permission_helper";
import {countryOptionContainer, countryValueContainer} from "Components/Common/Select/SelectStyle";
import {ActiveMarketplaceOptions, ActiveMarketplaces} from "helpers/marketplace_helper";
import SettingsLayout from "Components/Common/Layout/SettingsLayout";
import Select from "react-select";
import CreateCostAndFeeOption from "./Modals/CreateCostAndFeeOption";
import DuplicateCostAndFeeOption from "./Modals/DuplicateCostAndFeeOption";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayDate from "Components/Common/DisplayDate";
import { SettingsSlice } from "slices/settings/selector";

const PAGE_IDENTIFIER: ConstantPage = "settings.costAndFees";
const CostAndFeesList = () => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const setAsDefaultDialogRef = useRef<DialogRef>(null);
  const [selectedMarketplace, setSelectedMarketplace] = useState<SelectOptionsType>();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const tableRef = useRef<DataTableRef>(null);

  useEffect(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      const marketplace = selectedMarketplace?.value;
      dispatch(getCostAndFeesList(marketplace));
    }
  }, [dispatch, navigate, selectedMarketplace]); // eslint-disable-line react-hooks/exhaustive-deps

  const {loading, costAndFeesList, currentCostAndFeeOption} = useSelector(SettingsSlice);

  const columns = useMemo<ColumnDef<UserSettingCostAndFeeOptions, any>[]>(
    () => [
      {
        header: t("Actions"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSettingCostAndFeeOptions;
          const [isDeletingOrRestoring, setIsDeletingOrRestoring] = useState<boolean>(false); // eslint-disable-line

          const handleDeleteOrRestore = async () => {
            setIsDeletingOrRestoring(true);
            await deleteCostAndFeeSettings({userSettingCostAndFeeOptionId: row.userSettingCostAndFeeOptionId, deleted: !row.deleted}, row.name)(dispatch);
            setIsDeletingOrRestoring(false);
          };

          return (
            <div className="d-flex align-items-center">
              <Link
                id={`EditCostAndFee-${row.userSettingCostAndFeeOptionId}`}
                to={`/account/settings/cost-and-fees/edit/${row.userSettingCostAndFeeOptionId}`}
                className="btn btn-ghost-secondary px-1 py-0 fs-16"
              >
                <i className="ri-pencil-fill align-middle"></i>
              </Link>
              <DefaultUncontrolledTooltip target={`EditCostAndFee-${row.userSettingCostAndFeeOptionId}`}>{t("Settings.CostAndFees.Tooltip.Edit")}</DefaultUncontrolledTooltip>

              <Button
                id={`CopyCostAndFees-${row.userSettingCostAndFeeOptionId}`}
                color="ghost-secondary"
                className="btn-link px-1 py-0 fs-18"
                onClick={() => {
                  dispatch(setCurrentCostAndFeeOption(row));
                  setIsDuplicateModalOpen(true);
                }}
              >
                <i className="ri-file-copy-fill align-middle"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`CopyCostAndFees-${row.userSettingCostAndFeeOptionId}`}>{t("Settings.CostAndFees.Tooltip.Duplicate")}</DefaultUncontrolledTooltip>

              <Button
                id={`DeleteCostAndFees-${row.userSettingCostAndFeeOptionId}`}
                color={row.deleted ? "ghost-secondary" : "ghost-danger"}
                className="btn-link px-1 py-0 fs-18"
                onClick={handleDeleteOrRestore}
                disabled={isDeletingOrRestoring}
              >
                {row.deleted ? <i className="ri-arrow-go-back-line align-middle" /> : <i className="ri-delete-bin-fill align-middle"></i>}
              </Button>
              <DefaultUncontrolledTooltip target={`DeleteCostAndFees-${row.userSettingCostAndFeeOptionId}`}>
                {row.deleted ? t("Settings.CostAndFees.Tooltip.Restore") : t("Settings.CostAndFees.Tooltip.Delete")}
              </DefaultUncontrolledTooltip>

              {!row.isDefault && (
                <>
                  <Button
                    id={`SetAsDefaultCostAndFees-${row.userSettingCostAndFeeOptionId}`}
                    color="ghost-secondary"
                    className="btn-link px-1 py-0 fs-16"
                    onClick={() => {
                      dispatch(setCurrentCostAndFeeOption(row));
                      setAsDefaultDialogRef.current?.show();
                    }}
                  >
                    <i className="ri-checkbox-circle-fill align-middle"></i>
                  </Button>
                  <DefaultUncontrolledTooltip target={`SetAsDefaultCostAndFees-${row.userSettingCostAndFeeOptionId}`}>{t("Settings.CostAndFees.Tooltip.SetAsDefault")}</DefaultUncontrolledTooltip>
                </>
              )}
            </div>
          );
        },
      },
      {
        header: t("Settings.CostAndFees.TableColumn.Name"),
        size: 200,
        accessorKey: "name",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSettingCostAndFeeOptions;
          return (
            <Link
              to={`/account/settings/cost-and-fees/edit/${row.userSettingCostAndFeeOptionId}`}
              className="link-secondary link-offset-2 text-decoration-underline"
            >
              {row.name}
            </Link>
          );
        },
      },
      {
        header: t("Settings.CostAndFees.TableColumn.Marketplace"),
        size: 200,
        accessorKey: "marketplace",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSettingCostAndFeeOptions;
          const marketplaceInfo = ActiveMarketplaces.find((marketplaceInfo) => marketplaceInfo.marketplace === row.marketplace && marketplaceInfo.active);
          return (
            <>
              {marketplaceInfo && (
                <span className="hstack gap-2">
                  <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                    <img src={marketplaceInfo.flag} alt="Country Flag" className="rounded-circle" />
                  </div>
                  {`${marketplaceInfo.countryName} (${marketplaceInfo.marketplace})`}
                </span>
              )}
            </>
          );
        },
      },
      {
        header: t("Settings.CostAndFees.TableColumn.IsDefault"),
        size: 200,
        accessorKey: "isDefault",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSettingCostAndFeeOptions;
          return <>{row.isDefault && <span className="fs-12 badge rounded-pill bg-success-subtle text-success">{t("Default")}</span>}</>;
        },
      },
      {
        header: t("Settings.CostAndFees.TableColumn.CreateDate"),
        size: 150,
        accessorKey: "createDate",
        sortingFn: "datetime",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSettingCostAndFeeOptions;
          return (
            <>
              <DisplayDate id={`DisplayDateCreateDate-${row.userSettingCostAndFeeOptionId}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </>
          );
        },
      },
    ],
    [t, dispatch, navigate, userProfile], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Settings.Title"),
      url: "#",
    },
    {
      label: t("Settings.CostAndFees.Title"),
      url: "",
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Settings.Title")} menus={breadcrumbMenus} />
          <SettingsLayout hasAnyChanges={false} navigateTo="">
            <>
              <Card>
                <CardHeader>
                  <CardTitle className="fw-medium align-items-center d-flex">
                    <i className="mdi mdi-currency-jpy fs-18 me-1"></i>
                    {t("Settings.CostAndFees.Title")}
                  </CardTitle>
                  <Row className="mt-3 d-flex align-items-end">
                    <Col xs={9} md={4}>
                      <Select
                        placeholder={t("Settings.CostAndFees.SelectMarketplacePlaceholder")}
                        isClearable={true}
                        options={ActiveMarketplaceOptions}
                        name="marketplaceFilter"
                        id="marketplaceFilter"
                        onChange={(e: any) => setSelectedMarketplace(e)}
                        value={selectedMarketplace}
                        components={{Option: countryOptionContainer, SingleValue: countryValueContainer}}
                      />
                    </Col>
                    <Col></Col>
                    <Col xs={2} md="auto" className="d-flex justify-content-end">
                      <CreateCostAndFeeOption />
                      <DuplicateCostAndFeeOption show={isDuplicateModalOpen} toggle={() => setIsDuplicateModalOpen(!isDuplicateModalOpen)} />
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              <Card>
                <CardBody>
                  <DataTable ref={tableRef} columns={columns} data={costAndFeesList || []} totalDataLength={costAndFeesList.length} busy={loading.list} hovered />
                </CardBody>
              </Card>
            </>
          </SettingsLayout>
        </Container>
      </div>
      <Dialog
        ref={setAsDefaultDialogRef}
        color="success"
        buttons={["yes", "no"]}
        busy={loading.update}
        iconClass="ri-checkbox-circle-fill"
        message={t("Settings.CostAndFees.Dialog.SetAsDefault.Description")}
        title={t("Settings.CostAndFees.Dialog.SetAsDefault.Title", {marketplace: currentCostAndFeeOption.marketplace})}
        onButtonClick={async (button) => {
          if (button === "yes") {
            const current = currentCostAndFeeOption;
            await dispatch(setAsDefaultCostAndFees(current.userSettingCostAndFeeOptionId, current.marketplace));
          }
          setAsDefaultDialogRef.current?.hide();
        }}
      />
    </React.Fragment>
  );
};

export default CostAndFeesList;

import {Button, Card, CardBody, Col, Form, Label, Row, Spinner} from "reactstrap";
import {useFormik} from "formik";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useProfile} from "Components/Hooks/useProfile";
import {ScanAndSaveQuery} from "api/query";
import {UserSelectOption} from "Components/Hooks/useSelectOptions";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import ValidatedDate from "Components/Common/ValidatedDate";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import useSelectOptions from "Components/Hooks/useSelectOptions";
import * as Yup from "yup";

interface FiltersProps {
  busy: boolean;
  fields: FieldConfig<ScanAndSaveQuery>[];
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {scanAndSaveStatusSelectOptions} = useSelectOptions();
  const {userProfile} = useProfile();
  const [teamUserOptions, setTeamUserOptions] = useState<UserSelectOption[]>([]);

  // validation
  const validation = useFormik({
    initialValues: {} as ScanAndSaveQuery,
    validationSchema: Yup.object({
      startDate: Yup.date(),
      endDate: Yup.date(),
      statuses: Yup.array<String>().notRequired(),
      teamUserIds: Yup.array<String>().nullable(),
    }),
    onSubmit: (values) => {
      const payload: ScanAndSaveQuery = {
        page: values.page,
        pageSize: values.pageSize,
        startDate: values.startDate,
        endDate: values.endDate,
        statuses: values.statuses,
        teamUserIds: values.teamUserIds,
        action: "filtering",
      };
      updateQuery(payload);
    },
  });
  const {updateQuery} = useUrlQuery<ScanAndSaveQuery>(props.fields, validation);

  const onLoad = useCallback(
    () => {
      validation.handleSubmit();

      if (userProfile?.teamUsers) {
        let ownerOption: UserSelectOption[] = [{value: userProfile.userId, label: userProfile.fullName, avatar: userProfile.avatar, email: userProfile.email}];
        const teamOptions: UserSelectOption[] = userProfile.teamUsers.map((user) => ({
          label: user.fullName,
          value: user.userId,
          avatar: user.avatar,
          email: user.email,
        }));
        setTeamUserOptions([...ownerOption, ...teamOptions]);
      }
    },
    [], // eslint-disable-line
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <PlaceholderGlow busy={props.busy}>
      <Card style={{zIndex: 4}}>
        <CardBody>
          <div className="live-preview">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row className="align-items-center">
                <Col>
                  <div className="flex-shrink-0">
                    <Label className="form-label text-muted">{t("ScanAndSave.Filters.Title")}</Label>
                  </div>
                </Col>
                <Col xs="auto" align="right">
                  <Button type="button" className="fw-semibold btn-sm" disabled={props.busy} onClick={() => navigate("/scan-and-save/new")}>
                    <i className="ri-add-line align-bottom me-1"></i>
                    {t("ScanAndSave.Button.NewScanAndSave")}
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2 gx-5 gy-3">
                <Col xs={12} sm={6} md={4} lg={2}>
                  <Label htmlFor="startDate">{t("ScanAndSave.Filters.StartDate")}</Label>
                  <ValidatedDate field="startDate" validation={validation} value={validation.values.startDate} max={validation.values.endDate}></ValidatedDate>
                </Col>
                <Col xs={12} sm={6} md={4} lg={2}>
                  <Label htmlFor="endDate">{t("ScanAndSave.Filters.EndDate")}</Label>
                  <ValidatedDate field="endDate" validation={validation} value={validation.values.endDate} min={validation.values.startDate}></ValidatedDate>
                </Col>
                <Col xs={12} sm={6} md={4} lg={2}>
                  <Label htmlFor="statusFilter">{t("ScanAndSave.Filters.Status")}</Label>
                  <ValidatedMultiSelect options={scanAndSaveStatusSelectOptions} validation={validation} field="statuses" value={validation.values.statuses} hasSelectAll/>
                </Col>
                {userProfile?.teamUsers && userProfile?.teamUsers.length > 0 && userProfile?.subscription === "Premium" && (
                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="teamUserIds">{t("User")}</Label>
                    <ValidatedMultiSelect className="filter-input" options={teamUserOptions} validation={validation} field="teamUserIds" value={validation.values.teamUserIds} optionStyle="user" hasSelectAll/>
                  </Col>
                )}
                <Col xs={12} sm={6} md={4} lg={2}>
                  <Label>&nbsp;</Label>
                  <Button type="submit" color="secondary" className="d-block" disabled={props.busy} onClick={() => validation.setFieldValue("page", 1)}>
                    {props.busy ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                    {t("ScanAndSave.Filters.Button.Apply")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </CardBody>
      </Card>
    </PlaceholderGlow>
  );
};

export default Filters;

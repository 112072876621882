import React, {useEffect, useState} from "react";
import {Row, Col, CardBody, Card, Alert, Container, Label, Form, Button, Spinner} from "reactstrap";
import {useFormik} from "formik";
import {getInvitationInformation, registerUser, resetRegisterFlag} from "../../slices/thunks";
import {useSelector, useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {createSelector} from "reselect";
import {Trans, useTranslation} from "react-i18next";
import {IRegisterUserDto} from "models/application_user";
import {RootState} from "slices";
import {TimeZone, TimeZones} from "models/timezone";
import {updateSubscriptionLocalData} from "helpers/local_storage";
import {SubscriptionPeriodType} from "models/local_storage";
import ValidatedInput from "Components/Common/ValidatedInput";
import ParticlesAuth from "./ParticlesAuth";
import PhoneInput from "react-phone-input-2";
import logoTransparent from "../../assets/images/logo-dark-transparent.png";
import moment from "moment";
import i18n from "i18n";
import "react-phone-input-2/lib/high-res.css";
import * as Yup from "yup";
import _ from "lodash";

const Register = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [passwordShow, setPasswordShow] = useState<boolean>(false);

  const registerPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Register.loading,
      status: state.Register.status,
      emailRef: state.Register.emailRef,
      error: state.Register.error,
    }),
  );
  // Inside your component
  const {loading, status, emailRef, error} = useSelector(registerPageData);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: "",
      lastName: "",
      email: emailRef || "",
      phone: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t("Register.Validation.FirstName")),
      lastName: Yup.string().required(t("Register.Validation.LastName")),
      email: Yup.string()
        .required(t("Register.Validation.Email"))
        .email(t("Register.Validation.Email")),
      phone: Yup.string()
        .required(t("Register.Validation.Phone"))
        .min(6, t("Register.Validation.Phone")),
      password: Yup.string()
        .required(t("Register.Validation.Password"))
        .min(8, t("Register.Validation.PasswordLength"))
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/, t("Register.Validation.PasswordPattern")),
      confirmPassword: Yup.string()
        .required(t("Register.Validation.ConfirmPassword"))
        .min(8, " ")
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/, " ")
        .oneOf([Yup.ref("password"), ""], t("Register.Validation.PasswordMatch")),
    }),
    onSubmit: (values) => {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get("accountInvite");
      const tokenValue = _.isNil(token) ? undefined : token;
      const params: IRegisterUserDto = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        password: values.password,
        timezone: getUserTimezone(),
        token: tokenValue,
      };

      const savePromise = registerUser(params)(dispatch);

      savePromise.then((success) => {
        if (success) {
          validation.resetForm();
          navigate("/account/registration-successful");
        }
      });
    },
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const accountInvite = queryParams.get("accountInvite");
    if (accountInvite) {
      getInvitationInformation(accountInvite)(dispatch);
    }

    const tier = queryParams.get("tier");
    const frequency = queryParams.get("frequency");
    if (tier && frequency) {
      const tierValue: string = _.toLower(tier) as string;
      const frequencyValue: SubscriptionPeriodType = _.toLower(frequency) as SubscriptionPeriodType;
      updateSubscriptionLocalData(tierValue, frequencyValue);
    }
    return () => {
      dispatch(resetRegisterFlag());
    };
  }, []); // eslint-disable-line

  const getUserTimezone = (): string => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userOffset = moment.tz(userTimezone).utcOffset() / 60;
    const timezone = TimeZones.find((option: TimeZone) => option.offset === userOffset && option.label.includes(userTimezone.split("/")[1]));
    return timezone?.value || "UTC";
  };
  document.title = t("PageTitles.Register");

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content mt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoTransparent} alt="" height="50" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">{t("General.Slogan")}</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">{t("Register.Title")}</h5>
                      <p
                        className="text-muted"
                        dangerouslySetInnerHTML={{
                          __html: t("Register.Subtitle"),
                        }}
                      ></p>
                    </div>
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {status === "success" ? (
                          <Alert color="success">{t("Register.Message.RegistrationComplete")}</Alert>
                        ) : status === "success-team" ? (
                          <Alert color="success">{t("Register.Message.RegistrationCompleteSubUser")}</Alert>
                        ) : status === "failed" ? (
                          <Alert color="danger">{t(error?.message as string)}</Alert>
                        ) : null}

                        {status === "success" || status === "success-team" ? null : (
                          <>
                            <Row className="g-3">
                              <Col xxl={6}>
                                <Label htmlFor="firstName" className="form-label">
                                  {t("Register.FirstName")}
                                </Label>
                                <ValidatedInput validation={validation} field="firstName" placeholder={t("Register.FirstNamePlaceholder")} maxLength={50} tabIndex={1} upperFirst disableValidationUI />
                              </Col>
                              <Col xxl={6}>
                                <Label htmlFor="lastName" className="form-label">
                                  {t("Register.LastName")}
                                </Label>
                                <ValidatedInput validation={validation} field="lastName" placeholder={t("Register.LastNamePlaceholder")} maxLength={50} tabIndex={2} upperFirst disableValidationUI />
                              </Col>

                              <Col lg={12}>
                                <Label htmlFor="email" className="form-label">
                                  {t("Register.Email")}
                                </Label>
                                <ValidatedInput
                                  validation={validation}
                                  type="email"
                                  field="email"
                                  maxLength={100}
                                  placeholder={t("Register.EmailPlaceholder")}
                                  disabled={emailRef ? true : false}
                                  tabIndex={3}
                                  disableValidationUI
                                />
                              </Col>

                              <Col lg={12}>
                                <Label htmlFor="phone" className="form-label">
                                  {t("Register.Phone")}
                                </Label>
                                <PhoneInput
                                  searchStyle={{border: "1px solid #ced4da", borderRadius: "0.25rem", height: "38px", marginBottom: "5px", width: "calc(100% - 30px)"}}
                                  enableSearch
                                  specialLabel=""
                                  inputClass={`w-100`}
                                  country={i18n.language === "en" ? "us" : i18n.language}
                                  onChange={(value) => validation.setFieldValue("phone", value)}
                                  onBlur={() => validation.setFieldTouched("phone", true)}
                                  value={validation.values.phone || ""}
                                  buttonStyle={{borderColor: validation.touched.phone && validation.errors.phone ? "red" : ""}}
                                  inputStyle={{borderColor: validation.touched.phone && validation.errors.phone ? "red" : ""}}
                                  inputProps={{tabIndex: 4}}
                                />

                                {validation.touched.phone && validation.errors.phone ? <div className="mt-1 text-danger fs-11">{t(validation.errors.phone)}</div> : null}
                              </Col>
                              <Col xxl={6}>
                                <Label htmlFor="password" className="form-label">
                                  {t("Register.Password")}
                                </Label>
                                <div className="position-relative auth-pass-inputgroup">
                                  <ValidatedInput
                                    validation={validation}
                                    type={passwordShow ? "text" : "password"}
                                    field="password"
                                    placeholder={t("Register.PasswordPlaceholder")}
                                    tabIndex={5}
                                    disableValidationUI
                                  />
                                  <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" onClick={() => setPasswordShow(!passwordShow)}>
                                    <i className="ri-eye-fill align-middle"></i>
                                  </button>
                                </div>
                              </Col>
                              <Col xxl={6}>
                                <Label htmlFor="confirmPassword" className="form-label">
                                  {t("Register.ConfirmPassword")}
                                </Label>
                                <div className="position-relative auth-pass-inputgroup">
                                  <ValidatedInput
                                    validation={validation}
                                    type={passwordShow ? "text" : "password"}
                                    field="confirmPassword"
                                    placeholder={t("Register.ConfirmPasswordPlaceholder")}
                                    tabIndex={6}
                                    disableValidationUI
                                  />

                                  <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" onClick={() => setPasswordShow(!passwordShow)}>
                                    <i className="ri-eye-fill align-middle"></i>
                                  </button>
                                </div>
                              </Col>
                              <Col lg={12} className="mt-4">
                                <p className="mb-2 fs-12 text-muted fst-italic">
                                  <Trans i18nKey="Register.LabelTermsOfUse">
                                    {t("Register.LabelTermsOfUse")}
                                    <Link to="https://www.sellthis.com/privacy" target="_blank" className="text-primary text-decoration-underline fst-normal fw-medium"></Link>
                                  </Trans>
                                </p>
                                <Button color="success" className="w-100" type="submit" tabIndex={7} disabled={loading.register}>
                                  {loading.register ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : null}
                                  {t("Register.SignUp")}
                                </Button>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    <Link to="/account/login" className="fw-semibold text-primary text-decoration-underline">
                      {t("Register.SignIn")}
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default Register;

import config from "../config";

//IDENTITY_SERVICE
export const GET_CAPTCHA_INFO = `${config.api.IDENTITY_API_URL}/api/account/getCaptchaInfo`;
export const GET_LOGGED_USER = `${config.api.IDENTITY_API_URL}/api/account/getLoggedUser`;
export const POST_LOGIN = `${config.api.IDENTITY_API_URL}/api/account/login`;
export const POST_LOGIN_WITH_TOKEN = `${config.api.IDENTITY_API_URL}/api/account/loginWithToken`;
export const POST_LOGIN_LOGOUT = `${config.api.IDENTITY_API_URL}/api/account/logout`;
export const POST_REGISTER = `${config.api.IDENTITY_API_URL}/api/account/register`;
export const POST_REFRESH_TOKEN = `${config.api.IDENTITY_API_URL}/api/account/refreshToken`;
export const POST_GET_INVITATION_INFORMATION = `${config.api.IDENTITY_API_URL}/api/account/getInvitationInformation`;
export const POST_EMAIL_VERIFICATION = `${config.api.IDENTITY_API_URL}/api/account/emailVerification`;
export const POST_SEND_PHONE_VERIFICATION_CODE = `${config.api.IDENTITY_API_URL}/api/account/sendPhoneVerificationCode`;
export const POST_PHONE_VERIFICATION = `${config.api.IDENTITY_API_URL}/api/account/phoneVerification`;
export const POST_FORGET_PASSWORD = `${config.api.IDENTITY_API_URL}/api/account/forgotPassword`;
export const POST_RESET_PASSWORD = `${config.api.IDENTITY_API_URL}/api/account/resetPassword`;
export const POST_UPDATE_GENERAL_INFORMATION = `${config.api.IDENTITY_API_URL}/api/account/updateGeneralInformation`;
export const POST_UPDATE_UI_PREFERENCES = `${config.api.IDENTITY_API_URL}/api/account/updateUIPreferences`;
export const POST_UPDATE_AVATAR = `${config.api.IDENTITY_API_URL}/api/account/updateAvatar`;
export const POST_CHANGE_PASSWORD = `${config.api.IDENTITY_API_URL}/api/account/changePassword`;
export const POST_GET_COSTS_AND_FEES_LIST = `${config.api.IDENTITY_API_URL}/api/account/getCostsAndFeesList`;
export const POST_GET_COSTS_AND_FEES_ITEM = `${config.api.IDENTITY_API_URL}/api/account/getCostsAndFeesItem`;
export const POST_UPDATE_COST_AND_FEE_ITEM = `${config.api.IDENTITY_API_URL}/api/account/updateCostAndFeeItem`;
export const POST_SAVE_COST_AND_FEE_ITEM = `${config.api.IDENTITY_API_URL}/api/account/saveCostAndFeeItem`;
export const POST_SET_AS_DEFAULT_COST_AND_FEE_ITEM = `${config.api.IDENTITY_API_URL}/api/account/setAsDefaultCostAndFeeItem`;
export const POST_DUPLICATE_COST_AND_FEE_ITEM = `${config.api.IDENTITY_API_URL}/api/account/duplicateCostAndFeeItem`;
export const POST_RESET_DEFAULT_COST_AND_FEE_ITEM = `${config.api.IDENTITY_API_URL}/api/account/resetDefaultCostAndFeeItem`;
export const DELETE_COST_AND_FEE_ITEM = `${config.api.IDENTITY_API_URL}/api/account/deleteCostAndFeeItem`;
export const POST_GET_ANALYZE_OPTIONS = `${config.api.IDENTITY_API_URL}/api/account/getAnalyzeOptions`;
export const POST_GET_ANALYZE_ITEM = `${config.api.IDENTITY_API_URL}/api/account/getAnalyzeItem`;
export const POST_SAVE_ANALYZE_ITEM = `${config.api.IDENTITY_API_URL}/api/account/saveAnalyzeItem`;
export const POST_UPDATE_ANALYZE_ITEM = `${config.api.IDENTITY_API_URL}/api/account/updateAnalyzeItem`;
export const POST_SET_AS_DEFAULT_ANALYZE_ITEM = `${config.api.IDENTITY_API_URL}/api/account/setAsDefaultAnalyzeItem`;
export const DELETE_ANALYZE_ITEM = `${config.api.IDENTITY_API_URL}/api/account/deleteAnalyzeItem`;
export const POST_RESET_DEFAULT_ANALYZE_ITEM = `${config.api.IDENTITY_API_URL}/api/account/resetDefaultAnalyzeItem`;
export const POST_GET_EXCHANGE_RATES = `${config.api.IDENTITY_API_URL}/api/account/getExchangeRates`;
export const POST_UPDATE_EXCHANGE_RATES = `${config.api.IDENTITY_API_URL}/api/account/updateExchangeRates`;
export const POST_GET_NOTIFICATION_OPTIONS = `${config.api.IDENTITY_API_URL}/api/account/getNotificationOptions`;
export const POST_UPDATE_NOTIFICATION_OPTIONS = `${config.api.IDENTITY_API_URL}/api/account/updateNotificationOptions`;
export const POST_GET_SUB_USERS = `${config.api.IDENTITY_API_URL}/api/account/getSubUsers`;
export const POST_SAVE_SUB_USER_INVITATION = `${config.api.IDENTITY_API_URL}/api/account/saveSubUserInvitation`;
export const POST_UPDATE_SUB_USER_INFORMATION = `${config.api.IDENTITY_API_URL}/api/account/updateSubUserInformation`;
export const POST_RESEND_SUB_USER_INVITATION = `${config.api.IDENTITY_API_URL}/api/account/resendSubUserInvitation`;
export const DELETE_SUB_USER = `${config.api.IDENTITY_API_URL}/api/account/deleteSubUser`;
export const POST_GET_USER_LIMIT_USAGE_HISTORY = `${config.api.IDENTITY_API_URL}/api/account/getUserLimitUsageHistory`;
export const POST_GET_USER_REMAINING_LIMIT = `${config.api.IDENTITY_API_URL}/api/account/getUserRemainingLimit`;
export const POST_SEND_SUPPORT_REQUEST = `${config.api.IDENTITY_API_URL}/api/account/support`;
export const POST_SEND_REPORT_ERROR_REQUEST = `${config.api.IDENTITY_API_URL}/api/account/reportError`;
export const POST_CONSOLE_LOG_REPORT_REQUEST = `${config.api.IDENTITY_API_URL}/api/contact/consoleLogReport`;
export const POST_TWO_FACTOR_SETUP = `${config.api.IDENTITY_API_URL}/api/account/twoFactor/setup`;
export const POST_TWO_FACTOR_VERIFY = `${config.api.IDENTITY_API_URL}/api/account/twoFactor/verify`;
export const POST_TWO_FACTOR_ENABLE = `${config.api.IDENTITY_API_URL}/api/account/twoFactor/enable`;
export const POST_TWO_FACTOR_DISABLE = `${config.api.IDENTITY_API_URL}/api/account/twoFactor/disable`;

//PAYMENT_SERVICE
export const GET_SUBSCRIPTIONS = `${config.api.PAYMENT_API_URL}/api/subscription/getSubscriptions`;
export const GET_USER_SUBSCRIPTION = `${config.api.PAYMENT_API_URL}/api/subscription/getUserSubscription`;
export const GET_USER_PAYMENTS = `${config.api.PAYMENT_API_URL}/api/payment/getUserPayments`;
export const GET_USER_PAYMENT_METHODS = `${config.api.PAYMENT_API_URL}/api/payment/getUserPaymentMethods`;
export const POST_INITIALIZE_SUBSCRIPTION = `${config.api.PAYMENT_API_URL}/api/subscription/initializeSubscription`;
export const POST_CHANGE_USER_SUBSCRIPTION = `${config.api.PAYMENT_API_URL}/api/subscription/changeUserSubscription`;
export const POST_CREATE_USER_SUBSCRIPTION = `${config.api.PAYMENT_API_URL}/api/subscription/createUserSubscription`;
export const POST_CANCEL_USER_SUBSCRIPTION = `${config.api.PAYMENT_API_URL}/api/subscription/cancelUserSubscription`;
export const POST_RESUME_USER_SUBSCRIPTION = `${config.api.PAYMENT_API_URL}/api/subscription/continueUserSubscription`;
export const POST_RETRY_SUBSCRIPTION_PAYMENT = `${config.api.PAYMENT_API_URL}/api/payment/retrySubscriptionPayment`;
export const POST_DOWNLOAD_INVOICE = `${config.api.PAYMENT_API_URL}/api/payment/downloadInvoice`;
export const POST_SAVE_USER_PAYMENT_METHOD = `${config.api.PAYMENT_API_URL}/api/payment/saveUserPaymentMethod`;
export const POST_UPDATE_USER_PAYMENT_METHOD = `${config.api.PAYMENT_API_URL}/api/payment/updateUserPaymentMethod`;
export const POST_DELETE_USER_PAYMENT_METHOD = `${config.api.PAYMENT_API_URL}/api/payment/deleteUserPaymentMethod`;
export const POST_SET_AS_DEFAULT_PAYMENT_METHOD = `${config.api.PAYMENT_API_URL}/api/payment/setAsDefaultPaymentMethod`;
export const POST_VALIDATE_COUPON = `${config.api.PAYMENT_API_URL}/api/payment/validateCoupon`;

//AMAZON_SERVICE
export const POST_GET_USER_STORES = `${config.api.AMAZON_API_URL}/api/userStores/getUserStores`;
export const POST_SET_USER_STORE_NAME = `${config.api.AMAZON_API_URL}/api/userStores/setUserStoreName`;
export const POST_DISCONNECT_USER_STORE = `${config.api.AMAZON_API_URL}/api/userStores/disconnectUserStore`;
export const POST_DELETE_USER_STORE = `${config.api.AMAZON_API_URL}/api/userStores/deleteUserStore`;
export const POST_GET_USER_INVENTORIES = `${config.api.AMAZON_API_URL}/api/userInventory/getUserInventories`;
export const POST_ARCHIVE_INVENTORIES = `${config.api.AMAZON_API_URL}/api/userInventory/archiveInventories`;
export const POST_UNARCHIVE_INVENTORIES = `${config.api.AMAZON_API_URL}/api/userInventory/unarchiveInventories`;
export const POST_UPDATE_INVENTORY_COST = `${config.api.AMAZON_API_URL}/api/userInventory/updateInventoryCost`;
export const POST_UPDATE_INVENTORY = `${config.api.AMAZON_API_URL}/api/userInventory/updateInventory`;
export const POST_DOWNLOAD_INVENTORY = `${config.api.AMAZON_API_URL}/api/userInventory/downloadInventory`;
export const POST_GET_USER_ORDERS = `${config.api.AMAZON_API_URL}/api/userOrders/getUserOrders`;
export const POST_GET_USER_ORDER_DETAILS = `${config.api.AMAZON_API_URL}/api/userOrders/getUserOrderDetails`;
export const POST_UPDATE_USER_ORDER_COST = `${config.api.AMAZON_API_URL}/api/userOrders/updateUserOrderCost`;
export const POST_REASSIGN_USER_ORDER_SHIPMENT = `${config.api.AMAZON_API_URL}/api/userOrders/reassignUserOrderShipment`;
export const POST_GET_USER_SHIPMENTS = `${config.api.AMAZON_API_URL}/api/userShipments/getUserShipments`;
export const POST_GET_USER_DRAFT_SHIPMENTS = `${config.api.AMAZON_API_URL}/api/userShipments/getUserDraftShipments`;
export const POST_SAVE_NEW_USER_SHIPMENT = `${config.api.AMAZON_API_URL}/api/userShipments/saveNewUserShipment`;
export const POST_EDIT_USER_SHIPMENT = `${config.api.AMAZON_API_URL}/api/userShipments/editUserShipment`;
export const POST_DELETE_USER_SHIPMENT = `${config.api.AMAZON_API_URL}/api/userShipments/deleteUserShipment`;
export const POST_GET_USER_SHIPMENT_DETAILS = `${config.api.AMAZON_API_URL}/api/userShipments/getUserShipmentDetails`;
export const POST_SET_USER_SHIPMENT_DETAILS = `${config.api.AMAZON_API_URL}/api/userShipments/setUserShipmentDetails`;
export const POST_ADD_USER_SHIPMENT_ITEM = `${config.api.AMAZON_API_URL}/api/userShipments/addUserShipmentItem`;
export const POST_DELETE_USER_SHIPMENT_ITEM = `${config.api.AMAZON_API_URL}/api/userShipments/deleteUserShipmentItem`;
export const POST_REFRESH_USER_SHIPMENT_ITEM = `${config.api.AMAZON_API_URL}/api/userShipments/refreshUserShipmentItem`;
export const POST_ADD_TO_AMAZON_INVENTORY = `${config.api.AMAZON_API_URL}/api/userShipments/addToAmazonInventory`;
export const POST_GET_DASHBOARD_STATISTICS = `${config.api.AMAZON_API_URL}/api/dashboard/getStatistics`;

//SEARCH_SERVICE
export const GET_SEARCH = `${config.api.SEARCH_API_URL}/api/searches/search`;
export const POST_GET_USER_SEARCHES = `${config.api.SEARCH_API_URL}/api/searches/getUserSearches`;
export const POST_SAVE_NEW_SEARCH = `${config.api.SEARCH_API_URL}/api/searches/saveNewSearch`;
export const POST_REANALYZE = `${config.api.SEARCH_API_URL}/api/searches/reAnalyze`;
export const POST_DELETE_SEARCH = `${config.api.SEARCH_API_URL}/api/searches/deleteSearch`;
export const POST_MARK_SEARCH_REVIEWED = `${config.api.SEARCH_API_URL}/api/searches/markReviewed`;
export const POST_SUBMIT_FOR_ANALYSIS = `${config.api.SEARCH_API_URL}/api/searches/submitForAnalysis`;
export const POST_ANALYZE_SHIPMENT = `${config.api.SEARCH_API_URL}/api/searches/analyzeShipment`;
export const POST_GET_SEARCH_RESULTS_FILTERS = `${config.api.SEARCH_API_URL}/api/searchResults/getFilters`;
export const POST_GET_SEARCH_RESULTS = `${config.api.SEARCH_API_URL}/api/searchResults/getResults`;
export const POST_SAVE_SEARCH_RESULTS_FILTER = `${config.api.SEARCH_API_URL}/api/searchResults/saveFilter`;
export const POST_DELETE_SEARCH_RESULTS_FILTER = `${config.api.SEARCH_API_URL}/api/searchResults/deleteFilter`;
export const POST_SET_USER_SHOW_CHECKLIST_OPTION_CHOICE = `${config.api.SEARCH_API_URL}/api/searchResults/setUserShowCheckListOptionChoice`;
export const POST_SEARCH_PRODUCT_MARK_REVIEWED = `${config.api.SEARCH_API_URL}/api/searchResults/markReviewed`;
export const POST_EXPORT_SEARCH_RESULTS = `${config.api.SEARCH_API_URL}/api/searchResults/export`;
export const POST_GET_PRODUCT_DETAILS = `${config.api.SEARCH_API_URL}/api/productDetails/getProductDetails`;
export const POST_GET_SINGLE_SEARCH = `${config.api.SEARCH_API_URL}/api/productDetails/singleSearch`;
export const POST_ADD_TO_USER_FAVORITE_LIST = `${config.api.SEARCH_API_URL}/api/favorites/addToUserFavoriteList`;
export const POST_DELETE_USER_FAVORITE_LIST = `${config.api.SEARCH_API_URL}/api/favorites/deleteUserFavoriteList`;
export const POST_EDIT_USER_FAVORITE_LIST = `${config.api.SEARCH_API_URL}/api/favorites/editUserFavoriteList`;
export const POST_GET_USER_FAVORITES = `${config.api.SEARCH_API_URL}/api/favorites/getUserFavorites`;
export const POST_REMOVE_FROM_USER_FAVORITE_LIST = `${config.api.SEARCH_API_URL}/api/favorites/removeFromUserFavoriteList`;
export const POST_SAVE_USER_FAVORITE = `${config.api.SEARCH_API_URL}/api/favorites/saveUserFavoriteList`;
export const POST_GET_USER_SEARCH_NOTES = `${config.api.SEARCH_API_URL}/api/notes/getUserSearchNotes`;
export const POST_SAVE_SEARCH_NOTE = `${config.api.SEARCH_API_URL}/api/notes/save`;
export const POST_DELETE_SEARCH_NOTE = `${config.api.SEARCH_API_URL}/api/notes/delete`;

//CRAWLER_API_SERVICE
export const POST_GET_SCAN_AND_SAVE = `${config.api.CRAWLER_API_URL}/api/crawlerScan/getScanAndSave`;
export const POST_SAVE_NEW_SCAN_AND_SAVE = `${config.api.CRAWLER_API_URL}/api/crawlerScan/saveNewScan`;
export const POST_EXPORT_ASIN = `${config.api.CRAWLER_API_URL}/api/crawlerScan/exportAsin`;
export const POST_DELETE_SCAN = `${config.api.CRAWLER_API_URL}/api/crawlerScan/deleteScan`;

//NOTIFICATION_SERVICE
export const POST_GET_USER_WEB_NOTIFICATIONS = `${config.api.NOTIFICATION_API_URL}/api/notification/getUserWebNotifications`;
export const POST_READ_USER_WEB_NOTIFICATION = `${config.api.NOTIFICATION_API_URL}/api/notification/readUserWebNotification`;
export const POST_MARK_ALL_AS_READ = `${config.api.NOTIFICATION_API_URL}/api/notification/markAllAsRead`;

//ADMIN_SERVICE
export const POST_GET_ADMIN_USER_SEARCHES = `${config.api.ADMIN_API_URL}/api/search/getSearches`;
export const POST_GET_SEARCH_LOGS = `${config.api.ADMIN_API_URL}/api/search/getSearchLogs`;
export const POST_RESET_SEARCH_PRODUCT_ITEM = `${config.api.ADMIN_API_URL}/api/search/resetSearchProductItem`;
export const POST_GET_USERS = `${config.api.ADMIN_API_URL}/api/user/getUsers`;
export const POST_BLOCK_USER = `${config.api.ADMIN_API_URL}/api/user/blockUser`;
export const POST_UNBLOCK_USER = `${config.api.ADMIN_API_URL}/api/user/unblockUser`;
export const POST_RESET_USER_PASSWORD = `${config.api.ADMIN_API_URL}/api/user/resetPassword`;
export const POST_GENERATE_USER_LOGIN_TOKEN = `${config.api.ADMIN_API_URL}/api/user/generateUserLoginToken`;
export const POST_GET_USER_PAYMENT_HISTORY = `${config.api.ADMIN_API_URL}/api/user/getUserPaymentHistory`;
export const POST_GET_USER_LOG_HISTORY = `${config.api.ADMIN_API_URL}/api/user/getUserLogHistory`;
export const GET_SEARCH_USERS = `${config.api.ADMIN_API_URL}/api/user/searchUser`;
export const POST_RESTART_USER_SEARCH = `${config.api.ADMIN_API_URL}/api/search/restartSearch`;
export const POST_CANCEL_USER_SEARCH = `${config.api.ADMIN_API_URL}/api/search/cancelSearch`;
export const POST_UNDO_DELETE_USER_SEARCH = `${config.api.ADMIN_API_URL}/api/search/undoDeletedSearch`;
export const POST_GET_ADMIN_SCAN_AND_SAVE = `${config.api.ADMIN_API_URL}/api/scanAndSave/getScanAndSave`;
export const POST_RESTART_SCAN_AND_SAVE = `${config.api.ADMIN_API_URL}/api/scanAndSave/restartScan`;
export const POST_CANCEL_SCAN_AND_SAVE = `${config.api.ADMIN_API_URL}/api/scanAndSave/cancelScan`;
export const POST_UNDO_DELETED_SCAN = `${config.api.ADMIN_API_URL}/api/scanAndSave/undoDeletedScan`;
export const POST_GET_SYSTEM_MONITORING = `${config.api.ADMIN_API_URL}/api/systemMonitoring/getSystemMonitoring`;
export const POST_GET_SYSTEM_MONITORING_DETAILS = `${config.api.ADMIN_API_URL}/api/systemMonitoring/getSystemMonitoringDetails`;
export const POST_CLEAR_TOPICS = `${config.api.ADMIN_API_URL}/api/systemMonitoring/clearTopics`;
export const POST_RE_BALANCE_ORCHESTRATION_SERVICE = `${config.api.ADMIN_API_URL}/api/systemMonitoring/rebalanceOrchestrationService`;
export const POST_ADD_PRODUCT = `${config.api.ADMIN_API_URL}/api/systemMonitoring/addProduct`;
export const POST_GET_TAGS = `${config.api.ADMIN_API_URL}/api/tag/getTags`;
export const POST_CREATE_TAG = `${config.api.ADMIN_API_URL}/api/tag/createTag`;
export const POST_UPDATE_TAG = `${config.api.ADMIN_API_URL}/api/tag/updateTag`;
export const POST_DELETE_TAG = `${config.api.ADMIN_API_URL}/api/tag/deleteTag`;
export const POST_ADD_USER_TAG_ITEM = `${config.api.ADMIN_API_URL}/api/tag/addUserTagItem`;
export const POST_DELETE_USER_TAG_ITEM = `${config.api.ADMIN_API_URL}/api/tag/deleteUserTagItem`;
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {Button, Card, CardBody, Container, Spinner} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {useTranslation} from "react-i18next";
import {ColumnDef} from "@tanstack/react-table";
import {RootState} from "slices";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/useDebounce";
import {ScanAndSave} from "models/scan_and_save";
import {deleteScan, exportAsins, getScanAndSaveList, resetScanAndSaveState} from "slices/scan-and-save/thunk";
import {getRelativeDateByTimezone, getToday} from "helpers/utilities";
import {useProfile} from "Components/Hooks/useProfile";
import {ConstantPage} from "helpers/permission_helper";
import {ScanAndSaveQuery} from "api/query";
import {ScanAndSaveBadge} from "Components/Common/Badges/ScanAndSaveBadge";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import BreadCrumb from "Components/Common/BreadCrumb";
import Filters from "./Filters";
import Loader from "Components/Common/Loader";
import SendAnalysis, {SendAnalysisModal} from "../../Components/Common/Modals/SendAnalysis";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import NewScan from "./Modals/NewScan";
import NoResult from "Components/Common/NoResult";
import DisplayNumber from "Components/Common/DisplayNumber";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayDate from "Components/Common/DisplayDate";
import defaultAvatar from "assets/images/user-dummy-img.jpg";
import _ from "lodash";

export type ScanAndSaveListRef = {
  reload: VoidFunction;
};
interface ScanAndSavePageProps {
  new?: boolean;
}
const PAGE_IDENTIFIER: ConstantPage = "scanAndSave";
const ScanAndSavePage = (props: ScanAndSavePageProps) => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<ScanAndSaveListRef>(null);

  const [localQuery, updateLocalQuery] = useImmer<ScanAndSaveQuery>({
    action: "filtering",
    startDate: undefined,
    endDate: undefined,
    page: 1,
    pageSize: 10,
  });

  let filterFields: FieldConfig<ScanAndSaveQuery>[] = [
    {field: "startDate", queryParam: "start", type: "date", defaultValue: getRelativeDateByTimezone(30, userProfile?.timezone)},
    {field: "endDate", queryParam: "end", type: "date", defaultValue: getToday().toDate()},
    {field: "statuses", queryParam: "statuses", type: "array"},
    {field: "teamUserIds", queryParam: "teamUserIds", type: "array"},
    {field: "page", queryParam: "page", type: "number", defaultValue: localQuery.page},
    {field: "pageSize", queryParam: "pageSize", type: "number", defaultValue: localQuery.pageSize},
    {field: "sortBy", queryParam: "sortBy", type: "string", defaultValue: localQuery.sortBy},
    {field: "sortingOrder", queryParam: "sortingOrder", type: "number", defaultValue: localQuery.sortingOrder},
    {field: "action", queryParam: "action", type: "string", defaultValue: "filtering"},
  ];

  const debouncedLoadList = useDebounce(() => {
    getScanAndSaveList(localQuery)(dispatch).then(() => {
      tableRef.current?.resetSelection();
    });
  }, 200);

  const {readQueryParams, updateQuery, searchParams} = useUrlQuery<ScanAndSaveQuery>(filterFields);

  useEffect(() => {
    if (hasPermission("scanAndSave") && searchParams.has("action")) {
      const params = readQueryParams();
      updateLocalQuery(params as ScanAndSaveQuery);
      debouncedLoadList();
    }
  }, [debouncedLoadList, searchParams]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const scanAndSaveData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.ScanAndSave.loading,
      list: state.ScanAndSave.list,
    }),
  );

  const {loading, list} = useSelector(scanAndSaveData);

  const columns = useMemo<ColumnDef<ScanAndSave, any>[]>(
    () => [
      {
        header: t("Actions"),
        size: 90,
        enableResizing: false,
        enableSorting: false,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          const [isExporting, setIsExporting] = useState(false); // eslint-disable-line
          const [isDeletingOrRestoring, setIsDeletingOrRestoring] = useState<boolean>(false); // eslint-disable-line

          const handleDeleteOrRestore = async () => {
            setIsDeletingOrRestoring(true);
            await deleteScan({scanAndSaveId: row.id, deleted: !row.deleted}, row.searchName)(dispatch);
            setIsDeletingOrRestoring(false);
          };
          return (
            <div className="d-flex align-items-center">
              <Button
                id={`Analyze-${row.id}`}
                color="link"
                className={`btn btn-ghost-secondary px-1 py-0 fs-18 ${row.total === 0 ? "disabled" : ""}`}
                onClick={() => {
                  SendAnalysisModal.open({
                    id: row.id,
                    searchName: row.searchName,
                    listRef: listRef,
                    total: row.total,
                  });
                }}
              >
                <i className="mdi mdi-chart-timeline-variant-shimmer align-middle"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`Analyze-${row.id}`}>{t("ScanAndSave.Tooltip.Analyze")}</DefaultUncontrolledTooltip>

              <Button
                id={`DownloadScan-${row.id}`}
                color="link"
                className={`btn btn-ghost-secondary px-1 py-0 fs-18 ${row.total === 0 || isExporting ? "disabled" : ""}`}
                onClick={() => {
                  setIsExporting(true);
                  const exportPromise = exportAsins({asinFetcherTaskId: row.id}, row.searchName)(dispatch);
                  exportPromise.then(() => {
                    setIsExporting(false);
                  });
                }}
              >
                {isExporting ? <Spinner size="sm"></Spinner> : <i className="mdi mdi-cloud-download align-middle"></i>}
              </Button>
              <DefaultUncontrolledTooltip target={`DownloadScan-${row.id}`}>{t("ScanAndSave.Tooltip.Download")}</DefaultUncontrolledTooltip>

              <Button
                id={`DeleteScan-${row.id}`}
                color={row.deleted ? "ghost-secondary" : "ghost-danger"}
                className="btn-link px-1 py-0 fs-18"
                onClick={handleDeleteOrRestore}
                disabled={isDeletingOrRestoring || isExporting}
              >
                {row.deleted ? <i className="ri-arrow-go-back-line align-middle" /> : <i className="ri-delete-bin-fill align-middle"></i>}
              </Button>
              <DefaultUncontrolledTooltip target={`DeleteScan-${row.id}`}>{row.deleted ? t("ScanAndSave.Tooltip.Restore") : t("ScanAndSave.Tooltip.Delete")}</DefaultUncontrolledTooltip>
            </div>
          );
        },
      },
      {
        header: t("ScanAndSave.TableColumn.ScanName"),
        enableSorting: true,
        accessorKey: "searchName",
        sortingFn: "alphanumeric",
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return (
            <div className="d-flex align-items-center">
              <div className="nowrap">
                <Link className="link-secondary link-offset-2 text-decoration-underline me-1" role="button" to={`/searches/search-results/`}>
                  {userProfile?.teamUsers && userProfile?.teamUsers.length > 0 && (
                    <>
                      <img
                        id={`CreatedUserAvatarTooltip-${row.id}-${row.user.userId}`}
                        src={row.avatarUrl || defaultAvatar}
                        alt={`User Profile - ${row.user.fullName}`}
                        className="rounded-circle me-1 border"
                        style={{width: "16px", height: "16px"}}
                      />

                      <DefaultUncontrolledTooltip target={`CreatedUserAvatarTooltip-${row.id}-${row.user.userId}`}>
                        {t("ScanAndSave.Tooltip.CreatedBy", {value: row.user.userId === userProfile?.userId ? t("You") : `${row.user.fullName} (${row.user.email})`})}
                      </DefaultUncontrolledTooltip>
                    </>
                  )}
                </Link>
                {_.truncate(row.searchName, {
                  length: 30,
                  omission: "...",
                })}
              </div>
            </div>
          );
        },
      },
      {
        header: t("ScanAndSave.TableColumn.Url"),
        size: 400,
        accessorKey: "url",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          const truncated = _.truncate(row.url, {length: 60, omission: "..."});

          return (
            <>
              <div className="ellipsis-single-line">
                <Link to={row.url} className="link-secondary link-offset-2 text-decoration-underline" target="_blank">
                  {truncated}
                  <i className="ri-arrow-right-up-line"></i>
                </Link>
              </div>
            </>
          );
        },
      },
      {
        header: t("ScanAndSave.TableColumn.CreateDate"),

        size: 150,
        accessorKey: "createDate",
        sortingFn: "datetime",
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return (
            <>
              <DisplayDate id={`DisplayDateScanAndSaveCreateDate-${row.id}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </>
          );
        },
      },
      {
        header: t("ScanAndSave.TableColumn.Status"),
        accessorKey: "status",
        sortingFn: "alphanumericCaseSensitive",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return (
            <>
              <ScanAndSaveBadge status={row.status} />
            </>
          );
        },
      },
      {
        header: t("ScanAndSave.TableColumn.Total"),
        accessorKey: "total",
        sortingFn: "alphanumeric",
        size: 90,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return <DisplayNumber value={row.total} />;
        },
      },
      {
        header: t("ScanAndSave.TableColumn.Sent"),
        accessorKey: "sent",
        sortingFn: "alphanumeric",
        size: 90,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return <DisplayNumber value={row.sent} />;
        },
      },
    ],
    [t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    return () => {
      dispatch(resetScanAndSaveState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.title = t("PageTitles.ScanAndSave");

  //#endregion
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <NewScan isOpen={props.new || false} busy={loading.filter} listRef={listRef} toggle={() => navigate("/scan-and-save")} />
          <BreadCrumb title={t("ScanAndSave.Title")} menus={[{label: t("ScanAndSave.Title")}]} />
          <Filters busy={loading.filter} fields={filterFields} />
          <Card>
            <CardBody>
              {loading.filter ? (
                <>
                  <Loader />
                </>
              ) : list.items && list.items.length > 0 ? (
                <>
                  <DataTable
                    ref={tableRef}
                    busy={loading.list}
                    columns={columns}
                    data={list?.items || []}
                    totalDataLength={list?.totalCount}
                    pagination={{
                      pageIndex: localQuery.page - 1,
                      pageSize: localQuery.pageSize,
                    }}
                    onPaginationChanged={(pagination) => {
                      updateQuery({...localQuery, page: pagination.pageIndex + 1, pageSize: pagination.pageSize, action: "paginating"});
                    }}
                    onSortingChanged={(sorting) => {
                      updateQuery({...localQuery, sortBy: sorting?.sortBy, sortingOrder: sorting?.sortingOrder, action: "sorting"});
                    }}
                    hovered
                  />
                </>
              ) : (
                <NoResult title={t("ScanAndSave.NoResult.Title")} description={t("ScanAndSave.NoResult.Description")} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <SendAnalysis />
    </Restricted>
  );
};

export default ScanAndSavePage;

import {Card, CardBody} from "reactstrap";
import {useTranslation} from "react-i18next";
import {UserSearchProduct} from "models/user_search_product";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import CheckListItem, {CheckListStatus} from "./Components/CheckListItem";

enum SameProductCheckResult {
  YES = 0,
  NO = 1,
}

enum CustomsCheckResult {
  NO = 0,
  YES = 1,
}
interface CheckListProps {
  item: UserSearchProduct;
}
const CheckListItems = ({item}: CheckListProps) => {
  const {t} = useTranslation();
  const sameProductStatus: CheckListStatus =
    item.checkList.sameProductCheckResult != null
      ? item.checkList.sameProductCheckResult === SameProductCheckResult.YES
        ? "success"
        : item.checkList.sameProductCheckResult === SameProductCheckResult.NO
        ? "warning"
        : "muted"
      : "muted";
  const customsCheckStatus: CheckListStatus =
    item.checkList.customsCheckCategoryCheckResult === CustomsCheckResult.YES || item.checkList.customsCheckKeywordCheckResult === CustomsCheckResult.YES
      ? "warning"
      : item.checkList.customsCheckCategoryCheckResult === CustomsCheckResult.NO && item.checkList.customsCheckKeywordCheckResult === CustomsCheckResult.NO
      ? "success"
      : "muted";
  const productSizeEligibleToFbaStatus: CheckListStatus = item.checkList?.productSizeEligibleToFBA != null ? (item.checkList.productSizeEligibleToFBA === true ? "success" : "danger") : "muted";
  const isOldProductStatus: CheckListStatus = item.checkList?.isOldProduct != null ? (item.checkList.isOldProduct === true ? "success" : "danger") : "muted";
  const isTheRatingAverageGoodStatus: CheckListStatus = item.checkList?.isTheRatingAverageGood != null ? (item.checkList.isTheRatingAverageGood === true ? "success" : "danger") : "muted";
  return (
    <Card className="border card-animate mb-3">
      <CardBody>
        <ul className="list-unstyled vstack gap-1 mb-0">
          <h5>{t("SearchResults.Analyzes.CheckList.Title")}</h5>
          <CheckListItem labelName={t("SearchResults.Analyzes.CheckList.SameProduct")} status={sameProductStatus} hide={item.amazonBusinessModel === AmazonBusinessModel.WHOLESALE} />
          <CheckListItem labelName={t("SearchResults.Analyzes.CheckList.CustomsCheck")} status={customsCheckStatus} hide={item.amazonBusinessModel === AmazonBusinessModel.WHOLESALE} />
          <CheckListItem
            labelName={t("SearchResults.Analyzes.CheckList.ProductSizeEligibleToFba")}
            status={productSizeEligibleToFbaStatus}
            hide={item.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING}
          />
          <CheckListItem
            labelName={
              t("SearchResults.Analyzes.CheckList.IsOldProduct") +
              (item.checkList?.productExistSince
                ? " (" +
                  item.checkList.productExistSince
                    .replace("years", t("years"))
                    .replace("year", t("year"))
                    .replace("months", t("months"))
                    .replace("month", t("month"))
                    .replace("days", t("days"))
                    .replace("day", t("day"))
                    .replace("and", t("and"))
                    .replace("ago", t("ago")) +
                  ")"
                : "")
            }
            status={isOldProductStatus}
            hide={item.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING}
          />

          <CheckListItem labelName={t("SearchResults.Analyzes.CheckList.IsTheRatingAverageGood")} status={isTheRatingAverageGoodStatus} />
        </ul>
      </CardBody>
    </Card>
  );
};

export default CheckListItems;

import Dialog, {DialogRef} from "Components/Common/Dialog";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row, Spinner, Form} from "reactstrap";
import {useFormik} from "formik";
import {RefObject, useRef} from "react";
import {useTranslation} from "react-i18next";
import {ISaveNewUserShipmentDto} from "models/user_shipment";
import {ShipmentListRef} from "..";
import {creteNewShipment} from "slices/thunks";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {CommonSliceSelector} from "slices/common/selector";
import {createTypedModal} from "helpers/modal_helpers";
import {ShipmentSlice} from "slices/shipment/selector";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";

interface ModalData {
  listRef: RefObject<ShipmentListRef>;
  targetMarketplace?:string;
}

export const NewShipmentModal = createTypedModal<ModalData>("new_shipment");
const NewShipment = () => {
  const {t} = useTranslation();
  const {open, data} = NewShipmentModal.useModal();
  const navigate = useNavigate();
  const connectStoreDialogRef = useRef<DialogRef>(null);
  const dispatch: any = useDispatch();

  const {userStores, activeUserStoreOptions} = useSelector(CommonSliceSelector);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      shipmentName: "",
      userStoreId: "",
    },
    validationSchema: Yup.object().shape({
      shipmentName: Yup.string()
        .required(t("Shipments.NameRequired"))
        .max(50, t("Shipments.NameMaxLength")),
      userStoreId: Yup.string().required(t("Shipments.StoreRequired")),
    }),
    onSubmit: (values: any) => {
      const store = userStores.find((store) => store.userStoreId === values.userStoreId);
      let saveNewUserShipmentRequest: ISaveNewUserShipmentDto = {
        shipmentName: values.shipmentName,
        marketplace: store?.marketplace || "",
        userStoreId: values.userStoreId,
      };

      const savePromise = creteNewShipment(saveNewUserShipmentRequest)(dispatch);
      savePromise.then((isSuccess) => {
        if (isSuccess) {
          data?.listRef.current?.reload();
          toggle();
        }
      });
    },
  });

  const {loading} = useSelector(ShipmentSlice);

  const toggle = () => {
    if (activeUserStoreOptions.length > 0) {
      NewShipmentModal.close();
      validation.resetForm();
    } else {
      connectStoreDialogRef.current?.show();
    }
  };

  return (
    <>
      <Modal id="showNewShipmentModal" className="modal-md" isOpen={open} toggle={toggle} fade={true} centered={true}>
        <ModalHeader className="p-3" toggle={toggle}>
          {t("Shipments.NewShipment")}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row className="g-3">
              <Col xs={12} md={6}>
                <ValidatedInput validation={validation} type="text" field="shipmentName" maxLength={100} placeholder={t("Shipments.Name")} disableValidationUI />
              </Col>
              <Col xs={12} md={6}>
                <ValidatedSelect
                  options={data?.targetMarketplace ? [...activeUserStoreOptions.filter((store) => store.marketplace === data.targetMarketplace)] : [...activeUserStoreOptions]}
                  validation={validation}
                  field="userStoreId"
                  value={validation.values.userStoreId}
                  errorStyle="container"
                  optionStyle="marketplace"
                  valueStyle="marketplace"
                />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button type="button" className="btn btn-light" disabled={loading.save} onClick={toggle}>
              {t("Close")}
            </Button>

            <Button
              type="submit"
              className="btn btn-success"
              disabled={loading.save}
              onClick={() => {
                validation.setFieldTouched("shipmentName");
                validation.setFieldTouched("userStoreId");

                if (validation.isValid) {
                  validation.handleSubmit();
                }
              }}
            >
              {loading.save && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
              {t("Save")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Dialog
        ref={connectStoreDialogRef}
        color="success"
        buttons={["yes", "no"]}
        buttonConfig={(buttonConfigs) => {
          return {
            ...buttonConfigs,
            yes: {
              ...buttonConfigs.yes,
              label: t("Shipments.GoToStores"),
            },
            no: {
              ...buttonConfigs.no,
              label: t("Close"),
            },
          };
        }}
        busy={false}
        iconClass="mdi mdi-connection"
        message={t("Shipments.StoreConnectInfoMessage")}
        title={t("Stores.Connect")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            navigate("/stores");
          }
          connectStoreDialogRef.current?.hide();
        }}
      />
    </>
  );
};

export default NewShipment;

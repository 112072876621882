import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates, PagedList} from "helpers/types";
import {DeleteUserFavoriteListCommand, EditUserFavoriteListCommand, UserFavorite} from "models/user_favorites";

type FavoritesLoadingState = LoadingStates<"filter" | "list" | "delete" | "update">;

export type FavoritesState = {
  loading: FavoritesLoadingState;
  list: Partial<PagedList<UserFavorite>>;
  error: ApiError;
};

const initialState: FavoritesState = {
  loading: {
    filter: true,
    list: false,
    delete: false,
    update: false,
  },
  list: {items: []},
  error: {} as ApiError,
};

const FavoritesSlice = createSlice({
  name: "Favorites",
  initialState,
  reducers: {
    setList(state, action: PayloadAction<PagedList<UserFavorite>>) {
      state.list = action.payload;
    },
    loading(state, action: PayloadAction<[keyof FavoritesLoadingState, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    updateItem(state, action: PayloadAction<EditUserFavoriteListCommand>) {
      state.list = {
        ...state.list,
        items: state.list.items?.map((item) => (item.userFavoriteId === action.payload.userFavoriteId ? {...item, name: action.payload.name, description: action.payload.description} : item)),
      };
    },
    deleteOrRestoreItem(state, action: PayloadAction<DeleteUserFavoriteListCommand>) {
      state.list = {
        ...state.list,
        items: state.list.items?.map((item) => (item.userFavoriteId === action.payload.userFavoriteId ? {...item, deleted: action.payload.deleted} : item)),
      };
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
  },
});

export const {setList, loading, updateItem, deleteOrRestoreItem, apiError} = FavoritesSlice.actions;

export default FavoritesSlice.reducer;

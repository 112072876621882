import {ShipmentInfo, apiError, deleteOrRestoreShipment, loading, reset, setSearchResponse, setShipmentInfo, setTags} from "./reducer";
import {ResponseModel} from "models/response_model";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {postAddUserShipmentItem, postDeleteUserShipment, postDeleteUserShipmentItem, postGetUserDraftShipments, postSetUserShipmentDetails} from "services/shipment_service";
import {IAddUserShipmentItemDto, IDeleteUserShipmentItemDto, ISetUserShipmentDetailsDto} from "models/user_shipment";
import {SearchQuery} from "api/query";
import {getSearch, postReAnalyze} from "services/search_service";
import {postGetTags} from "services/admin_service";
import {DeleteUserShipmentCommand, ReAnalyzeCommand} from "api/command";
import {refreshRemainingLimits} from "slices/thunks";
import i18n from "i18n";

export const getUserDraftShipments = (asin: string, marketplace: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["userShipment", true]));
    const result: ResponseModel = await postGetUserDraftShipments({asin, marketplace});
    dispatch(setShipmentInfo(result.data));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["userShipment", false]));
  }
};

export const deleteUserShipmentFromSearchResult = (command: DeleteUserShipmentCommand, name: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    await postDeleteUserShipment(command);
    dispatch(deleteOrRestoreShipment(command));
    if (command.deleted) {
      renderSuccessToast(i18n.t("Shipments.Toast.DeleteSuccess", {value: name}));
    }
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const deleteUserShipmentItemFromSearchResult = (deleteUserShipmentItemRequest: IDeleteUserShipmentItemDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    const result: ResponseModel = await postDeleteUserShipmentItem(deleteUserShipmentItemRequest);
    const userShipments: ShipmentInfo = result.data;
    dispatch(setShipmentInfo(userShipments));
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const addUserShipmentItemFromSearchResult = (addUserShipmentItemRequest: IAddUserShipmentItemDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    const result: ResponseModel = await postAddUserShipmentItem(addUserShipmentItemRequest);
    const userShipments: ShipmentInfo = result.data;
    dispatch(setShipmentInfo(userShipments));
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const setUserShipmentDetailsFromSearchResult = (newShipmentDetails: ISetUserShipmentDetailsDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const result: ResponseModel = await postSetUserShipmentDetails(newShipmentDetails);
    const userShipments: ShipmentInfo = result.data;
    dispatch(setShipmentInfo(userShipments));
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const reAnalyze = (command: ReAnalyzeCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["reanalyze", true]));
    await postReAnalyze(command);
    dispatch(refreshRemainingLimits());
    renderSuccessToast(i18n.t("Searches.ReAnalyzeSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["reanalyze", false]));
  }
};

export const getSearchQuery = (query: SearchQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["search", true]));
    const result: ResponseModel = await getSearch(query);
    dispatch(setSearchResponse(result.data));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["search", false]));
  }
};

export const getTags = () => async (dispatch: any) => {
  try {
    dispatch(loading(["tag", true]));
    const result: ResponseModel = await postGetTags();
    dispatch(setTags(result.data));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["tag", false]));
  }
};

export const resetCommonState = () => async (dispatch: any) => {
  dispatch(reset());
};
